import React from "react";
import { DownloadVideoSvg, VideoBtnSvg } from "../Svgs";
import { useTranslation } from "react-i18next";

const FaqVideo = ({ url }) => {
  const { t } = useTranslation("common");
  return (
    <div className="alignH gap-4">
      <VideoBtnSvg />
      <div>
        <span className="f16 normal text-lightBlack">{t("Video")}</span>
        <p className="f12 normal text-lightBlack">Youtube</p>
      </div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <DownloadVideoSvg />
      </a>
    </div>
  );
};

export default FaqVideo;
