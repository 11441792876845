import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";
import { Overlay } from "react-bootstrap";
import MediaPreview from "../../../Common/MediaPreview";
import userAvatar from "../../../../Images/Images/avatar.png";
import { useTranslation } from "react-i18next";

function MessageItem({ message, index, isOwner }) {
  const { t } = useTranslation("common");
  const ref = useRef();
  const target = useRef(null);
  const [previewMedia, setPreviewMedia] = useState();
  const [showOverlay, setShowOverlay] = useState();
  const date = moment(message?.attributes?.createdAt).format(
    "DD-MM-YYYY[ | ]HH[h]mm"
  );

  const user = message?.attributes?.user?.data;
  const photoUrl = user?.attributes?.photo?.data?.attributes?.url;
  const userName = `${user?.attributes?.firstName ?? ""} ${
    user?.attributes?.lastName ?? ""
  }`;

  let title = "";
  if (user?.attributes?.userRole === 1) {
    title = t("Administrator");
  } else if (user?.attributes?.userRole === 0) {
    title =
      user?.attributes?.profil?.data?.attributes?.reseller?.data?.attributes
        ?.name ?? "";
  } else if (user?.attributes?.userRole === 2) {
    title = user?.attributes?.business_affiliate?.data?.attributes?.name;
  }

  const attachments = message?.attributes?.attachments?.data;
  const hasText = message?.attributes?.text?.length > 0;

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (index === 0) ref?.current?.scrollIntoView();
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [message, index]);
  return (
    <div
      className={`messageContainer d-flex mt-2 ${
        isOwner ? "ms-auto" : "me-auto"
      }`}
      ref={ref}
    >
      {!isOwner && (
        <>
          <img
            ref={target}
            src={photoUrl ? `${IMAGE_END_POINT}${photoUrl}` : userAvatar}
            alt={userName}
            className="message-user me-3"
            onMouseOver={() => setShowOverlay(true)}
            onMouseOut={() => setShowOverlay(false)}
          />
        </>
      )}
      <Overlay
        target={target.current}
        show={showOverlay}
        placement="bottom-start"
      >
        {(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: 99999,
              boxShadow: "0px 3px 6px #0000001a",
            }}
            className="d-flex align-items-center p-12px bg-white br5"
          >
            <img
              src={photoUrl ? `${IMAGE_END_POINT}${photoUrl}` : userAvatar}
              alt=""
              width={34}
              height={34}
              className="ob-cover rounded-circle"
            />

            <div className="d-flex flex-column ms-2 ps-1">
              {userName?.trim()?.length > 0 && (
                <p className="f14 mb-0 semi-bold text-dark">{userName}</p>
              )}
              <p className="f14 mb-0 text-brightGrey">{title}</p>
            </div>
          </div>
        )}
      </Overlay>
      <div>
        {message?.attributes?.text?.length > 0 && (
          <div
            className={`f14 text-brightGrey fit-content b-1-lightGrey p-3 br5 ${
              isOwner ? "ms-auto" : "bg-light-grey-1"
            }`}
          >
            {message?.attributes?.text?.split("\n").map((t) => (
              <p className="mb-0">{t?.length > 0 ? t : <br />}</p>
            ))}
          </div>
        )}

        <div
          className={`d-flex flex-wrap gap-2 ${hasText ? "mt-2" : ""} ${
            isOwner ? "justify-content-end" : "justify-content-start"
          }`}
        >
          {attachments?.map((attach) => {
            const url = attach?.attributes?.file?.data?.attributes?.url;
            const fileName = attach?.attributes?.file?.data?.attributes?.name;
            const fileType = attach?.attributes?.file?.data?.attributes?.mime;
            const isImage = fileType?.includes("image");
            const isVideo = fileType?.includes("video");
            return (
              <div
                key={attach?.id}
                className="d-flex align-items-center justify-content-center"
              >
                <button
                  className="bg-lightGrey outline-none border-0 br5 overflow-hidden w-100px h-100px d-flex align-items-center justify-content-center"
                  onClick={() =>
                    (isImage || isVideo) &&
                    setPreviewMedia(attach?.attributes?.file?.data)
                  }
                >
                  {isImage ? (
                    <img
                      src={IMAGE_END_POINT + url}
                      alt={fileName}
                      className="ob-contain br5"
                      width={100}
                      height={100}
                    />
                  ) : isVideo ? (
                    <video width={100} height={100}>
                      <source src={IMAGE_END_POINT + url} type={fileType} />
                    </video>
                  ) : (
                    <a
                      href={`${IMAGE_END_POINT}${url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center mb-0 text-blue f14 h-100 w-100"
                    >
                      {t("Document")}
                    </a>
                  )}
                </button>
              </div>
            );
          })}
        </div>

        <p
          className={`mb-0 f12 text-brightGrey mt-3 ${
            isOwner ? "text-end" : ""
          }`}
        >
          {date}
        </p>
      </div>
      {previewMedia && (
        <MediaPreview
          file={previewMedia?.attributes?.url}
          setPreviewMedia={setPreviewMedia}
          mediaType={
            previewMedia?.attributes?.mime?.includes("image")
              ? "image"
              : previewMedia?.attributes?.mime?.includes("video")
              ? "video"
              : null
          }
          isUrl
        />
      )}
    </div>
  );
}

export default MessageItem;
