import React, { useEffect, useRef, useState } from "react";
import Button from "../../../Common/FormElements/Button/Button";
import AttachmentInput from "./AttachmentInput";
import MessageMediaPreview from "./MessageMediaPreview";
import { useTranslation } from "react-i18next";

function MessageInput({
  loading,
  newMessage,
  setNewMessage,
  handleChange,
  handleSubmit,
}) {
  const { t } = useTranslation("common");
  const textareaRef = useRef(null);

  const handleFocus = () => {
    if (textareaRef?.current) {
      textareaRef.current.focus();
    }
  };

  useEffect(() => {
    handleFocus();
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;

    const adjustTextareaHeight = () => {
      if (textarea) {
        textarea.style.height = "auto"; // Reset the height to auto to calculate the new height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content's scrollHeight
      }
    };

    if (textarea) {
      textarea.addEventListener("input", adjustTextareaHeight);
      adjustTextareaHeight(); // Call it initially to set the correct height
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener("input", adjustTextareaHeight);
      }
    };
  }, []);

  return (
    <div className="formContainer position-sticky w100">
      <MessageMediaPreview
        attachments={newMessage?.attachments}
        setAttachments={(updatedAttachments) =>
          setNewMessage({ ...newMessage, attachments: updatedAttachments })
        }
      />
      <form
        className="position-relative d-flex gap-3 flex-wrap"
        onSubmit={(e) => {
          handleSubmit(e, () => {
            const textarea = textareaRef.current;
            textarea.style.height = "auto";
          });
        }}
      >
        <div
          className="w100 b-2-softGrey br5 messageInput py-2 ps-4 pe-2 d-flex align-items-center justify-content-center"
          onClick={() => handleFocus()}
        >
          <textarea
            ref={textareaRef}
            name="text"
            rows={1}
            type="textarea"
            placeholder="Message"
            className="d-flex align-items-center w-100 border-0"
            onChange={loading ? undefined : handleChange}
            value={newMessage?.text ?? ""}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleSubmit(e, () => {
                  const textarea = textareaRef.current;
                  textarea.style.height = "auto";
                });
              }
            }}
          />
        </div>
        {/* TODO LATER */}
        <div className="d-flex align-content-center justify-content-end mt-2 w-100">
          <AttachmentInput
            name="attachments"
            value={newMessage?.attachments}
            handleChange={handleChange}
          />
          <Button
            className="sendBtn ms-2 mt-auto"
            isSubmit
            loading={loading}
            disabled={
              (!newMessage?.text?.trim()?.length > 0 &&
                !newMessage?.attachments?.length > 0) ||
              loading
            }
            // onClick={(e) => {
            //   e.stopPropagation();
            //   if (loading) return;
            //   handleSubmit(e, () => {
            //     const textarea = textareaRef.current;
            //     textarea.style.height = "auto";
            //   });
            // }}
          >
            {t("Send")}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default MessageInput;
