import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/fontawesome-free-solid";
import Button from "../../Common/FormElements/Button/Button";
import TicketsSAVPreview from "./TicketsSAVPreview";
import TicketsSAVValues from "./TicketsSAVValues";
import { SAVSvg } from "../../Svgs";

import "./TicketsSAV.css";
import { getTicketSAVs } from "../../../REDUX/actions/ticketSAV";
import { getCurrentAffiliate } from "../../../REDUX/actions/affiliate";
import { useTranslation } from "react-i18next";

const TicketsSAV = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticketSAVs, ticketSAVsReload } = useSelector(
    (state) => state.ticketSAVReducer
  );
  const { user } = useSelector((state) => state.userReducer);
  const { currentAffiliate } = useSelector((state) => state.affiliateReducer);

  useEffect(() => {
    dispatch(getTicketSAVs({}));
  }, [dispatch, ticketSAVsReload]);

  useEffect(() => {
    dispatch(
      getCurrentAffiliate(user?.attributes?.business_affiliate?.data?.id)
    );
  }, [dispatch, user?.attributes?.business_affiliate?.data?.id]);
  return (
    <div className="px-4 pt-4 w-100 h-100 bg-white br5 shadow14">
      <div className="alignH justify-content-between">
        <div className="alignH gap-2">
          <SAVSvg color="#14748e" />
          <span className="quickSand f22 bold text-blue">
            {t("AS Tickets", { ns: "common" })}
          </span>
        </div>
        <div className="alignH gap-3">
          <Button onClick={() => navigate("/sav")}>{t("View More")}</Button>
          {/* <Button Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}>
            Créer un ticket
          </Button> */}
        </div>
      </div>

      <TicketsSAVPreview />
      <TicketsSAVValues
        values={
          user?.attributes?.userRole === 2
            ? ticketSAVs?.filter((ticketSAV) =>
                currentAffiliate?.informations?.attributes?.organizations?.data
                  .map((affReseller) => affReseller.id)
                  .includes(ticketSAV?.reseller?.id)
              )
            : ticketSAVs
        }
      />
    </div>
  );
};

export default TicketsSAV;
