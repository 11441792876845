import React from "react";
import Button from "../../../Components/Common/FormElements/Button/Button";
import { panierAddProduct } from "../../../REDUX/actions/panier";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ProductTableFooter({ productDetails }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  const hideFooter = user?.attributes?.userRole !== 0;

  const handleAddProduct = () => {
    dispatch(panierAddProduct({ productID: productDetails.id }, t));
  };

  if (hideFooter) return null;
  return (
    <div className="productTableFooter d-flex justify-content-end align-items-center pe-4">
      <Button
        onClick={handleAddProduct}
        disabled={productDetails?.availableQuantity <= 0}
      >
        Ajouter au panier
      </Button>
    </div>
  );
}

export default ProductTableFooter;
