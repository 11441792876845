export const getColumnslist = (role, t) => {
  return [
    { title: "N°", width: "col-1", sort: "number" },
    { title: t("Date"), width: "col-1", sort: "date" },
    { title: t("Invoice"), width: "col-1", sort: "facture.number" },
    { title: t("Client"), width: "col-1", sort: "reseller.name" },
    {
      title: t("Purchase order"),
      width: "col-1",
      sort: "bon_de_commande.number",
    },
    // {
    //   title: "Livraison Souhaité",
    //   width: "col-1",
    //   sort: "estimatedDeliveryDate",
    // },
    { title: t("Delivery"), width: "col-1", sort: "realDeliveryDate" },
    role === "BusinessBroker"
      ? { title: t("Commission"), width: "col-1", sort: "comission" }
      : {
          title: t("BA"),
          width: "col-1",
          sort: "business_affiliate.user.firstName",
        },
    role === "admin" && {
      title: t("Margin Rate"),
      width: "col-1",
      sort: "txMarge",
    },
    { title: t("Amount incl. tax"), width: "col-1", sort: "totalTTC" },
    { title: t("Status"), width: "col-1", sort: "status" },
    { title: t("Payment"), width: "col-1", sort: "payStatus" },
    { title: t("Created by"), width: "col-1", sort: "creator.firstName" },
    // { title: "", width: "col-1" },
  ];
  // const roleMap = {
  //   reseller: [
  //     { title: "N°", width: "col-1", sort: "number" },
  //     { title: "Date", width: "col-1" },
  //     { title: "Facture", width: "col-1" },
  //     { title: "Client", width: "col-1" },
  //     { title: "Livraison Souhaité", width: "col-1" },
  //     { title: "Livraison", width: "col-1" },
  //     { title: "AA", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Marge Net", width: "col-1" },
  //     // { title: "Tx Marge", width: "col-1" },
  //     // { title: "Montant HT", width: "col-1" },
  //     // { title: "TVA", width: "col-1" },
  //     { title: "Montant TTC", width: "col-1" },
  //     { title: "Statut", width: "col-1" },
  //     { title: "Paiement", width: "col-1" },
  //     { title: "Créé par", width: "col-1" },
  //     { title: "", width: "col-1" },
  //     // { title: "N° de commande", width: "col-2" },
  //     // { title: "Date de commande", width: "col-2" },
  //     // { title: "Réf client", width: "col-1" },
  //     // { title: "Montant", width: "col-1" },
  //     // // { title: "Modèles", width: "col-1" },
  //     // { title: "Livraison souhaitée", width: "col-2" },
  //     // { title: "Livrée le", width: "col-1" },
  //     // { title: "Statut", width: "col-1" },
  //     // { title: "", width: "col-1" },
  //   ],
  //   admin: [
  //     { title: "N°", width: "col-1", sort: "number" },
  //     { title: "Date", width: "col-1", sort: "date" },
  //     { title: "Facture", width: "col-1", sort: "facture.number" },
  //     { title: "Client", width: "col-1", sort: "reseller.name" },
  //     {
  //       title: "Livraison Souhaitée",
  //       width: "col-1",
  //       sort: "estimatedDeliveryDate",
  //     },
  //     { title: "Livraison", width: "col-1", sort: "realDeliveryDate" },
  //     { title: "AA", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Marge Net", width: "col-1" },
  //     // { title: "Tx Marge", width: "col-1" },
  //     // { title: "Montant HT", width: "col-1" },
  //     // { title: "TVA", width: "col-1" },
  //     { title: "Montant TTC", width: "col-1" },
  //     { title: "Statut", width: "col-1" },
  //     { title: "Paiement", width: "col-1" },
  //     { title: "Créé par", width: "col-1" },
  //     { title: "", width: "col-1" },
  //   ],
  //   BusinessBroker: [
  //     { title: "N°", width: "col-1", sort: "number" },
  //     { title: "Date", width: "col-1" },
  //     { title: "Facture", width: "col-1" },
  //     { title: "Client", width: "col-1" },
  //     { title: "Livraison Souhaitée", width: "col-1" },
  //     { title: "Livraison", width: "col-1" },
  //     { title: "AA", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Marge Net", width: "col-1" },
  //     // { title: "Tx Marge", width: "col-1" },
  //     // { title: "Montant HT", width: "col-1" },
  //     // { title: "TVA", width: "col-1" },
  //     { title: "Montant TTC", width: "col-1" },
  //     { title: "Statut", width: "col-1" },
  //     { title: "Paiement", width: "col-1" },
  //     { title: "Créé par", width: "col-1" },
  //     { title: "", width: "col-1" },
  //     // { title: "N°", width: "col-1" },
  //     // { title: "Date de commande", width: "col-2" },
  //     // { title: "N° Fact", width: "col-1" },
  //     // { title: "Société", width: "col-1" },
  //     // // { title: "Référence client", width: "col-2" },
  //     // // { title: "AA", width: "col-1" },
  //     // // { title: "Créée par", width: "col-1" },
  //     // { title: "Livraison souhaitée", width: "col-2" },
  //     // { title: "Livrée le", width: "col-1" },
  //     // { title: "Montant", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Paiement", width: "col-2" },
  //   ],
  // };
  // return roleMap[role] || [];
};

export const approvColumnsList = [
  { title: "Container name", className: "w10" },
  { title: "Factory", className: "w10" },
  { title: "Order date", className: "w13" },
  { title: "Estimated date", className: "w13" },
  { title: "Arrival date", className: "w10" },
  { title: "ER €/$", className: "w10" },
  { title: "QC", className: "w10" },
  { title: "Delivery", className: "w10" },
  { title: "Container cost", className: "w10" },
];

export const commandesHeaderTable = [
  { title: "N°", width: "col-1" },
  { title: "N° Fact", width: "col-1" },
  { title: "Société", width: "col-1" },
  { title: "Date livraison", width: "col-1" },
  { title: "AA", width: "col-1" },
  // { title: "Commission", width: "col-1" },
  { title: "Marge Net", width: "col-1" },
  { title: "Tx Marge", width: "col-1" },
  { title: "Montant HT", width: "col-1" },
  { title: "TVA", width: "col-1" },
  { title: "Montant TTC", width: "col-1" },
  { title: "Statut", width: "col-1" },
  { title: "Paiement", width: "col-1" },
];
