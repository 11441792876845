import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openFromProfile,
  openRightModal,
} from "../../../REDUX/actions/rightModal";
import Profile from "../../Profile/Profile";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../REDUX/actions/user";
import { useTranslation } from "react-i18next";

const ProfileDropdown = ({ setIsShowProfile }) => {
  const { t } = useTranslation("common");
  const { userRole } = useSelector((state) => state.userReducer);
  const canSeeTeamView = userRole !== "BusinessBroker";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenProfile = () => {
    dispatch(openFromProfile());
    dispatch(openRightModal(<Profile />));
    setIsShowProfile(false);
  };
  const handleNavigate = () => {
    navigate("equipe");
    setIsShowProfile(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="bg-white br5 p-3 profileDropdownContainer colAlignC align-items-start position-absolute">
      <button
        className="dropdownBtn text-brightGrey text-start f15 px-3 py-1 border-0 bg-transparent br8 w100"
        onClick={handleOpenProfile}
      >
        {t("My profile")}
      </button>
      {canSeeTeamView && (
        <button
          className="dropdownBtn text-brightGrey text-start f15 px-3 py-1 border-0 bg-transparent br8 w100"
          onClick={handleNavigate}
        >
          {t("My team")}
        </button>
      )}
      <hr
        style={{ borderColor: "#abb8b2", marginBottom: 10, marginTop: 10 }}
        width={"100%"}
      />
      <button
        className="dropdownBtn text-brightGrey text-start f15 px-3 py-1 border-0 bg-transparent br8 w100"
        onClick={handleLogout}
      >
        {t("Logout")}
      </button>
    </div>
  );
};

export default ProfileDropdown;
