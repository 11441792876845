import React from "react";
import "./TabsNavbar.css";
import { useTranslation } from "react-i18next";

function TabsNavbar({
  tabs,
  activeTab,
  setActiveTab,
  center,
  className = "",
  disabled,
  disabledIndex,
  tabClassName,
  withBottomBorder,
  bottom = "-16px",
  secondary,
}) {
  const { t } = useTranslation("common");
  const handleClick = (tabName) => {
    setActiveTab(tabName);
  };

  const checkActive = (tabName) => {
    return activeTab === tabName ? "active" : "";
  };

  const centerClassName = center ? "center" : "";
  const botBorderClassName = withBottomBorder ? "botBorder" : "";

  if (secondary)
    return (
      <div
        className={`tabsNavbar p-0 alignH bg-whiteGrey ${centerClassName} ${botBorderClassName} ${className} tab-disabled ${
          secondary ? "secondary" : ""
        }`}
      >
        {tabs.map((tab, index) => {
          const activeClassName = checkActive(tab);
          return (
            <button
              className={`tabBtn flex-1 h100 border-0 d-flex justify-content-center px-3 bg-transparent f15 sBold text-lightBlack position-relative ${activeClassName} ${tabClassName}`}
              onClick={() => handleClick(tab)}
              key={index}
              disabled={(index > 0 && disabled) || disabledIndex === index}
            >
              {t(`${tab}`)}
            </button>
          );
        })}
      </div>
    );

  return (
    <div
      className={`tabsNavbar alignH bg-whiteGrey ${centerClassName} ${botBorderClassName} ${className} tab-disabled ${
        secondary ? "secondary" : ""
      }`}
    >
      {tabs.map((tab, index) => {
        const activeClassName = checkActive(tab);
        return (
          <button
            className={`tabBtn h100 border-0 d-flex justify-content-center px-3 mx-2 bg-transparent f15 sBold text-lightBlack position-relative  ${activeClassName} ${tabClassName}`}
            onClick={() => handleClick(tab)}
            key={index}
            style={{ "--bottomPosition": bottom }}
            disabled={(index > 0 && disabled) || disabledIndex === index}
          >
            {t(`${tab}`)}
          </button>
        );
      })}
    </div>
  );
}

export default TabsNavbar;
