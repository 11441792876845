import React, { useEffect, useState } from "react";
import TextArea from "../Common/FormElements/TextArea/TextArea";
import Select from "../Common/FormElements/Select/Select";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import { useSelector } from "react-redux";
import { isDefined } from "../../Utils";
import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";
import { useTranslation } from "react-i18next";

const NewGuideQuestions = ({
  formData,
  setFormData,
  update,
  language,
  setLanguage,
}) => {
  const { t } = useTranslation("common");

  const { i18n } = useTranslation("common");

  const productTypes = [
    {
      name: t("Products"),
      code: 1,
    },
    {
      name: t("Accessories"),
      code: 2,
    },
    {
      name: t("Communication"),
      code: 3,
    },
    {
      name: t("AS"),
      code: 4,
    },
  ];
  const { categories } = useSelector((state) => state.categoryReducer);
  const { ranges } = useSelector((state) => state.rangeReducer);

  const [optionData, setOptionData] = useState({
    type: productTypes,
    category: [],
    range: [],
    model: [],
  });

  const getModels = async () => {
    try {
      if (isDefined(formData?.type)) {
        const { data } = await API.get(
          `${API_END_POINT}api/products?filters[productType][$eq]=${formData?.type?.code}`
        );

        setOptionData((currentData) => ({
          ...currentData,
          category: [],
          range: [],
          model: data?.data?.map((item) => ({
            name:
              language === "en"
                ? item?.attributes?.name_en ?? item?.attributes?.name
                : item?.attributes?.name,
            code: item?.id,
          })),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle change
  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    if (field === "category") {
      setFormData((formData) => ({
        ...formData,
        range: undefined,
        model: undefined,
      }));
    } else if (field === "range") {
      setFormData((formData) => ({
        ...formData,
        model: undefined,
      }));
    } else if (field === "type") {
      setFormData((formData) => ({
        type: formData?.type,
        title: formData.title,
        description: formData?.description,
      }));
    }
  };

  useEffect(() => {
    setOptionData((currData) => ({
      ...currData,
      category: categories,
    }));
  }, [formData?.type, categories]);

  useEffect(() => {
    setOptionData((data) => ({
      ...data,
      range: formData?.category?.ranges,
    }));
  }, [formData?.category?.ranges]);

  useEffect(() => {
    setOptionData((data) => ({
      ...data,
      model: ranges?.find((range) => +range?.code === +formData?.range?.code)
        ?.models,
    }));
  }, [formData?.range, ranges]);

  useEffect(() => {
    if ([2, 4, 3]?.includes(formData?.type?.code)) {
      getModels(i18n.language);
    }
  }, [formData?.type?.code, i18n.language]);

  return (
    <div className="d-flex flex-column px-5 pt-4 gap-4">
      <div className="w100 d-flex flex-column gap-2">
        <div className="col-4 d-flex flex-column gap-2">
          <span className="text-softBlack normal f16">{t("Language")}</span>
          <Select
            resetable
            showClear
            className="w100"
            placeholder={t("Language")}
            options={[
              { name: t("French"), value: "fr" },
              { name: t("English"), value: "en" },
            ]}
            value={language}
            callback={(selectedOption) => {
              setLanguage(selectedOption.value);
            }}
          />
        </div>
        <span className="text-softBlack normal f16">{t("Title")}</span>
        <TextInput
          className="w100"
          value={formData.title ?? ""}
          callback={(e) => handleChange("title", e.target.value)}
        />
      </div>
      <div className="row alignH gy-3">
        {selectList.map(({ label, name, types }, id) => {
          if (types?.includes(formData?.type?.code) || types === undefined)
            return (
              <div className="col-4 d-flex flex-column gap-2" key={id}>
                <span className="text-softBlack normal f16">
                  {t(`${label}`)}
                </span>
                <Select
                  resetable
                  showClear
                  className="w100"
                  placeholder={t(`${label ?? ""}`)}
                  options={optionData[name]?.map(
                    ({ name, code, ...props }) => ({
                      name: t(`${name}`),
                      code,
                      ...props,
                    })
                  )}
                  value={formData[name]}
                  callback={(selectedOption) => {
                    handleChange(name, selectedOption.value);
                  }}
                />
              </div>
            );
          return null;
        })}
      </div>
      <div className="w100 d-flex flex-column gap-2">
        <span className="text-softBlack normal f16">{t("Description")}</span>
        <TextArea
          className={"w-100 b-2-softGrey h100"}
          value={formData.description || ""}
          callback={(e) => handleChange("description", e.target.value)}
        />
      </div>
    </div>
  );
};

export default NewGuideQuestions;

const selectList = [
  {
    label: "Type",
    name: "type",
    placeholder: "Type",
  },
  {
    label: "Categories",
    name: "category",
    placeholder: "Category",
    types: [1],
  },
  {
    label: "Range",
    name: "range",
    placeholder: "Gamme",
    types: [1],
  },
  {
    label: "Model",
    name: "model",
    placeholder: "Model",
    types: [1, 2, 3, 4],
  },
];
