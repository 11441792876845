import moment from "moment";
import QueryString from "qs";

export const approvsQuery = ({ searchInput, factory, year, month }) => {
  let dateMonth;
  if (month && year) {
    dateMonth = moment(`${year}-${month}-01`);
  }

  let dateYear;
  if (year) {
    dateYear = moment(`${year}-01-01`);
  }

  const dateFilter = dateMonth
    ? {
        $gte: moment(dateMonth).startOf("month").format("YYYY-MM-DD"),
        $lte: moment(dateMonth).endOf("month").format("YYYY-MM-DD"),
      }
    : dateYear
    ? {
        $gte: moment(dateYear).startOf("year").format("YYYY-MM-DD"),
        $lte: moment(dateYear).endOf("year").format("YYYY-MM-DD"),
      }
    : {};
  return QueryString.stringify(
    {
      fields: [
        "id",
        "containerName",
        "factory",
        "orderDate",
        "estimatedDate",
        "arrivalDate",
        "taux",
        "deliveryPrice",
        "QC",
        "returnCost",
        "amountTTC",
        "TVA",
        "amountHT",
        "entrepot",
      ],
      populate: {
        packings: {
          populate: {
            model: true,
            entrepot: true,
          },
        },
        payments: {
          fields: ["id", "amount", "date"],
        },
        realEntrepot: {
          fields: ["id", "name"],
        },
        attachments: {
          fields: ["id", "title", "createdAt"],
          populate: {
            file: { fields: "*" },
          },
        },
      },
      filters: {
        $or: [
          { containerName: { $containsi: searchInput ?? "" } },
          { packings: { serialNumber: { $eq: searchInput ?? "" } } },
        ],

        factory: { $eq: factory },
        arrivalDate: dateFilter,
      },
      sort: ["id:desc"],
      // start: (page - 1) * 5,
      // limit: 5,
    },
    { encodeValuesOnly: true }
  );
};
