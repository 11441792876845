import React from "react";
import { useTranslation } from "react-i18next";
import enFlag from "../../Images/Icons/united-kingdom-flag-icon.svg";
import frFlag from "../../Images/Icons/france-flag-icon.svg";

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const languages = [
    { icon: frFlag, value: "fr" },
    { icon: enFlag, value: "en" },
  ];

  return (
    <div className="flex bg-white rounded overflow-hidden">
      {languages.map((language) => (
        <button
          type="button"
          onClick={() => i18n.changeLanguage(language.value)}
          className={`text-black f14 rounded py-1 medium t02 px-2 border-0 ${
            currentLanguage.includes(language.value)
              ? "bg-softGrey"
              : "bg-white"
          }`}
        >
          <img src={language.icon} alt="" width={30} height={30} />
        </button>
      ))}
    </div>
  );
};

export default LanguageSelector;
