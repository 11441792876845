import React, { useEffect, useState } from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import { Col, Row } from "react-bootstrap";
import { equipementForm } from "./data";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const EquipmentProduct = ({ handleFormsChange, initialValues }) => {
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState(initialValues ?? {});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleClick = () => {
  //   if (!invalidFields?.length > 0) {
  //     if (currentEquipement) {
  //       dispatch(updateCurrentEquipement(formData, currentEquipement?.id));
  //     } else {
  //       dispatch(createEquipement(formData));
  //     }
  //   }
  // };

  useEffect(() => {
    handleFormsChange("equipement", formData);
  }, [formData]);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <Row>
          {equipementForm.map((el) => {
            if (el.type === "select") {
              return (
                <Col
                  lg={3}
                  key={el.name}
                  className="mb-3 d-flex flex-column gap-2"
                >
                  <span className="text-softBlack normalf16">
                    {t(`${el.label}`, { ns: "common" })}
                  </span>
                  <Select
                    name={el.name}
                    placeholder={t(`${el.label ?? ""}`, { ns: "common" })}
                    value={formData[el.name]}
                    options={el.options}
                    label={t(`${el.label}`, { ns: "common" })}
                    callback={handleChange}
                  />
                </Col>
              );
            }
            return (
              <Col
                lg={3}
                key={el.name}
                className="mb-3 d-flex flex-column gap-2"
              >
                <span className="text-softBlack normalf16">
                  {t(`${el.label}`, { ns: "common" })}
                </span>
                <TextInput
                  className={"w100"}
                  type={el.type}
                  name={el.name}
                  value={formData[el.name]}
                  placeholder={t(`${el.placeholder ?? ""}`, { ns: "common" })}
                  euro={el.euro}
                  callback={handleChange}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      {/* <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>

        <Button
          className="px-5"
          disabled={disabled}
          loading={loading}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </>
  );
};

export default EquipmentProduct;
