import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Attachements from "../../Common/Forms/Attachements/Attachements";
import { transformSavedAttachments } from "./transformSavedAttachments";
import Button from "../../Common/FormElements/Button/Button";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import { updateTicketAttachments } from "../../../REDUX/actions/ticketSAV";
import { useTranslation } from "react-i18next";

function SavPreviewAttachments({ ticketId }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const { ticketSAV, ticketSAVLoading, ticketSAVError, ticketSAVReload } =
    useSelector((state) => state.ticketSAVReducer);
  const initialState = ticketSAV?.attachments
    ? transformSavedAttachments(ticketSAV?.attachments)
    : [];
  const [attachments, setAttachments] = useState(initialState);

  const handleUpdate = () => {
    dispatch(updateTicketAttachments({ id: ticketId, attachments }));
  };

  useEffect(() => {
    setAttachments(transformSavedAttachments(ticketSAV?.attachments));
  }, [ticketSAVReload, ticketSAV?.attachments]);

  return (
    <ShadowLoader error={ticketSAVError}>
      <div className="h100 d-flex flex-column justify-content-between mt-4">
        <Attachements
          attachments={attachments}
          setAttachments={setAttachments}
          acceptTypes={"/*"}
        />
        <div className="d-flex justify-content-center my-5">
          <Button onClick={handleUpdate} loading={ticketSAVLoading}>
            {t("Save")}
          </Button>
        </div>
      </div>
    </ShadowLoader>
  );
}

export default SavPreviewAttachments;
