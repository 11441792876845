import React from "react";
import { useTranslation } from "react-i18next";

const ListProductsTitles = () => {
  const { t } = useTranslation("common");
  return (
    <div className="row bg-dimGrey py-3 ps-4 mx-0 w-100 d-flex justify-content-between align-items-center">
      <span className="col-1 text-brightGrey sBold sBold f14"></span>
      <span className="col-2 text-brightGrey sBold sBold f14">{t("Name")}</span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Category")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Range")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Purchase Price")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Margin Rate PO")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Res. Price Excl. Tax")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Res. Margin Rate")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Public Price Incl. Tax")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        {t("Status")}
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14"></span>
    </div>
  );
};

export default ListProductsTitles;
