import React from "react";
import DeleteBtn from "../../Common/FormElements/DeleteBtn";
import Button from "../../Common/FormElements/Button/Button";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const DeleteInvoicesModal = ({ handleDelete = () => {}, message }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  return (
    <div className="d-flex align-items-center flex-column gap-2">
      <p>{message}</p>
      <div className="d-flex gap-3">
        <DeleteBtn onClick={handleDelete} />
        <Button onClick={() => dispatch(closeCenteredModal())}>
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteInvoicesModal;
