const approvDetailsLabels = [
  { label: "Model", className: "col-3" },
  { label: "Reference", className: "col-1" },
  { label: "Tank", className: "col-1" },
  { label: "Panel", className: "col-1" },
  { label: "Serial number", className: "col-1" },
  { label: "Prix achat", className: "col-1" },
  { label: "QC", className: "col-1" },
  { label: "Purchase Price", className: "col-1" },
  { label: "Transport", className: "col-1" },
  { label: "Delivered cost", className: "col-1" },
];

export default approvDetailsLabels;
