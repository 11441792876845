import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./Chart.css";
import { soldProductColors } from "../../../helpers/fakeData/Dashboard";
import { useTranslation } from "react-i18next";

const DonutChart = ({ data = [], labels }) => {
  const { t } = useTranslation("common");
  const [series, setSeries] = useState([]);
  const [colors] = useState(soldProductColors);
  useEffect(() => {
    setSeries(data);
  }, [data]);
  return (
    <div className="w-100">
      <Chart
        options={{
          labels: labels.filter((_, index) => !!data[index]),
          colors: colors.filter((_, index) => !!data[index]),
          legend: { show: false },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  value: {
                    show: true,
                    fontSize: "36px",
                    fontFamily: "Open Sans",
                    fontWeight: 700,
                    color: "#050708",
                    offsetY: 16,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: false,
                    label: t("Average"),
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    color: "#abb8b2",
                    formatter: function (w) {
                      return (
                        Number(
                          w.globals.seriesPercent.reduce((a, b) => {
                            return a + b[0];
                          }, 0)
                        ) / w.globals.seriesPercent.length
                      ).toFixed(1);
                    },
                  },
                },
              },
            },
          },
          stroke: {
            show: true,
            curve: "smooth",
            colors: undefined,
            width: 8,
            dashArray: 0,
          },
          dataLabels: {
            textAnchor: "middle",
            style: {
              fontSize: "17px",
              fontFamily: "Open Sans",
              fontWeight: "300",
            },
            background: {
              enabled: false,
              foreColor: "white",
              padding: 0,
              borderWidth: 0,
              opacity: 1,
            },
          },
        }}
        series={series?.filter((item) => !!item)}
        type="donut"
        height={370}
      />
    </div>
  );
};

export default DonutChart;
