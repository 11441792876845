const checkFormDataValidity = (formData) => {
  for (const key in formData) {
    if (
      !formData[key] &&
      key !== "refClient" &&
      key !== "serialNumber" &&
      key !== "commande" &&
      key !== "product" &&
      key !== "deliveryDate" &&
      key !== "warrantyEndDate"
    ) {
      return false;
    }
  }
  return true;
};

export default checkFormDataValidity;
