import React from "react";
import { v4 as uuidv4 } from "uuid";

import "./AddAttachment.css";
import { useTranslation } from "react-i18next";

function AddAttachment({
  attachments,
  setAttachments,
  fromNewProduct,
  fromGuide,
}) {
  const { t } = useTranslation("common");
  const handleClick = () => {
    setAttachments([...attachments, { id: uuidv4(), title: "", date: "" }]);
  };

  return (
    <button
      onClick={handleClick}
      className="addAttachment ms-3 mt-3 f12 text-softBlue border-0 bg-transparent underline"
    >
      {fromNewProduct
        ? `+ ${t("Add a new photo or video")}`
        : fromGuide
        ? `+ ${t("Add new file")}`
        : `+ ${t("Add new document")}`}
    </button>
  );
}

export default AddAttachment;
