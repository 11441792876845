import React, { useState } from "react";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import CalendarToolbar from "../Components/Calendar/CalendarToolbar/CalendarToolbar";
import CalendarMain from "../Components/Calendar/CalendarMain/CalendarMain";
import RightModal from "../Components/Modals/RightModal/RightModal";
import EventsAndNews from "../Components/Dashboard/EventsAndNews/EventsAndNews";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import Select from "../Components/Common/FormElements/Select/Select";
import { useTranslation } from "react-i18next";

const CalendarView = () => {
  const { t } = useTranslation("common");
  const selectOptions = [
    { name: t("All"), code: "Tous" },
    { name: t("Showrooms"), code: "Salons" },
    { name: t("Events"), code: "Événements" },
    { name: t("Deliveries"), code: "Livraisons" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    name: t("All"),
    code: "Tous",
  });
  return (
    <ViewScroller>
      <div>
        <div className="row justify-content-between">
          <div className="col-3">
            <ViewHeader title={t("Calendar")} />
          </div>
          <div className="col-2">
            <Select
              options={selectOptions}
              placeholder={t("Select an option")}
              value={selectedOption}
              callback={(e) => setSelectedOption(e.value)}
            />
          </div>
        </div>
        <CalendarToolbar />
        <CalendarMain selectedOption={selectedOption} />
        <RightModal width={55} />
        {/* <CenteredModal /> */}
        <div className="mt-5">
          <EventsAndNews />
        </div>
      </div>
    </ViewScroller>
  );
};

export default CalendarView;
