import { useTranslation } from "react-i18next";

export const TicketProblemPicker = (problem) => {
  const { t } = useTranslation("common");

  switch (problem) {
    case 1:
      return t("Breakdown");
    case 2:
      return t("Electricity");
    case 3:
      return t("Break");
    case 4:
      return t("Leak");
    case 5:
      return t("Transport");
    case 6:
      return t("Accessories");
    default:
      return t("Breakdown");
  }
};
