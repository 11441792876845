import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../REDUX/actions/catalogue";
import cartIcon from "../../Images/Icons/cart.svg";
import { Spinner } from "react-bootstrap";

const tabsMap = {
  Products: "Produits",
  Accessories: "Accessoires",
  "Marketing Products": "Produits marketing",
  "Spare Part": "Pièce Détachée",
  Services: "Services",
  SPA: "SPA",
  "SWIM SPA": "SPA DE NAGE",
  SAUNA: "SAUNA",
};

const AddToCartButton = ({
  qte = 1,
  packingItemId,
  activeTab,
  searchInput,
  productsactiveTab,
}) => {
  const { cartLoading } = useSelector((state) => state.catalogueReducer);
  const dispatch = useDispatch();
  return (
    <button
      className="outline-none border-0 p-1 px-3 bg-darkBlue d-flex align-items-center justify-content-center br14 my-1"
      style={{ height: "30px" }}
      onClick={() =>
        dispatch(
          addToCart(packingItemId, qte, {
            activeTab: tabsMap[activeTab],
            searchInput,
            productsactiveTab: tabsMap[productsactiveTab],
          }),
        )
      }
    >
      {cartLoading === packingItemId ? (
        <Spinner
          size="sm"
          animation="border"
          variant="light"
          className="small-spinner"
        />
      ) : (
        <img
          src={cartIcon}
          alt=""
          width="20px"
          height="auto"
          className="mb-0 ob-contain"
        />
      )}
    </button>
  );
};

export default AddToCartButton;
