import React, { useEffect, useState } from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { resellerForm } from "./data";
import { useTranslation } from "react-i18next";

function Infromations({ initialValues, handleFormChange, customErrors }) {
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState(initialValues);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const errorsMap = {
    exist: t("This email is already in use."),
    phoneNumberError: t("Please enter a valid phone number."),
    emailError: t("The email address is not valid!"),
  };
  useEffect(() => {
    handleFormChange("infos", formData);
  }, [formData]);

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="modalContent px-5 pt-4">
        <div className="row">
          {resellerForm.map((el) => (
            <div className="col-6 mt-4" key={el?.name}>
              <p className="f16 text-ultraBlack mb-2">{t(`${el?.label}`)}</p>
              <TextInput
                {...el}
                label={el.label ? t(`${el.label}`) : ""}
                placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                value={formData[el?.name]}
                callback={handleChange}
              />
              {customErrors &&
                errorsMap.hasOwnProperty(customErrors[el?.name]) && (
                  <div className="mt-2 text-redCalendar f14">
                    {errorsMap[customErrors[el?.name]]}
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>

      {/* <div className="buttonsContainer d-flex justify-content-center mt-auto">
        <Button variant="outline" className="me-4">
          Annuler
        </Button>
        <Button
          loading={loading}
          disabled={disabled}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </div>
  );
}

export default Infromations;
