import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProspectPreview from "./ProspectPreview";
import { getProspects } from "../../../REDUX/actions/prospects";
import { PointerSvg } from "../../Svgs";
import { getResellers } from "../../../REDUX/actions/reseller";
import { useTranslation } from "react-i18next";

const ProspectsPreviewValues = () => {
  const { t } = useTranslation("common");
  const { user, userRole } = useSelector((state) => state.userReducer);
  const { prospects } = useSelector((state) => state.prospectsReducer);
  const { resellers } = useSelector((state) => state.resellerReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.attributes?.userRole === 0) {
      dispatch(getProspects(""));
    } else {
      dispatch(getResellers());
    }
  }, [user?.id, dispatch]);

  return (
    <div>
      {userRole === "admin" || userRole === "BusinessBroker"
        ? resellers?.slice(0, 6)?.map((reseler, index) => (
            <div className="colAlignC" key={reseler?.id}>
              <ProspectPreview
                nom={reseler?.attributes?.name}
                email={reseler?.attributes?.email}
                tel={reseler?.attributes?.phoneNumber}
                withoutOptions
              />
              {index + 1 !== resellers?.slice(0, 6).length && (
                <hr
                  style={{ borderColor: "#abb8b2", margin: 0 }}
                  width={"100%"}
                />
              )}
            </div>
          ))
        : prospects?.slice(0, 6)?.map((prospect, index) => (
            <div className="colAlignC" key={prospect?.id}>
              <ProspectPreview
                id={prospect?.id}
                nom={`${prospect?.firstName} ${prospect?.lastName}`}
                tel={prospect?.phoneNumber}
                email={prospect?.email}
                Icon={<PointerSvg />}
              />
              {+prospect?.id !== prospects?.length && (
                <hr
                  style={{ borderColor: "#abb8b2", margin: 0 }}
                  width={"100%"}
                />
              )}
            </div>
          ))}
    </div>
  );
};

export default ProspectsPreviewValues;
