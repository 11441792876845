import React from "react";
import { useDispatch } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/fontawesome-free-solid";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import AskForQuote from "./AskForQuote";
import { GlobeSvg, PointerSvg } from "../Svgs";
import CustomTooltip from "../Common/CustomTooltip/CustomTooltip";
import {
  closeCenteredModal,
  openCenteredModal,
} from "../../REDUX/actions/centeredModal";
import { openRightModal } from "../../REDUX/actions/rightModal";
import Prospect from "../Calendar/Modals/Prospect/Prospect";
import { deleteProspect } from "../../REDUX/actions/prospects";
import DeleteProspect from "./DeleteProspect";
import { useTranslation } from "react-i18next";

const ProspectCard = ({
  lastName,
  firstName,
  phoneNumber,
  email,
  adresse,
  id,
  refClient,
  comments,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  return (
    <div>
      <div
        className={`row px-4 py-4 d-flex align-items-center w-100 orderCardContainer pointer `}
        onClick={() => dispatch(openRightModal(<Prospect id={id} />))}
      >
        <span className={`f14 normal col-2 text-customBlack `}>
          {firstName} {lastName}
        </span>
        <span className={`f14 normal col-2 text-lightBlack `}>
          {phoneNumber}
        </span>
        <span className={`f14 normal col-2 text-lightBlack ellipsis-text`}>
          {email}
        </span>
        <div className="col-2">
          <p className={`f14 normal  text-lightBlack mb-1 `}>
            {adresse?.street}
          </p>
          <p className={`f14 normal text-lightBlack mb-1 `}>
            {adresse?.city?.data?.attributes?.name}
          </p>
          <p className={`f14 normal text-lightBlack mb-1 `}>
            {adresse?.country?.data?.attributes?.name}
          </p>
        </div>
        <span className={`f14 normal col-2 text-lightBlack `}>
          {refClient ?? "-"}
        </span>
        {/* <span className={` f14 normal col-1 pointer `}>
          <FontAwesomeIcon icon={faChevronDown} size={"xl"} color={"#666666"} />
        </span> */}
        <span className={` f14 normal col-2 `}>
          <VerticalPoints
            showLeft
            options={[
              {
                text: t("Prospect profile"),
                callback: () => dispatch(openRightModal(<Prospect id={id} />)),
              },
              {
                text: t("Quotation request"),
                hidden: true,
                callback: () => dispatch(openCenteredModal(<AskForQuote />)),
              },
              {
                text: t("Delete prospect"),
                red: true,
                callback: () =>
                  dispatch(
                    openCenteredModal(
                      <DeleteProspect
                        payload={{ id, comments: comments?.data }}
                      />,
                    ),
                  ),
              },
            ]}
            className="ms-auto"
            // color={showOrder ? "white" : ""}
          />
        </span>
      </div>
    </div>
  );
};

export default ProspectCard;
