/* ************************* events actions reducer ************************* */
// dependencies
import { toast } from "react-toastify";
import API from "../../Api/Api";
import qs from "qs";
import {
  EVENTS_FETCHED,
  EVENTS_LOADING,
  EVENT_LOADING,
  EVENT_UPDATED,
  EVENT_FETCHED,
  CREATED_EVENT_LOADING,
  CREATED_EVENT_SUCCESS,
  DELETE_EVENT,
} from "../reducer/events";
import { closeRightModal } from "./rightModal";
import { eventTypeFr } from "../../Utils/fileUtils";
import { uploadFile } from "../../Utils";
// get Events
export const getEvents = () => async (dispatch, getState) => {
  const { user } = getState().userReducer;
  dispatch({ type: EVENTS_LOADING });
  let filters = {};
  if (user?.attributes?.userRole === 0) {
    const resellerId =
      user?.attributes?.profil?.data?.attributes?.reseller?.data?.id;
    filters = {
      $or: [
        {
          resellers: {
            id: {
              $eq: resellerId,
            },
          },
        },
        {
          resellers: {
            id: {
              $null: true,
            },
          },
        },
      ],
    };
  } else if (user?.attributes?.userRole === 2) {
    const businessAffiliateId = user?.attributes?.business_affiliate?.data?.id;
    filters = {
      $or: [
        {
          resellers: {
            business_affiliate: {
              id: { $eq: businessAffiliateId },
            },
          },
        },
        {
          resellers: {
            id: {
              $null: true,
            },
          },
        },
      ],
    };
  }
  const query = qs.stringify({
    fields: [
      "title",
      "startDate",
      "endDate",
      "startTime",
      "endTime",
      "eventType",
      "visitLink",
      "description",
    ],
    populate: {
      Image: { fields: ["url"] },
    },
    filters,
  });
  try {
    const { data: events } = await API.get(`api/events?${query}`);
    dispatch({ type: EVENTS_FETCHED, payload: events });
  } catch (err) {
    console.log(err);
    toast.error();
  }
};
// create event action :
export const createEvent = (payload, t) => async (dispatch) => {
  const { newEvent } = payload;
  dispatch({ type: CREATED_EVENT_LOADING });
  const query = qs.stringify({
    populate: {
      Image: { fields: ["url"] },
    },
  });

  try {
    let uploadedImageId = null;
    if (newEvent.eventType === "event" && newEvent.photo) {
      uploadedImageId = await uploadFile(newEvent.photo);
    }

    const { data: createdEvent } = await API.post(`/api/events?${query}`, {
      data: { ...newEvent, Image: uploadedImageId },
    });

    dispatch({ type: CREATED_EVENT_SUCCESS, payload: createdEvent.data });
    dispatch(closeRightModal());
    toast.success(
      `${eventTypeFr[newEvent.eventType]} ${t(`successfully created!`, {
        ns: "common",
      })}`
    );
  } catch (error) {
    console.log(error, "error");
    toast.error(
      t(`An error has occurred!`, {
        ns: "common",
      })
    );
  }
};
// getSingleEvent action:
export const getSingleEvent = (eventId) => async (dispatch) => {
  dispatch({ type: EVENT_LOADING });
  const query = qs.stringify({
    fields: [
      "title",
      "startDate",
      "endDate",
      "startTime",
      "endTime",
      "eventType",
      "visitLink",
      "description",
    ],
    populate: {
      Image: { fields: ["url", "name"] },
      resellers: { populate: true },
    },
  });

  try {
    const { data: singleEvent } = await API.get(
      `api/events/${eventId}?${query}`
    );

    dispatch({
      type: EVENT_FETCHED,
      payload: singleEvent,
    });
  } catch (err) {
    toast.error("Erreur lors de la récupération de l'événement.");
  }
};
// delete event :
export const deleteEvent = (eventId, eventType, t) => async (dispatch) => {
  try {
    await API.delete(`/api/events/${eventId}`);
    dispatch({ type: DELETE_EVENT, payload: eventId });
    dispatch(closeRightModal());
    toast.success(`${eventTypeFr[eventType]} ${t("successfully deleted!")}`);
  } catch (error) {
    console.log(error, "error");
    toast.error(t("An error occurred while deleting the event."));
  }
};

export const updateEvent = (payload, t) => async (dispatch) => {
  const { updatedEvent, eventId } = payload;
  try {
    dispatch({ type: EVENT_LOADING });
    let uploadedImageId = null;
    if (updatedEvent.newPhoto) {
      uploadedImageId = await uploadFile(updatedEvent.newPhoto);
    }
    if (uploadedImageId) {
      updatedEvent.Image = uploadedImageId;
    } else {
      updatedEvent.Image = updatedEvent?.Image.data?.id;
    }
    await API.put(`api/events/${eventId}`, {
      data: {
        ...updatedEvent,
      },
    });
    dispatch({ type: EVENT_UPDATED });
    dispatch(getEvents());
    dispatch(closeRightModal());
    toast.success(t("Event successfully updated"));
  } catch (error) {
    console.log(error);
  }
};
