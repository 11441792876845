import React, { useEffect, useState } from "react";
import moment from "moment";
import { saveAs } from "file-saver";

import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import ProductAttachementBtns from "./ProductAttachementBtns";
import { CommandesSvg, FactureSvg, ProspectsSvg } from "../../Svgs";

import "../../Common/Forms/Attachements/AttachmentItem/AttachmentItem.css";
import { useDispatch } from "react-redux";
import { deleteDocumentAttachements } from "../../../REDUX/actions/products";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { documentExtensions } from "./data";
import { useTranslation } from "react-i18next";

function ProductAttachementItem({
  id,
  title,
  docType,
  icon,
  file,
  fileName,
  fileUrl,
  attachments,
  setAttachments,
  newFileName,
}) {
  const { t } = useTranslation("common");
  const icons = [
    <FactureSvg width={15} />,
    <CommandesSvg width={15} />,
    <ProspectsSvg height={15} />,
  ];

  const dispatch = useDispatch();

  const handleUpload = (e) => {
    const modifiedAttachments = attachments?.map((attach) =>
      attach?.id === id
        ? {
            ...attach,
            title: e.target.files[0]?.name,
            file: e.target.files[0],
            date: moment().format("DD-MM-YYYY"),
          }
        : attach
    );
    setAttachments(modifiedAttachments);
  };
  const handleDelete = () => {
    const modifiedAttachments = attachments.filter(
      (attach) => attach.id !== id
    );
    setAttachments(modifiedAttachments);
  };

  const deleteAddedAttachements = () => {
    const attachementsToDelete = attachments?.filter(
      (attach) => attach?.id === id
    );

    dispatch(
      deleteDocumentAttachements(attachementsToDelete, () => handleDelete())
    );
  };

  const handleChange = (e) => {
    const modifiedAttachments = attachments.map((attach) =>
      attach.id === id
        ? {
            ...attach,
            [e.target.name]: e.target.value,
          }
        : attach
    );
    setAttachments(modifiedAttachments);
  };

  const handleNameChange = (e) => {
    const modifiedAttachments = attachments.map((attach) =>
      attach.id === id
        ? {
            ...attach,
            title: e.target.value,
          }
        : attach
    );
    setAttachments(modifiedAttachments);
  };

  return (
    <div className="productAttachementItem">
      <div className={`attachmentItem d-flex align-items-center p-3 `}>
        <TextInput
          className="w40 pe-4"
          placeholder={t("SPA")}
          inputClassName={"f12"}
          placeholderStyle={"f12"}
          value={title}
          callback={handleNameChange}
        />

        <div className="w40">
          {file || fileUrl ? (
            <>
              <span
                onClick={() => saveAs(IMAGE_END_POINT + fileUrl, fileName)}
                className="documentLink f12 text-softBlue mb-0 w100 pointer"
              >
                {newFileName ? newFileName : fileName}
              </span>
            </>
          ) : (
            <div>
              <label>
                <div className="bg-softBlue pointer d-flex text-white align-items-center px-4 py-2 br5">
                  <i className="bi bi-upload" />
                  <p className="f12 mb-0 ms-2">{t("Import document")}</p>
                </div>
                <input
                  hidden
                  type="file"
                  onChange={handleUpload}
                  accept={documentExtensions.join(",")}
                />
              </label>
            </div>
          )}
        </div>
        <ProductAttachementBtns
          handleDelete={deleteAddedAttachements}
          preview={IMAGE_END_POINT + fileUrl}
        />
      </div>
      <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
    </div>
  );
}

export default ProductAttachementItem;
