import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../Components/Common/FormElements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

function IBANinput() {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [copied, setcopied] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setcopied(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);
  const inputValue = show
    ? "FR76 3000 6000 0112 3456 7890 189"
    : "**** **** **** **** **** **** ***";
  const iconClassName = !show ? "bi bi-eye-fill" : "bi bi-eye-slash-fill";
  return (
    <div className="d-flex">
      <div className="d-flex flex-1">
        <input
          type="text"
          className="ibanInput br5 p-3 b-1-darkGrey w100"
          value={inputValue}
          disabled
        />
        <div
          onClick={handleClick}
          className="inputEye pointer br50 p-2 d-flex align-items-center justify-content-center"
        >
          <i className={iconClassName} />
        </div>
      </div>
      <Button
        RightIcon={() => (
          <FontAwesomeIcon icon={copied ? faCheckSquare : faPaste} />
        )}
        className="ms-3"
        onClick={() => {
          setcopied(true);
          navigator.clipboard.writeText("FR76 3000 6000 0112 3456 7890 189");
        }}
      >
        {copied ? t("IBAN copied") : t("Copy IBAN")}
      </Button>
    </div>
  );
}

export default IBANinput;
