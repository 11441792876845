import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeleteBtn = ({ onClick = () => {}, type = "button", loading }) => {
  const { t } = useTranslation("common");
  return (
    <button
      type={type}
      className="deleteButton bg-redWithoutOp text-white border-0 px-4 br5"
      onClick={onClick}
    >
      {loading ? (
        <Spinner animation="border" variant="light" className="small-spinner" />
      ) : (
        t("Delete")
      )}
    </button>
  );
};

export default DeleteBtn;
