import React, { useEffect, useState } from "react";
import AddToCartButton from "./AddToCartButton";
import Button from "../Common/FormElements/Button/Button";
import { useTranslation } from "react-i18next";

const CatalogueItemCard = ({
  product,
  packingGroup,
  activeTab,
  searchInput,
  productsactiveTab,
}) => {
  const { t } = useTranslation("common");
  const [qte, setqte] = useState(1);
  useEffect(() => {
    setqte(
      qte > packingGroup.packings.length ? packingGroup.packings.length : qte
    );
  }, [packingGroup.packings.length]);

  return product.attributes.productType === 1 ? (
    <div className="f12 bold my-1">
      {packingGroup.packings.map((packingItem) => (
        <div
          className="alignH justify-content-between packingItem"
          key={packingItem.id}
        >
          <div>
            {t("SN")}:{packingItem.serialNumber}
          </div>
          <AddToCartButton
            packingItemId={packingItem.id}
            activeTab={activeTab}
            searchInput={searchInput}
            productsactiveTab={productsactiveTab}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="f12 bold my-1 alignH justify-content-between border-bottom">
      <div className="alignH gap-3">
        {t("Quantity")}{" "}
        <button
          className="bold alignC qteButton"
          onClick={() => setqte(qte > 1 ? qte - 1 : 1)}
        >
          -
        </button>
        {qte}{" "}
        <button
          className="bold alignC qteButton f16"
          onClick={() =>
            setqte(
              qte < packingGroup.packings.length
                ? qte + 1
                : packingGroup.packings.length
            )
          }
        >
          +
        </button>
      </div>
      <div>
        <AddToCartButton
          qte={qte}
          packingItemId={packingGroup.packings[0].id}
          activeTab={activeTab}
          searchInput={searchInput}
          productsactiveTab={productsactiveTab}
        />
      </div>
    </div>
  );
};

export default CatalogueItemCard;
