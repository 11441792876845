import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Step4MissingData = () => {
  const { t } = useTranslation("common");
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);

  return !cart.addresseDeLivraison ||
    !cart.addresseDeFacturation ||
    !cart.reseller ||
    !cart.deliveryOptionName ||
    !cart.business_affiliate ||
    !cart.facture ||
    !cart.bon_de_commande ? (
    <div className="mt-3 bold">
      <div className="f12 text-red">{t("Missing details")}</div>
      {!cart.addresseDeLivraison ? (
        <div className="text-red f10">
          {t("Delivery address")} ({t("required")})
        </div>
      ) : null}
      {!cart.addresseDeFacturation ? (
        <div className="text-red f10">
          {t("Billing address")} ({t("required")})
        </div>
      ) : null}
      {!cart.deliveryOptionName ? (
        <div className="text-red f10">
          {t("Delivery method")} ({t("required")})
        </div>
      ) : null}
      {!cart.reseller ? (
        <div className="text-red f10">
          {t("Reseller")} ({t("required")})
        </div>
      ) : null}
      {!cart.business_affiliate && userRole !== "reseller" ? (
        <div className="text-red f10">{t("Business affiliate")}</div>
      ) : null}
      {!cart.facture ? (
        <div className="text-red f10">{t("Invoice")}</div>
      ) : null}
      {!cart.bon_de_commande ? (
        <div className="text-red f10">{t("Purchase order")}</div>
      ) : null}
    </div>
  ) : null;
};

export default Step4MissingData;
