import React, { useState } from "react";
import CheckBox from "../../Common/FormElements/CheckBox/CheckBox";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeliveryCalendar from "../../Catalogue/DeliveryDetails/DeliveryCalendar/DeliveryCalendar";
import { updateCommande } from "../../../REDUX/actions/commandes";
import { useTranslation } from "react-i18next";

function DeliveryDialog({
  oldEstimatedDate,
  orderStatus,
  orderId,
  isEstimatedDate,
  oldRealDeliveryDate,
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] =
    useState(oldEstimatedDate);
  const [realDeliveryDate, setRealDeliveryDate] = useState(oldRealDeliveryDate);

  const handleCancel = () => {
    dispatch(closeCenteredModal());
  };
  const handleSubmit = () => {
    isEstimatedDate
      ? dispatch(updateCommande(orderId, { estimatedDeliveryDate }))
      : dispatch(updateCommande(orderId, { realDeliveryDate }));
    dispatch(closeCenteredModal());
  };
  return (
    <div className="deliveryDialog">
      <h2 className="f23 sBold text-brightGrey mb-4">
        {isEstimatedDate ? t("Desired delivery on") : t("Delivery on")}
      </h2>
      <DeliveryCalendar
        setSelectedDate={
          isEstimatedDate ? setEstimatedDeliveryDate : setRealDeliveryDate
        }
        selectedDate={
          isEstimatedDate ? estimatedDeliveryDate : realDeliveryDate
        }
        orderStatus={orderStatus}
      />
      <CheckBox
        text={t("I don't know")}
        className="mt-3"
        textClassName="text-brightGrey f15"
        callback={() =>
          isEstimatedDate
            ? setEstimatedDeliveryDate(null)
            : setRealDeliveryDate(null)
        }
      />
      <div className="deliveryDialogFooter mt-3 d-flex justify-content-center">
        <Button variant="outline" className="me-3" onClick={handleCancel}>
          {t("Ignore")}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={estimatedDeliveryDate === undefined}
        >
          {t("Choose")}
        </Button>
      </div>
    </div>
  );
}

export default DeliveryDialog;
