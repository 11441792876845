import React from "react";
import "./CalendarHeader.css";
import { useTranslation } from "react-i18next";

function CalendarHeader() {
  const { t } = useTranslation("common");
  return (
    <div className="calendarHeader d-flex">
      <div className="slot firstSlot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Monday")}</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Tuesday")}</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Wednesday")}</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Thursday")}</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Friday")}</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Saturday")}</p>
      </div>
      <div className="slot lastSlot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">{t("Sunday")}</p>
      </div>
    </div>
  );
}

export default CalendarHeader;
