import React from "react";
import IBANinput from "../../Catalogue/PaymentDetails/IBANinput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Step4Paiement = () => {
  const { t } = useTranslation("common");
  const { userRole } = useSelector((state) => state.userReducer);

  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <h4 className="text-softBlue bold ">
        {userRole === "admin" ? 5 : 3}- {t("Payment")}
      </h4>
      <form>
        <h2 className="quickSand bold f16 sBold text-blue  mb-4">
          {t("Make the transfer")}
        </h2>
        <div>
          <div className="w100">
            <p className="f17 sBold text-softBlue">
              {t("Name and address of the account holder")}
            </p>
            <p className="f17 sBold text-customBlack mb-0">
              S.A.R.L AB WELLNESS
            </p>
            <p className="f17 text-brightGrey w70">
              Zone de comblat le chateau 15800 VIC SUR CER
            </p>
          </div>
        </div>
        <div className="w100">
          <p className="f17 sBold text-softBlue">IBAN</p>
          <IBANinput />
        </div>
        {/* <h2 className="quickSand f23 sBold text-blue mt-5 mb-4">
          Méthode de paiement
        </h2> */}
        {/* <PaymentOption
                title="Paiement par virement bancaire"
                paymenMethod={paymenMethod}
                setPaymenMethod={setPaymenMethod}
                methodName="bank"
              >
                <p className="f16 text-brightGrey mb-0 ms-4">
                  Dés la réception de votre paiement sur le compte Poséidon
                  Spa,la livraison sera activée sur l'adresse de votre commande,
                  qui sera validée.
                </p>
              </PaymentOption> */}
      </form>
    </div>
  );
};

export default Step4Paiement;
