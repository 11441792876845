import React, { useState } from "react";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import Button from "../Common/FormElements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../REDUX/actions/rightModal";
import NewProductAdmin from "./NewProductAdmin";
import { getProducts } from "../../REDUX/actions/products";
import { useTranslation } from "react-i18next";

const ProduitsViewHeader = ({
  search,
  isSearching,
  setSearch,
  setIsSearching,
}) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
    if (isSearching) {
      dispatch(
        getProducts(undefined, undefined, undefined, true, i18n.language)
      );
      setIsSearching(false);
    }
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch({ type: "RESET_PRODUCTS" });
    dispatch(getProducts(undefined, search, undefined, true, i18n.language));
    setIsSearching(search?.length > 0);
  };

  return (
    <div className="alignH justify-content-between gap-3">
      <h2 className="text-blue bold f30">{t("Products", { ns: "common" })}</h2>
      <form onSubmit={handleSearch}>
        <TextInput
          withSearchBtn
          searchButtonType="submit"
          value={search}
          callback={handleChange}
          placeholder={t("Search", { ns: "common" })}
          width={500}
          onSearchBtnClick={handleSearch}
          clearSearch={handleClearSearch}
        />
      </form>
      <Button
        Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
        onClick={() => dispatch(openRightModal(<NewProductAdmin />))}
      >
        {t("New product")}
      </Button>
    </div>
  );
};

export default ProduitsViewHeader;
