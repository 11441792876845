export const savPdtsExtractor = (savData) => {
  return savData?.map((item) => {
    return {
      id: item?.attributes?.packings?.data[0]?.attributes?.model?.data?.id,
      name: item?.attributes?.packings?.data[0]?.attributes?.model?.data
        ?.attributes?.name,
      code: item?.attributes?.packings?.data[0]?.attributes?.model?.data?.id,
      serialNumber:
        item?.attributes?.packings?.data[0]?.attributes?.serialNumber,
      refClient: item?.attributes?.refClient,
    };
  });
};

export const savOrdersExtractor = (savData, t) => {
  return savData?.map((item) => {
    return {
      id: item?.id,
      name: item?.attributes?.oldId
        ? `${t("Order")} #${item?.attributes?.number}/${
            item?.attributes?.oldId
          }`
        : `${t("Order")} #${item?.attributes?.number}`,
      code: item?.id,
      deliveryDate: item?.attributes?.realDeliveryDate,
    };
  });
};

export const savResellersExtractor = (savResellersData) => {
  return savResellersData.data
    ?.filter((item) => {
      return (
        item?.attributes?.archived === false ||
        item?.attributes?.archived === null
      );
    })
    ?.map((item) => {
      return {
        id: item?.id,
        name: item?.attributes?.name,
        code: item?.id,
      };
    });
};

export const savPdtsCommandesExtractor = (savData, language) => {
  return savData?.map((item) => {
    return {
      id: item?.model?.id,
      name:
        language === "en"
          ? item?.model?.name_en ?? item?.model?.name
          : item?.model?.name,
      code: item?.model?.id,
      serialNumber: item?.serialNumber,
      refClient: item?.refClient,
    };
  });
};
