import React from "react";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import { useTranslation } from "react-i18next";

function CompanyInfo() {
  const { t } = useTranslation("common");
  return (
    <div className="p-5">
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("Company name")}</p>
          <TextInput placeholder={t("Company name")} />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("Siret")}</p>
          <TextInput placeholder={t("Siret")} />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("E-mail")}</p>
          <TextInput placeholder={t("E-mail")} />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("Phone number")}</p>
          <TextInput placeholder={t("Phone number")} />
        </div>
      </div>
      <div className="mb-3">
        <p className="f16 text-ultraBlack mb-2">{t("Website")}</p>
        <TextInput placeholder={t("Website")} />
      </div>
    </div>
  );
}

export default CompanyInfo;
