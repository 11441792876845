import moment from "moment";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import {
  getApprovQuantity,
  getPackingQuantity,
  getProductQuantity,
  getReplenishmentDate,
} from "../../REDUX/actions/products";
import _ from "lodash";

export const panierExtractor = (panierData) => {
  return {
    id: panierData?.panier?.data?.id,
    total: panierData?.panier?.data?.attributes?.total,
    realRefClient: panierData?.panier?.data?.attributes?.realRefClient,
    productsCount: panierData?.panier?.data?.attributes?.productsCount,
    business_affiliate:
      panierData?.panier?.data?.attributes?.business_affiliate?.data?.id,
    reseller: panierData?.panier?.data?.attributes?.reseller?.data?.id,
    resellerData: panierData?.panier?.data?.attributes?.reseller?.data,
    delivery: {
      id: panierData?.panier?.data?.attributes?.delivery?.data?.id,
      ...panierData?.panier?.data?.attributes?.delivery?.data?.attributes,
      address: {
        id: panierData?.panier?.data?.attributes?.delivery?.data?.attributes
          ?.address?.data?.id,
        ...panierData?.panier?.data?.attributes?.delivery?.data?.attributes
          ?.address?.data?.attributes,
      },
    },
    panierItems: panierData?.panier?.data?.attributes?.panier_items?.data?.map(
      (panierItem) => {
        return {
          id: panierItem?.id,
          quantity: panierItem?.attributes?.quantity,
          refClient: panierItem?.attributes?.refClient,
          productID: panierItem?.attributes?.product?.data?.id,
          name: panierItem?.attributes?.product?.data?.attributes?.name,
          name_en: panierItem?.attributes?.product?.data?.attributes?.name_en,
          description:
            panierItem?.attributes?.product?.data?.attributes?.description,
          productType:
            panierItem?.attributes?.product?.data?.attributes?.productType,
          category: panierItem?.attributes?.product?.data?.attributes?.category,
          publicPriceTTC:
            panierItem?.attributes?.product?.data?.attributes?.publicPriceTTC,
          retailerPriceHT:
            panierItem?.attributes?.product?.data?.attributes?.retailerPriceHT,

          availableQuantity: getProductQuantity(
            panierItem?.attributes?.product?.data?.attributes?.packings?.data
          ),
          getPackingQuantity: (selected) =>
            getPackingQuantity(
              panierItem?.attributes?.product?.data?.attributes?.packings?.data,
              selected
            ),
          getApprovQuantity: (selected) =>
            getApprovQuantity(
              panierItem?.attributes?.product?.data?.attributes?.packings?.data,
              selected
            ),
          getReplenishmentDate: (selected) =>
            getReplenishmentDate(
              panierItem?.attributes?.product?.data?.attributes?.packings?.data,
              selected
            ),
          photo:
            panierItem?.attributes?.product?.data?.attributes?.attachments?.data?.reduce(
              (acc, attach) => {
                if (acc?.length > 0) return acc;
                if (
                  attach?.attributes?.file?.data?.attributes?.mime?.includes(
                    "image"
                  )
                ) {
                  return `${IMAGE_END_POINT}${attach?.attributes?.file?.data?.attributes?.url}`;
                }
              },
              ""
            ),
          packings: panierItem?.attributes?.packings?.data?.filter(
            (item) => !item?.attributes?.sold
            // moment(
            //   item?.attributes?.approvisionnement?.data?.attributes
            //     ?.arrivalDate,
            // ).diff(moment(), "day") < 0 && !item?.attributes?.sold,
          ),
          productPackings:
            panierItem?.attributes?.product?.data?.attributes?.packings?.data?.filter(
              (item) => !item?.attributes?.sold
              // moment(
              //   item?.attributes?.approvisionnement?.data?.attributes
              //     ?.arrivalDate,
              // ).diff(moment(), "day") < 0 && !item?.attributes?.sold,
            ),
        };
      }
    ),
    panierPackings: _.uniqBy(
      _.flatten(
        panierData?.panier?.data?.attributes?.panier_items?.data?.map(
          (panierItem) =>
            panierItem?.attributes?.product?.data?.attributes?.packings?.data?.filter(
              (item) => !item?.attributes?.sold
              // moment(
              //   item?.attributes?.approvisionnement?.data?.attributes
              //     ?.arrivalDate,
              // ).diff(moment(), "day") < 0 &&
            )
        )
      ),
      function (item) {
        return item?.id;
      }
    ),
  };
};
