import React from "react";
import Button from "../../Common/FormElements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import NewIntermediary from "./NewIntermediary";
import { useTranslation } from "react-i18next";

function ViewHeader() {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleOpenModal = () => {
    dispatch(openRightModal(<NewIntermediary resellerId={id} />));
  };
  return (
    <div className="alignH justify-content-between gap-3">
      <div className="alignH">
        <Button onClick={handleGoBack}>{t("Back")}</Button>
        <h2 className="text-blue bold f30 mb-0 ms-4">{t("Profiles")}</h2>
      </div>
      <div className="alignH gap-3 ">
        <Button
          Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
          onClick={handleOpenModal}
        >
          {t("Add a profile")}
        </Button>
      </div>
    </div>
  );
}

export default ViewHeader;
