import React, { useRef } from "react";
import TextInput from "../../FormElements/TextInput/TextInput";
import CalendarInput from "../../FormElements/CalendarInput/CalendarInput";
import userPhoto from "../../../../Images/Images/avatar.png";
import "./GeneralInfo.css";
import moment from "moment";
import PassswordInput from "../ResetPassword/PassswordInput";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";
import { useTranslation } from "react-i18next";

const MemberInputsForm = ({
  formData,
  handleChange = () => {},
  handleImageChange = () => {},
  handleCalenderInput = () => {},
  userImage = null,
  defaultImgUser = null,
}) => {
  const { t } = useTranslation("common");
  const fileInputRef = useRef(null);
  const handleCameraIconClick = () => {
    fileInputRef.current.click();
  };
  const userWithoutImg = defaultImgUser
    ? ` ${IMAGE_END_POINT}${defaultImgUser}`
    : userPhoto;

  return (
    <div className="generalInfo d-flex flex-column justify-content-between px-5">
      <div className="d-flex pt-4 pb-3">
        <div className="memberImageContainer me-4 d-flex justify-content-center align-self-lg-start position-relative pointer">
          <label htmlFor="imageInput" className="imageInput">
            <img
              src={userImage ? URL.createObjectURL(userImage) : userWithoutImg}
              alt="member"
              className="br50 ob-cover w100 h100 pointer"
              onClick={handleCameraIconClick}
            />
          </label>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            hidden
          />
          <div className="iconContainer d-flex align-items-center justify-content-center bg-softBlue br50">
            <i
              className="bi bi-camera text-white f18"
              onClick={handleCameraIconClick}
            />
          </div>
        </div>
        <div className="d-flex flex-column w100">
          <div className="d-flex w100">
            <div className="me-3 w50">
              <div className="mb-3">
                <p className="f16 text-ultraBlack mb-2">{t("Last name")}</p>
                <TextInput
                  placeholder={t("Last name")}
                  name="lastName"
                  callback={handleChange}
                  value={formData?.lastName}
                />
              </div>
              <div className="mb-3 ">
                <p className="f16 text-ultraBlack mb-2">{t("Date of birth")}</p>
                <CalendarInput
                  className="birthdayInput b-2-softGrey w100"
                  placeholder={t("dd/mm/yyyy")}
                  name="birthDate"
                  value={
                    formData?.birthdate
                      ? moment(formData?.birthdate).utc(true).toDate()
                      : null
                  }
                  callback={(event) => handleCalenderInput(event, "birthdate")}
                  withMinDate={false}
                />
              </div>
            </div>
            <div className="w50">
              <div className="mb-3">
                <p className="f16 text-ultraBlack mb-2">{t("First name")}</p>
                <TextInput
                  placeholder={t("First name")}
                  callback={handleChange}
                  name="firstName"
                  value={formData?.firstName}
                />
              </div>
              <div className="mb-3">
                <p className="f16 text-ultraBlack mb-2">{t("Phone number")}</p>
                <TextInput
                  placeholder="Ex. +33 (0)1 23 45 67 89"
                  name="phoneNumber"
                  callback={handleChange}
                  value={formData?.phoneNumber}
                />
              </div>
            </div>
          </div>
          <div className="w100 d-flex">
            <div className="w50 mb-3 me-3">
              <p className="f16 text-ultraBlack mb-2">{t("E-mail")}</p>
              <TextInput
                placeholder={t("E-mail")}
                name="email"
                callback={handleChange}
                value={formData?.email}
              />
            </div>
            <div className="w50 mb-3">
              <p className="f16 text-ultraBlack mb-2">{t("Position")}</p>
              <TextInput
                placeholder={t("Position")}
                name="occupation"
                callback={handleChange}
                value={formData?.occupation}
              />
            </div>
          </div>
        </div>
      </div>
      <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
      <div className="w100 mb-5 mt-3">
        <PassswordInput
          placeholder=""
          callback={handleChange}
          name="password"
          value={formData?.password}
          text={t("Password")}
        />
      </div>
    </div>
  );
};

export default MemberInputsForm;
