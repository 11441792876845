import store from "../store";
import qs from "qs";
import client from "./Client";
import {
  CREATE_DELIVERY,
  CREATE_PANIER,
  CREATE_PANIER_ITEM,
  DELETE_PANIER_ITEM,
  UPDATE_DELIVERY,
  UPDATE_PANIER,
  UPDATE_PANIER_ITEM,
} from "../Graphql/Mutations/panier";
import { GET_PANIER } from "../Graphql/Queries/panier";
import { panierExtractor } from "../../Utils/DataExtractors/panierExtractor";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { isDefined } from "../../Utils";
import { toast } from "react-toastify";

export const getPanier =
  (payload, callBack = () => {}) =>
  async (dispatch, getState) => {
    const { user } = getState().userReducer;
    try {
      if (payload.softLoading === true) {
        dispatch({ type: "PANIER_SOFT_LOADING" });
      } else {
        dispatch({ type: "PANIER_LOADING" });
      }
      if (payload.id) {
        const { data: fetchedPanier } = await client.query({
          query: GET_PANIER,
          variables: {
            id: payload.id,
          },
        });

        let panierContent = panierExtractor(fetchedPanier);
        let total = 0;
        let productsCount = 0;
        panierContent?.panierItems?.forEach((element) => {
          total += element.retailerPriceHT * element.quantity;
          productsCount += element.quantity;
        });
        const { data: updatedPanier } = await client.mutate({
          mutation: UPDATE_PANIER,
          variables: {
            id: panierContent.id,
            total,
            productsCount,
            realRefClient: payload.realRefClient,
            reseller:
              panierContent?.reseller ?? user?.attributes?.userRole === 0
                ? user?.attributes?.profil?.data?.attributes?.reseller?.data?.id
                : null,
          },
        });
        let modifiedUpdatedPanier = { panier: updatedPanier.updatePanier };

        panierContent = panierExtractor(modifiedUpdatedPanier);
        dispatch({ type: "PANIER_FETCHED", payload: panierContent });
      } else {
        return;
      }
      callBack();
    } catch (error) {
      console.log(error);
      dispatch({ type: "PANIER_ERROR", payload: error });
    }
  };

export const createPanier = (payload) => async (dispatch, getState) => {
  const { user } = getState().userReducer;
  dispatch({ type: "PANIER_LOADING" });
  try {
    const { data: panierData } = await client.mutate({
      mutation: CREATE_PANIER,
      variables: { userID: payload.userID },
    });
    await client.mutate({
      mutation: CREATE_PANIER_ITEM,
      variables: {
        product: payload.productID,
        packings: isDefined(payload?.packing) ? [payload?.packing] : null,
        panier: panierData.createPanier.data.id,
        quantity: 1,
        reseller:
          user?.attributes?.userRole === 0
            ? user?.attributes?.profil?.data?.attributes?.reseller?.data?.id
            : null,
      },
    });

    const { data: createdDelivery } = await client.mutate({
      mutation: CREATE_DELIVERY,
      variables: {
        panier: panierData.createPanier.data.id,
      },
    });
    await API.post("api/addresses", {
      data: {
        delivery: createdDelivery.createDelivery.data.id,
      },
    });
    await dispatch({ type: "PANIER_CREATED" });
    dispatch(getPanier({ id: panierData.createPanier.data.id }));
  } catch (error) {
    console.log(error);
    dispatch({ type: "PANIER_ERROR", payload: error });
  }
  return { type: "CREATE_PANIER" };
};

export const updatePanier = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "PANIER_LOADING" });
    const currentPanier = store.getState().panierReducer.panier;
    const productItemExists = currentPanier?.panierItems.find(
      (panierItem) => +panierItem.productID === +payload.productID
    );
    await client.mutate({
      mutation: CREATE_PANIER_ITEM,
      variables: {
        product: payload.productID,
        packings: isDefined(payload?.packing) ? [payload?.packing] : null,
        panier: currentPanier.id,
        quantity: 1,
      },
    });
    dispatch({ type: "PANIER_UPDATED" });
    dispatch(getPanier({ id: currentPanier.id }));
  } catch (error) {
    console.log(error);
    dispatch({ type: "PANIER_ERROR", payload: error });
  }
};

export const updatePanierItemPacking = (payload) => async (dispatch) => {
  dispatch({ type: "PANIER_LOADING" });
  try {
    const currentPanier = store.getState().panierReducer.panier;
    await client.mutate({
      mutation: UPDATE_PANIER_ITEM,
      variables: {
        id: payload.id,
        refClient: payload?.refClient,
      },
    });

    dispatch({ type: "PANIER_UPDATED" });
    dispatch(getPanier({ id: currentPanier.id }));
  } catch (error) {
    console.log(error);
    dispatch({ type: "PANIER_ERROR", payload: error });
  }
};

export const panierRemoveProduct = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "PANIER_SOFT_LOADING" });
    await client.mutate({
      mutation: DELETE_PANIER_ITEM,
      variables: {
        id: payload.panierItemID,
      },
    });
    dispatch({ type: "PANIER_UPDATED" });
    dispatch(getPanier({ id: payload.panierID, softLoading: true }));
  } catch (error) {
    console.log(error);
    dispatch({ type: "PANIER_ERROR", payload: error });
  }
};

export const panierAddProduct =
  (payload, callback = () => {}, t) =>
  async (dispatch) => {
    try {
      // console.log({ panierAddPdt: payload });
      // return;
      const panier = store.getState().panierReducer.panier;
      const user = store.getState().userReducer.user;
      if (panier) {
        await dispatch(
          updatePanier({
            productID: payload.productID,
            packing: payload?.packing,
          })
        );
      } else {
        await dispatch(
          createPanier({
            userID: user.id,
            productID: payload.productID,
            packing: payload?.packing,
          })
        );
      }
      if (payload.productName?.length > 0) {
        toast.success(payload?.productName + ` ${t("is added to the cart")}`);
      }
      callback();
      return { type: "ADD_PANIER_PRODUCT" };
    } catch (error) {
      callback();
    }
  };

export const incrementQuantity = (payload) => async (dispatch) => {
  dispatch({ type: "PANIER_SOFT_LOADING" });
  try {
    await client.mutate({
      mutation: UPDATE_PANIER_ITEM,
      variables: {
        id: payload.panierItemID,
        quantity: payload.oldQuantity + 1,
      },
    });
    dispatch({ type: "PANIER_UPDATED" });
    dispatch(getPanier({ id: payload.panierID, softLoading: true }));
  } catch (error) {
    console.log(error);
    dispatch({ type: "PANIER_ERROR", payload: error });
  }
};
export const decrementQuantity = (payload) => async (dispatch) => {
  if (payload.oldQuantity > 1) {
    dispatch({ type: "PANIER_SOFT_LOADING" });
    try {
      await client.mutate({
        mutation: UPDATE_PANIER_ITEM,
        variables: {
          id: payload.panierItemID,
          quantity: payload.oldQuantity - 1,
        },
      });
      dispatch({ type: "PANIER_UPDATED" });
      dispatch(getPanier({ id: payload.panierID, softLoading: true }));
    } catch (error) {
      console.log(error);
      dispatch({ type: "PANIER_ERROR", payload: error });
    }
  } else {
    try {
      dispatch(
        panierRemoveProduct({
          panierItemID: payload.panierItemID,
          panierID: payload.panierID,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch({ type: "PANIER_ERROR", payload: error });
    }
  }
};

export const updateDelivery =
  (payload, hasLoading = true) =>
  async (dispatch) => {
    if (hasLoading) {
      dispatch({ type: "PANIER_LOADING" });
    }
    try {
      // console.log("first", { payload });
      await client.mutate({
        mutation: UPDATE_DELIVERY,
        variables: {
          id: payload.deliveryID,
          date: payload.date,
          deliveryAddress: payload.deliveryAddress,
          billingAddress: payload.billingAddress,
          deliveryMessage: payload.deliveryMessage,
          mode: payload.mode,
          price: payload.price,
          addressId: payload.addressId,
        },
      });
      dispatch({ type: "PANIER_UPDATED" });

      if (hasLoading) {
        dispatch(getPanier({ id: payload.panierID }));
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "PANIER_ERROR", payload: error });
    }
  };

export const createDeliveryPanier = (payload) => async (dispatch) => {
  const { panierId } = payload;
  dispatch({ type: "PANIER_LOADING" });
  try {
    const { data: createdDelivery } = await client.mutate({
      mutation: CREATE_DELIVERY,
      variables: {
        panier: panierId,
      },
    });
    await API.post("api/addresses", {
      data: {
        delivery: createdDelivery.createDelivery.data.id,
      },
    });
    dispatch({ type: "PANIER_UPDATED" });
    dispatch(getPanier({ id: panierId }));
  } catch (err) {
    dispatch({ type: "PANIER_ERROR", payload: err });
  }
};

export const toggleOrderWithoutPay = (state) => (dispatch) => {
  const canOrderWithoutPay = store.getState().panierReducer.canOrderWithoutPay;
  if (state === true) {
    dispatch({ type: "TOGGLE_ORDER_WO_PAY", payload: true });
  } else {
    dispatch({ type: "TOGGLE_ORDER_WO_PAY", payload: !canOrderWithoutPay });
  }
};

export const addCartAffiliate = (id) => async (dispatch) => {
  if (!id) return;
  try {
    const query = qs.stringify({
      populate: [
        "city",
        "country",
        "user",
        "comissions.range",
        "organizations",
        "email",
      ],
    });

    const {
      data: { data: affiliateData },
    } = await API.get(`${API_END_POINT}api/business-affiliates/${id}?${query}`);

    dispatch({ type: "CART_AFFILIATE", payload: affiliateData });
  } catch (error) {
    console.log(error);
  }
};

export const addResellerToPanier = (id, value) => async (dispatch) => {
  try {
    if (!isDefined(id)) return;
    await API.put(`${API_END_POINT}api/paniers/${id}`, {
      data: {
        reseller: value ?? null,
      },
    });

    dispatch(getPanier({ id }));
  } catch (error) {
    console.log(error);
  }
};

export const addBAToPanier = (id, value) => async (dispatch) => {
  try {
    if (!isDefined(id)) return;
    await API.put(`${API_END_POINT}api/paniers/${id}`, {
      data: {
        business_affiliate: value ?? null,
      },
    });

    dispatch(getPanier({ id }));
  } catch (error) {
    console.log(error);
  }
};

export const updateAddressName = (id, value) => async () => {
  try {
    if (!isDefined(id)) return;
    await API.put(`${API_END_POINT}api/addresses/${id}`, {
      data: {
        name: value ?? "",
      },
    });
  } catch (error) {
    console.log(error);
  }
};
