import React from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import ViewHeader from "../../Common/ViewHeader/ViewHeader";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import NewCatalogueList from "../NewCatalogueList";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Step1Catalogue = ({
  searchInput,
  setSearchInput,
  setActiveTab,
  activeTab,
  productsactiveTab,
  setproductsActiveTab,
}) => {
  const { t } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const clearSearch = () => {
    setSearchInput("");
  };
  return (
    <div>
      <form onSubmit={handleSearch}>
        <ViewHeader title={t("Catalogue")}>
          <TextInput
            withSearchBtn
            placeholder={t("Search")}
            className="w50"
            value={searchInput}
            callback={handleSearchChange}
            onSearchBtnClick={handleSearch}
            searchButtonType="submit"
            clearSearch={clearSearch}
          />
        </ViewHeader>
      </form>
      <div>
        <TabsNavbar
          tabs={
            user?.attributes?.userRole === 1
              ? [
                  "Products",
                  "Accessories",
                  "Spare Part",
                  "Marketing Products",
                  "Services",
                ]
              : ["Products", "Accessories", "Spare Part", "Marketing Products"]
          }
          activeTab={activeTab}
          setActiveTab={(value) => {
            // if (activeTab !== value) {
            //   dispatch({ type: "RESET_PRODUCTS" });
            // }
            setActiveTab(value);
          }}
          center
          withBottomBorder
        />
      </div>
      {activeTab === "Products" && (
        <div>
          <TabsNavbar
            tabs={["SPA", "SWIM SPA", "SAUNA"]}
            activeTab={productsactiveTab}
            setActiveTab={(value) => {
              // if (activeTab !== value) {
              //   dispatch({ type: "RESET_PRODUCTS" });
              // }
              setproductsActiveTab(value);
            }}
            center
            withBottomBorder
          />
        </div>
      )}
      <div className="mt-5 d-flex">
        <div className="d-flex flex-wrap gap-3 w100">
          <NewCatalogueList
            activeTab={activeTab}
            searchInput={searchInput}
            productsactiveTab={productsactiveTab}
          />
        </div>
      </div>
    </div>
  );
};

export default Step1Catalogue;
