import React from "react";
import Select from "../../Common/FormElements/Select/Select";
import ListDeleteIcon from "../../Common/DeleteIcon/ListDeleteIcon";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { useTranslation } from "react-i18next";

function CommissionItem({
  value,
  rangeValue,
  rangeName,
  commission,
  options,
  onChange,
  removeField,
}) {
  const { t } = useTranslation("common");
  return (
    <div className="alignH py-3 px-3 b-1-bottom-softGrey">
      <div className="w60">
        <Select
          name={rangeName}
          value={rangeValue}
          options={options}
          width="w95"
          placeholder={t("Select")}
          valueCustomStyle="12px"
          callback={onChange}
        />
      </div>
      <TextInput
        name="value"
        value={value}
        percentage
        width="25%"
        inputClassName={"f12"}
        placeholderStyle={"f12"}
        callback={onChange}
      />
      <ListDeleteIcon callback={removeField} />
    </div>
  );
}

export default CommissionItem;
