import React from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";

const LineChartHeader = ({
  colors,
  years,
  dateActiveTab,
  setDateActiveTab,
}) => {
  return (
    <div className="alignH justify-content-between px-5 py-2">
      <div className="alignH gap-4">
        {years?.map((year, index) => (
          <div className="alignH gap-2">
            <span
              className={`br50 bg-${colors[index]}`}
              style={{ width: "11px", height: "11px" }}
            ></span>
            <span className="br50 text-lightBlack sBold f12">{year}</span>
          </div>
        ))}
      </div>

      {/* Filters month/year */}
      <TabsNavbar
        tabs={["Year"]}
        activeTab={dateActiveTab}
        setActiveTab={setDateActiveTab}
        className="justify-content-between py-2  gap-3"
        tabClassName="f14 normal"
        bottom="-7px"
      />
    </div>
  );
};

export default LineChartHeader;
