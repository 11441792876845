import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { useEffect, useState } from "react";
import TextArea from "../../Common/FormElements/TextArea/TextArea";

const TranslateProduct = ({ handleFormsChange, initialValues }) => {
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState(initialValues ?? {});

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    handleFormsChange("product", formData);
  }, [formData]);

  return (
    <div className="d-flex flex-column flex-stretch">
      <p className="mb-3 pb-3 f14 font-medium border-bottom bold text-lightBlack">
        {t("English")}
      </p>
      <Row className="gx-5 flex-grow-1">
        <Col lg={12} className="mb-3 d-flex flex-column gap-2">
          <div>
            <span className="text-softBlack normalf16">
              {t("Name", { ns: "common" })}
            </span>
          </div>
          <TextInput
            className={"w-50"}
            type="text"
            name="name_en"
            value={formData.name_en}
            placeholder={t("Name")}
            callback={handleChange}
          />
        </Col>
        <Col lg={12} className="d-flex flex-column gap-2">
          <span className="text-softBlack normalf16">
            {t("Description", { ns: "common" })}
          </span>
          <TextArea
            name="description_en"
            value={formData.description_en}
            placeholder={t("description")}
            className={"w-50 b-2-softGrey h100"}
            rows={10}
            callback={handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TranslateProduct;
