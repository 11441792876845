export const ATTRIBUTES_PANIER = `data {
        id
        attributes {
          total
          productsCount
          realRefClient
          reseller {
            data {
              id
              attributes {
                name
                phoneNumber
                delivery_addresses {
                  data {
                    id
                    attributes {
                      street
                      name
                      zip
                      city {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                      country {
                        data {
                          id
                          attributes {
                            name
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          business_affiliate {
            data {
              id
            }
          }
          delivery{
            data{
              id
              attributes{
                date
                deliveryAddress
                billingAddress
                deliveryMessage
                mode
                price
                address {
                  data{
                    id
                    attributes
                    {
                      street
                      name
                      city {
                        data {
                          id
                          attributes {
                            name
                            code
                          }
                        }
                      }
                      country {
                        data {
                          id
                          attributes {
                            name
                            code
                          }
                        }
                      }
                      zip
                    }
                  }
                }
              }
            }
          }
          panier_items {
            data {
              id
              attributes {
                quantity
                refClient
                packings {
                  data {
                    id
                    attributes {
                      reference
                      cuve
                      panel
                      serialNumber
                      sold
                      buyingPrice
                      buyingCost
                      deliveredCost
                      transport
                      approvisionnement {
                        data {
                          id
                          attributes {
                            arrivalDate
                            estimatedDate
                          }
                        }
                      }
                      model {
                        data {
                          id
                          attributes {
                            productType
                            retailerPriceHT
                            kangourou
                            accessoryWeight
                            gamme {
                              data {
                                id
                              }
                            }
                            product_size {
                              data {
                                id
                                attributes {
                                  name
                                  ordre
                                }
                              }
                            }
                            delivery_types {
                              data {
                                id
                                attributes {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                product {
                  data {
                    id
                    attributes {
                      name
                      name_en
                      category {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                      description
                      productType
                      publicPriceTTC
                      retailerPriceHT
                      packings(pagination: { pageSize: 1000000 }) {
                        data {
                          id
                          attributes {
                            reference
                            cuve
                            panel
                            serialNumber
                            sold
                            buyingPrice
                            buyingCost
                            deliveredCost
                            transport
                            model {
                              data {
                                id
                              }
                            }
                            approvisionnement {
                              data {
                                id
                                attributes {
                                  arrivalDate
                                  estimatedDate
                                }
                              }
                            }
                          }
                        }
                      }
                      attachments {
                        data {
                          attributes {
                            file {
                              data {
                                attributes {
                                  url
                                  mime
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
`;
