import React, { useEffect, useState } from "react";
import ListProductsTitles from "./ListProductsTitles";
import ProductCardAdmin from "./ProductCardAdmin";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../REDUX/actions/products";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import useOnScreen from "../../hooks/useOnScreen";
import { ProgressSpinner } from "primereact/progressspinner";
import { useTranslation } from "react-i18next";

const ProduitsViewContent = ({ search, isSearching }) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const { products, productsLoading: loading } = useSelector(
    (state) => state.productsReducer
  );
  const { measureRef, isIntersecting } = useOnScreen();

  useEffect(() => {
    if (isIntersecting) {
      if (isSearching) {
        dispatch(
          getProducts(undefined, search, undefined, false, i18n.language)
        );
      } else {
        dispatch(
          getProducts(undefined, undefined, undefined, false, i18n.language)
        );
      }
    }
  }, [dispatch, isIntersecting, i18n.language]);

  useEffect(() => {
    dispatch({ type: "RESET_PRODUCTS" });
  }, [dispatch]);

  return (
    <div className="flex-1 d-flex flex-column">
      <ListProductsTitles />
      <LoaderWrapper loading={loading && products?.length < 10}>
        <div className="flex-1">
          {products?.map((product) => {
            return (
              <div key={product?.id}>
                <ProductCardAdmin
                  key={product?.id}
                  id={product?.id}
                  {...product?.attributes}
                  name={
                    i18n.language === "en"
                      ? product?.attributes?.name_en ??
                        product?.attributes?.name
                      : product?.attributes?.name
                  }
                  // TODO - Add missing status if exists
                  status={
                    product?.attributes?.availableQuantity > 0
                      ? t("In stock")
                      : t("Out of stock")
                  }
                />
                <hr
                  style={{
                    borderColor: "#abb8b2",
                    margin: 0,
                  }}
                  width={"100%"}
                />
              </div>
            );
          })}
        </div>
      </LoaderWrapper>
      {!loading && (
        <div style={{ width: "100%", height: "1px" }} ref={measureRef} />
      )}
      {loading && products?.length >= 10 && (
        <ProgressSpinner
          style={{
            display: "flex",
            alignItems: "center",
            width: "100px",
            height: "100px",
            marginTop: "30px",
          }}
          strokeWidth={2}
          className="loadingViewSpinner"
        />
      )}
    </div>
  );
};

export default ProduitsViewContent;
