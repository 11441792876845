import React from "react";
import { useTranslation } from "react-i18next";

const CalendarFooter = () => {
  const { t } = useTranslation("common");
  const calendarFooterItems = [
    { id: 1, label: t("Events"), color: "bg-wildStrawberry" },
    { id: 2, label: t("Deliveries"), color: "bg-aquaGreen" },
    { id: 3, label: t("SAV Deliveries"), color: "bg-yellowOrange" },
    { id: 4, label: t("Product Deliveries"), color: "bg-yellowGreen" },
    { id: 5, label: t("Showroom"), color: "bg-jasminePurple" },
    { id: 6, label: t("Prospects"), color: "bg-blueTurquoise" },
  ];
  return (
    <div>
      <hr style={{ borderColor: "#abb8b2" }} className="mt-0" width={"100%"} />
      <div className="alignH flex-wrap gap-3 ">
        {calendarFooterItems?.map(({ id, label, color }) => (
          <div className="alignH flex-wrap mx-2" key={id}>
            <div className="alignH gap-3">
              <div
                className={`rounded-circle ${color}`}
                style={{ width: "14px", height: "14px" }}
              ></div>
              <span className="text-brightGrey f16 normal">{label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarFooter;
