import React from "react";
import SyntheseRecettes from "./SyntheseRecettes";
import SyntheseDepenses from "./SyntheseDepenses";
import { useSelector } from "react-redux";

const Synteses = () => {
  const { stats } = useSelector((state) => state.statsReducer);

  // console.log({ stats });
  return (
    <div className="alignH w100 gap-4 my-2 align-items-stretch">
      <SyntheseRecettes stats={stats?.orderStats ?? {}} />
      <SyntheseDepenses stats={stats?.expenseStats ?? {}} />
    </div>
  );
};

export default Synteses;
