import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Common/FormElements/Button/Button";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { caractProductForm } from "./data";
import { Col, Row } from "react-bootstrap";
import ExtraFields from "./ExtraFieldsGenerator";
import {
  closeCenteredModal,
  openCenteredModal,
} from "../../../REDUX/actions/centeredModal";
import DeleteButton from "../../Common/ListHeaderButtons/DeleteButton";
import DeleteExtraFieldModal from "./DeleteExtraFieldModal";
import { useTranslation } from "react-i18next";

const CaractProduct = ({ handleFormsChange, initialValues, productType }) => {
  const { t } = useTranslation("common");
  const hasADefaultInputs =
    productType?.code !== null &&
    productType?.code !== undefined &&
    productType?.code === 1;

  const [formData, setFormData] = useState(initialValues ?? {});
  const [extraFields, setExtraFields] = useState(
    initialValues?.extrafields ?? []
  );

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleExtraFieldsChange = (e, index) => {
    const { value } = e.target;
    setExtraFields((prevExtraFields) => {
      const updatedExtraFields = [...prevExtraFields];
      updatedExtraFields[index] = {
        ...updatedExtraFields[index],
        valueName: value,
      };
      return updatedExtraFields;
    });
  };
  const handleDeleteInput = (targetName) => {
    const updatedInputs = extraFields.filter(
      ({ inputName }) => inputName !== targetName
    );
    setExtraFields(updatedInputs);
    dispatch(closeCenteredModal());
  };
  const openModalFieldsGenrator = () => {
    dispatch(
      openCenteredModal(
        <ExtraFields
          addAnExtraField={(newExtraField) => {
            setExtraFields([...extraFields, newExtraField]);
          }}
        />
      )
    );
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      extrafields: extraFields,
    }));
  }, [extraFields]);

  useEffect(() => {
    handleFormsChange("caracteristiques", formData);
  }, [formData]);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <Row>
          {hasADefaultInputs &&
            caractProductForm.map((el) => {
              return (
                <Col
                  lg={3}
                  key={el.name}
                  className="mb-3 d-flex flex-column gap-2 px-2 py-2"
                >
                  <span className="text-softBlack normalf16 text-capitalize">
                    {t(`${el.label}`, { ns: "common" })}
                  </span>
                  <TextInput
                    className={"w100"}
                    type={el.type}
                    name={el.name}
                    value={formData[el.name]}
                    placeholder={t(`${el.placeholder ?? ""}`, { ns: "common" })}
                    euro={el.euro}
                    callback={handleChange}
                  />
                </Col>
              );
            })}
          {extraFields?.map((extraField, index) => (
            <Col lg={3} key={extraField?.inputname} className="mb-3">
              <div className="extra-field px-2 py-2 position-relative d-flex flex-column gap-2">
                <span className="text-softBlack normal f16 text-capitalize">
                  {extraField?.inputName}
                </span>
                <div className="position-absolute top-0 right-0 listHeaderButtons delete-extra-field py-2">
                  <i
                    className="bi bi-trash px-3 text-lameRed pointer"
                    onClick={() =>
                      dispatch(
                        openCenteredModal(
                          <DeleteExtraFieldModal
                            message={`Veuillez supprimer le champ ${extraField?.inputName} ?`}
                            handleDelete={() =>
                              handleDeleteInput(extraField?.inputName)
                            }
                          />
                        )
                      )
                    }
                  />
                </div>
                <TextInput
                  className={"w100"}
                  type="string"
                  name={`${extraField?.inputName}`}
                  placeholder={extraField?.inputName}
                  value={extraField?.valueName}
                  callback={(e) => handleExtraFieldsChange(e, index)}
                ></TextInput>
              </div>
            </Col>
          ))}
          <Col
            lg={3}
            className="d-flex flex-column align-items-center justify-content-center gap-2"
          >
            <Button className="mt-3" onClick={openModalFieldsGenrator}>
              {t("Add new field", { ns: "common" })}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CaractProduct;
