import React from "react";
import NewSAVInfos from "./NewSAVInfos";
import NewSAVCommande from "./NewSAVCommande";
import NewSAVAttachments from "./NewSAVAttachments/NewSAVAttachments";
import NewSAVComment from "./NewSAVComment";
import NewSAVSummary from "./NewSAVSummary";

function NewTicketSAVContent({
  activeTab,
  formData,
  setFormData,
  attachments,
  setAttachments,
}) {
  switch (activeTab) {
    case "General information":
      return <NewSAVInfos formData={formData} setFormData={setFormData} />;
    case "AS Order":
      return <NewSAVCommande />;
    case "Attachments":
      return (
        <NewSAVAttachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      );
    case "Comment":
      return <NewSAVComment />;
    case "Summary":
      return <NewSAVSummary />;
    default:
      <NewSAVInfos />;
  }
}

export default NewTicketSAVContent;
