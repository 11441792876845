import React from "react";
import GeneralInfo from "../../../Common/Forms/GeneralInfo/GeneralInfo";
import AddressInfo from "../../../Common/Forms/AddressInfo/AddressInfo";
import CompanyInfo from "../../../Common/Forms/CompanyInfo/CompanyInfo";
import NewGuideQuestions from "../../../Faq/NewGuideQuestions";
import NewGuideFichiers from "../../../Faq/NewGuideFichiers";
import { useTranslation } from "react-i18next";

function TabContent({
  activeTab,
  formData,
  setFormData = () => {},
  attachments,
  setAttachments = () => {},
  update,
  language,
  setLanguage,
}) {
  switch (activeTab) {
    case "General information":
      return <GeneralInfo />;
    case "Address":
      return <AddressInfo />;
    case "Company":
      return <CompanyInfo />;
    case "Question":
      return (
        <NewGuideQuestions
          formData={formData}
          setFormData={setFormData}
          update={update}
          language={language}
          setLanguage={setLanguage}
        />
      );
    case "Files":
      return (
        <NewGuideFichiers
          attachments={attachments}
          setAttachments={setAttachments}
        />
      );
    default:
      <GeneralInfo />;
  }
}

export default TabContent;
