import React from "react";

import CustomCheckBox from "../../Common/CustomCheckBox/CustomCheckBox";
import DepenseItem from "./DepenseItem";
import { useSelector } from "react-redux";
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";

const DepensesList = ({ checkedItems, setCheckedItems }) => {
  const { t } = useTranslation("common");
  const { expenses, loading } = useSelector((state) => state.expensesReducer);

  const handleCheckAll = () => {
    if (checkedItems?.length === expenses?.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(
        expenses?.map((item) => ({
          id: item?.id,
          comments: item?.attributes?.comments?.data?.map((item) => item?.id),
          attachmentId: item?.attributes?.attachment?.data?.id,
          fileName:
            item?.attributes?.attachment?.data?.attributes?.file?.data
              ?.attributes?.name,
          fileUrl:
            item?.attributes?.attachment?.data?.attributes?.file?.data
              ?.attributes?.url,
          fileId:
            item?.attributes?.attachment?.data?.attributes?.file?.data?.id,
        }))
      );
    }
  };

  const factureColStyle = `f14 text-brightGrey mb-0 col-1 sBold`;

  return (
    <div className="d-flex flex-column h-100">
      <div className="listHeader alignH bg-dimGrey justify-content-between p-3 mt-4">
        <CustomCheckBox
          checked={
            expenses?.length > 0 && checkedItems?.length === expenses?.length
          }
          callback={handleCheckAll}
          className="me-3"
        />
        <p className={factureColStyle}>{t("Date")}</p>
        <p className={factureColStyle}>{t("Title")}</p>
        <p className={factureColStyle}>{t("Supplier")}</p>
        <p className={factureColStyle}>{t("Type")}</p>
        <p className={factureColStyle}>{t("Amount incl. tax")}</p>
        <p className={factureColStyle}>{t("VAT")}</p>
        <p className={factureColStyle}>{t("Amount excl. tax")}</p>
        <p className={factureColStyle}>{t("Payment")} 1</p>
        <p className={factureColStyle}>{t("Payment")} 2</p>
        <p className={factureColStyle}>{t("Payment")} 3</p>
        <p className={factureColStyle}></p>
      </div>
      <div className="w100 flex-1">
        <LoaderWrapper loading={loading}>
          {expenses?.map((depense) => (
            <DepenseItem
              key={depense?.id}
              id={depense?.id}
              {...depense?.attributes}
              checked={checkedItems?.find((item) => +item?.id === +depense?.id)}
              // checkAll={checkAll}
              comments={depense?.attributes?.comments?.data?.map(
                (item) => item?.id
              )}
              attachmentId={depense?.attributes?.attachment?.data?.id}
              fileId={
                depense?.attributes?.attachment?.data?.attributes?.file?.data
                  ?.id
              }
              fileUrl={
                depense?.attributes?.attachment?.data?.attributes?.file?.data
                  ?.attributes?.url
              }
              fileName={
                depense?.attributes?.attachment?.data?.attributes?.file?.data
                  ?.attributes?.name
              }
              setCheckedItems={setCheckedItems}
            />
          ))}
        </LoaderWrapper>
      </div>
    </div>
  );
};

export default DepensesList;
