import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../../REDUX/actions/catalogue";
import { useTranslation } from "react-i18next";

const Step4Particulier = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <div className="alignH gap-5">
        <h4 className="mt-4 text-softBlue bold">
          1- {t("Client details (individual)")}
        </h4>
      </div>

      <div className="mt-3 alignH gap-2">
        <div>
          {t("Full name")} / {t("company")} :{" "}
        </div>
        <input
          className="refClientItem p-1"
          value={cart.particulierName}
          onChange={(e) =>
            dispatch(
              updateCart(cart.id, {
                particulierName: e.target.value,
                facture: null,
                bon_de_commande: null,
              })
            )
          }
        />
      </div>
      <div className="mt-3 alignH gap-2">
        <div>{t("Phone number")} : </div>
        <input
          className="refClientItem p-1"
          value={cart.particulierPhone}
          onChange={(e) =>
            dispatch(
              updateCart(cart.id, {
                particulierPhone: e.target.value,
                facture: null,
                bon_de_commande: null,
              })
            )
          }
        />
      </div>
      <div className="mt-3 alignH gap-2">
        <div>{t("Email")} : </div>
        <input
          className="refClientItem p-1"
          value={cart.particulierEmail}
          onChange={(e) =>
            dispatch(
              updateCart(cart.id, {
                particulierEmail: e.target.value,
                facture: null,
                bon_de_commande: null,
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default Step4Particulier;
