const initialState = {
  commandes: [],
  commande: null,
  commandeLoading: false,
  commandeRefetchLoading: false,
  commandeError: null,
  commandesCount: 0,
  factureLoading: null,
  currentComments: [],
  currentCommentsLoading: false,
  commentsLoading: false,
};
const commandeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "COMMANDES_LOADING":
      return { ...state, commandeLoading: true, commandeError: null };
    case "GENERATE_FACTURE_LOADING":
      return { ...state, factureLoading: payload };
    case "GENERATE_BON_LOADING":
      return { ...state, factureLoading: payload };
    case "COMMANDES_REFETCH_LOADING":
      return { ...state, commandeRefetchLoading: true, commandeError: null };
    case "COMMANDES_SUCCESS":
      return {
        ...state,
        factureLoading: null,
        commandeLoading: false,
        commandeError: null,
        commandes: payload.commandes,
        commandesCount: payload.count,
        commandeRefetchLoading: false,
      };

    case "COMMANDE_SUCCESS":
      return {
        ...state,
        factureLoading: null,
        commandeLoading: false,
        commandeError: null,
        commande: payload.commande,
        commandeRefetchLoading: false,
      };

    case "UPDATE_COMMANDE":
      return {
        ...state,
        factureLoading: null,
        commandeLoading: false,
        commandeError: null,
        commandes: state.commandes.map((el) =>
          el.id === payload?.commandeId ? payload?.data : el
        ),
      };
    case "DELETE_COMMANDE":
      return {
        ...state,
        factureLoading: null,
        commandeLoading: false,
        commandeError: null,
        commandes: state.commandes.filter((el) => el.id !== payload),
      };
    case "COMMANDES_SUCCESS_REFETCH":
      return {
        ...state,
        commandeLoading: false,
        commandeError: null,
        commandeRefetchLoading: false,
        factureLoading: null,
        commandes: [...state.commandes, ...payload],
      };
    case "COMMANDES_ERROR":
      return {
        ...state,
        factureLoading: null,
        commandeLoading: null,
        commandeRefetchLoading: false,
        commandeError: null,
      };

    case "CREATE_COMMENTS_ORDER_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_ORDER_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_ORDER_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_ORDER_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };
    default:
      return state;
  }
};
export default commandeReducer;
