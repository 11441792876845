import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import UpdateTicketSAVEchanges from "../UpdateTicketSavAdmin/UpdateSavTabContent/UpdateSavContentEchanges";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import userAvatar from "../../../Images/Images/avatar.png";
import Thumbnail from "../../Common/Thumbnail/Thumbnail";

function NewSAVComment() {
  const [activeTab, setActiveTab] = useState("Comments");

  const { user } = useSelector((state) => state.userReducer);
  const chatSection = useRef(null);

  return (
    <div
      className="newSavComment d-flex flex-column justify-content-between p-4 px-5 pb-0"
      ref={chatSection}
    >
      <div className="alignH justify-content-between p-2 bg-dimGrey mb-3 position-sticky top-0">
        <div className="d-flex align-items-center">
          <Thumbnail
            image={
              user?.attributes?.photo?.data?.attributes?.url
                ? `${IMAGE_END_POINT}${user?.attributes?.photo?.data?.attributes?.url}`
                : userAvatar
            }
            width={50}
            height={50}
          />

          <div className="ms-2">
            <p className="f14 sBold text-ultraBlack mb-0">{`${
              user?.attributes?.firstName ?? ""
            } ${user?.attributes?.lastName ?? ""}`}</p>
            {/* <p className="f12 text-brightGrey mb-0">Société</p> */}
          </div>
        </div>
        {[1, 2]?.includes(user?.attributes?.userRole) && (
          <div>
            <TabsNavbar
              tabs={["Comments", "Admin notes"]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              bottom="-23px"
            />
          </div>
        )}
      </div>
      <UpdateTicketSAVEchanges activeTab={activeTab} />
    </div>
  );
}

export default NewSAVComment;
