import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import ProfileGeneralInfo from "./ProfileGeneralInfo";
import NewMemberGeneralInfo from "./NewMemberGeneralInfo";
import Button from "../../FormElements/Button/Button";
import { addUser } from "../../../../REDUX/actions/user";
import { getRandomNumber } from "../../../../Utils";
import { closeRightModal } from "../../../../REDUX/actions/rightModal";

import "./GeneralInfo.css";
import { useTranslation } from "react-i18next";

function GeneralInfo({
  fromProfile,
  newUser,
  setNewUser,
  handleChangeUpdateUser,
  invalidFields,
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { user, userCreateLoad } = useSelector((state) => state.userReducer);
  const userRole = user?.attributes?.role;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    birthdate: "",
    phoneNumber: "",
    email: "",
    password: "",
    occupation: "",
    photo: null,
    role: user?.attributes?.userRole,
    userRole: user?.attributes?.userRole,
  });
  const [triggerImg, setTriggerImg] = useState(false);
  const defaultImg = newUser?.photo;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        photo: imageFile,
      }));
    }
  };
  const handleMyProfileImg = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setNewUser((prev) => ({
        ...prev,
        newPhoto: imageFile,
      }));
    }
    setTriggerImg(true);
  };
  const handleCalenderInput = (e, name) => {
    const { value } = e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleAddNewUser = async () => {
    const phoneNumberPattern = /^(\+|\d{1,4})\s?(\d\s?){5,}$/;
    if (!formData?.phoneNumber?.match(phoneNumberPattern)) {
      toast.error(t("Please enter a valid phone number."));
      return;
    }
    if (formData?.photo) {
      const randomId = `${formData?.firstName}-${getRandomNumber(1000, 9999)}`;
      const dataToSend = {
        ...formData,
        birthdate: formData.birthdate ? formData.birthdate : undefined,
        photo: formData?.photo,
        username: randomId,
      };
      dispatch(addUser(dataToSend, t));
    } else {
      // If no image is selected, just dispatch the addUser action with the existing formData
      dispatch(addUser({ ...formData, username: formData?.firstName }, t));
    }
  };
  const handleCloseRightModal = () => {
    dispatch(closeRightModal());
  };
  const formDataIncomplete =
    !formData?.firstName ||
    !formData?.lastName ||
    !formData?.phoneNumber ||
    !formData?.email ||
    !formData?.password ||
    !formData?.occupation;

  const checkProfilePhoto = () => {
    return !triggerImg
      ? null
      : newUser.newPhoto
      ? newUser.newPhoto
      : newUser?.photo;
  };

  return (
    <div className="d-flex h-100 flex-column justify-content-between">
      {fromProfile ? (
        <ProfileGeneralInfo
          newUser={newUser}
          setNewUser={setNewUser}
          user={user}
          handleChangeUpdateUser={handleChangeUpdateUser}
          handleImageChange={handleMyProfileImg}
          userImage={checkProfilePhoto()}
          defaultImgUser={defaultImg}
          invalidFields={invalidFields}
        />
      ) : (
        <NewMemberGeneralInfo
          handleChange={handleChange}
          formData={formData}
          handleImageChange={handleImageChange}
          handleCalenderInput={handleCalenderInput}
          userImage={formData?.photo}
          userRole={userRole}
        />
      )}

      {!fromProfile ? (
        <div className="align-self-center w-100 alignC">
          <Button
            type="button"
            variant="outline"
            className="mx-2 px-5"
            onClick={handleCloseRightModal}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            className="mx-2"
            onClick={handleAddNewUser}
            formDataIncomplete={formDataIncomplete}
            loading={userCreateLoad}
          >
            {t("Save")}
          </Button>
          {/* <Button type="button" className="mx-2 bg-softBlue">
            Envoyer les accès
          </Button> */}
        </div>
      ) : null}
    </div>
  );
}

export default GeneralInfo;
