import React, { useEffect, useState } from "react";
import Select from "../../Common/FormElements/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../../REDUX/actions/catalogue";
import AdressSelector from "../Step3/AdressSelector";
import DeliveryOptionNew from "../Step3/DeliveryOptionNew";
import { useTranslation } from "react-i18next";

const Step3Catalogue = ({ searchInput, activeTab, productsactiveTab }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [selectedBusiness, setSelectedBusiness] = useState(undefined);
  const [selectedReseller, setSelectedReseller] = useState(undefined);
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const { userRole } = useSelector((state) => state.userReducer);
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { businessAffiliates, resellers } = useSelector(
    (state) => state.generalReducer
  );
  useEffect(() => {
    if (cart && cart?.deliveryOptionName && cart.deliveryOptions) {
      setSelectedDelivery(
        [
          {
            id: 7,
            name: t("Pickup at the Poseidon warehouse"),
            price: 0,
          },
          { id: 6, name: t("Manual delivery fees") },
          ...cart.deliveryOptions,
        ].filter((el) => el.id === cart.delivery_type?.id)[0] || {}
      );
    }
    setSelectedReseller(
      cart.reseller
        ? { code: cart.reseller.id, name: cart.reseller.name }
        : undefined
    );
    setSelectedBusiness(
      cart.business_affiliate
        ? {
            code: cart.business_affiliate.id,
            name: cart.business_affiliate.company,
          }
        : undefined
    );
  }, [cart]);

  const handleSelectBusiness = (value) => {
    setSelectedBusiness(value);
    setSelectedReseller(undefined);
    dispatch(
      updateCart(cart.id, {
        business_affiliate: value?.code || null,
        reseller: null,
        addresseDeFacturation: null,
        addresseDeLivraison: null,
        facture: null,
        bon_de_commande: null,
      })
    );
  };
  const handleSelectReseller = (value) => {
    setSelectedReseller(value);
    const foundReseller = resellers.filter((el) => el.id === value?.code)[0];
    dispatch(
      updateCart(cart.id, {
        reseller: value?.code || null,
        addresseDeFacturation:
          foundReseller.attributes.billingAddress.data[0].id,
        addresseDeLivraison:
          foundReseller.attributes.delivery_addresses.data[0].id,
        facture: null,
        bon_de_commande: null,
      })
    );
  };

  return (
    <div>
      <h2 className="bold text-softBlue">{t("Choose a delivery address")}</h2>
      {!cart.particulier && (
        <div className="row mb-4">
          {userRole === "admin" && (
            <>
              <div className="col-3">
                <p className="f16 text-ultraBlack mb-2">
                  {t("Business affiliate")}
                </p>
                <Select
                  showClear
                  resetable
                  placeholder={t("Select")}
                  callback={(e) => handleSelectBusiness(e.target.value)}
                  value={selectedBusiness}
                  options={businessAffiliates.map((el) => {
                    return { name: el.attributes.company, code: el.id };
                  })}
                  // disabled={canOrderWithoutPay}
                />
              </div>
            </>
          )}

          {userRole === "admin" || userRole === "BusinessBroker" ? (
            <div className="col-3">
              <p className="f16 text-ultraBlack mb-2">{t("Store")}</p>
              <Select
                showClear
                resetable
                placeholder={t("Select")}
                callback={(e) => handleSelectReseller(e.target.value)}
                value={selectedReseller}
                options={resellers
                  .filter((el) =>
                    selectedBusiness?.code
                      ? el.attributes.business_affiliate.data?.id ===
                        selectedBusiness?.code
                      : el
                  )
                  .map((el) => {
                    return { name: el.attributes.name, code: el.id };
                  })}
              />
            </div>
          ) : null}
        </div>
      )}

      <AdressSelector selectedReseller={selectedReseller} />

      <h2 className="bold text-softBlue mt-3">
        {t("Choose the delivery method")}
      </h2>
      <DeliveryOptionNew
        deliveryOption={{
          id: 7,
          name: t("Pickup at the Poseidon warehouse"),
          price: 0,
        }}
        storeIcon
        setSelectedDelivery={setSelectedDelivery}
        selectedDelivery={selectedDelivery}
      />
      {userRole === "admin" && (
        <DeliveryOptionNew
          deliveryOption={{
            id: 6,
            name: t("Manual delivery fees"),
          }}
          manual
          setSelectedDelivery={setSelectedDelivery}
          selectedDelivery={selectedDelivery}
        />
      )}
      {cart?.deliveryOptions?.map((deliveryOption, index) => (
        <DeliveryOptionNew
          deliveryOption={deliveryOption}
          key={index}
          setSelectedDelivery={setSelectedDelivery}
          selectedDelivery={selectedDelivery}
        />
      ))}
    </div>
  );
};

export default Step3Catalogue;
