import React from "react";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import UpdateMember from "../Modals/UpdateMember/UpdateMember";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeleteMember from "../Modals/DeleteMember/DeleteMember";
import { useTranslation } from "react-i18next";

function MemberItem({ name, phone, email, post, id, photo, userRole }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const HandleDeleteUser = (id, userRole) => {
    dispatch(openCenteredModal(<DeleteMember id={id} userRole={userRole} />));
  };
  const detailsOptions = [
    {
      text: t("Edit"),
      callback: () => dispatch(openRightModal(<UpdateMember memberId={id} />)),
    },
    {
      text: t("Delete"),
      red: true,
      callback: () => HandleDeleteUser(id, userRole),
    },
  ];
  return (
    <div
      className="memberItem d-flex align-items-center p-3 b-1-bottom-softGrey"
      onClick={() => dispatch(openRightModal(<UpdateMember memberId={id} />))}
    >
      <div className="d-flex align-items-center w20">
        <div className="avatarContainer me-3 ">
          <img
            src={photo}
            alt="user"
            className="me-3 br50 w-100 h-100 ob-cover"
            width={43}
            height={43}
          />
        </div>
        <p className="f14 text-customBlack medium mb-0">{name}</p>
      </div>
      <p className="f14 text-brightGrey mb-0 w20">{phone}</p>
      <p className="f14 text-brightGrey mb-0 w30">{email}</p>
      <p className="f14 text-brightGrey mb-0 w20">{post}</p>
      <VerticalPoints options={detailsOptions} showLeft className="ms-auto" />
    </div>
  );
}

export default MemberItem;
