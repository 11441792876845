export const statusOptions = (t) => [
  { name: t("Pending"), code: 1 },
  { name: t("Resolved"), code: 2 },
  { name: t("Canceled"), code: 3 },
];
export const problemOptions = (t) => [
  { name: t("Breakdown"), code: 1 },
  { name: t("Electricity"), code: 2 },
  { name: t("Break"), code: 3 },
  { name: t("Leak"), code: 4 },
  { name: t("Transport"), code: 5 },
  { name: t("Accessories"), code: 6 },
];
