import React, { useEffect, useState } from "react";
import PaiementItem from "./PaiementItem";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Common/FormElements/Button/Button";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import { updateCommandePayments } from "../../../REDUX/actions/commandes";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Separator from "../../Common/Separator/Separator";
import { useTranslation } from "react-i18next";

function Paiements({ formData, setFormData }) {
  const { t } = useTranslation("common");
  const [isDepassTotal, setisDepassTotal] = useState();
  const [disabled, setdisabled] = useState(true);
  const { user } = useSelector((state) => state.userReducer);

  // const { order } = useSelector((state) => state.ordersReducer);
  const {
    commande: order,
    commandeLoading,
    commandeRefetchLoading,
    commandeError,
  } = useSelector((state) => state.commandeReducer);

  const dispatch = useDispatch();

  const handleAddPayment = () => {
    setFormData({
      ...formData,
      payments: [
        ...formData?.payments,
        {
          id: (Math.random(0, 60000) * 10000).toFixed(0),
          paymentNumber: formData?.payments ? formData.payments.length + 1 : 1,
          amount: 0,
          date: undefined,
          newPayment: true,
        },
      ],
    });
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      payments: order?.payments || [],
    }));
  }, [order?.payments, setFormData]);

  useEffect(() => {
    const sumPaiements = formData?.payments?.reduce(
      (acc, { amount }) => acc + +amount,
      0
    );
    setisDepassTotal(sumPaiements > formData?.total ? true : false);
  }, [formData?.payments?.length, formData?.total, formData?.payments]);

  const handleSubmit = () => {
    dispatch(updateCommandePayments(formData));
  };

  useEffect(() => {
    if (formData?.payments) {
      for (const payment of formData?.payments) {
        if (payment?.amount === 0 || payment?.date === undefined) {
          setdisabled(true);
        } else {
          setdisabled(false);
        }
      }
    }
    if (!formData?.number) setdisabled(true);

    return () => {
      setdisabled(true);
    };
  }, [formData]);

  return (
    <div className="modalContent px-5 pt-4 d-flex flex-column justify-content-between h100">
      <div>
        <div className="d-flex justify-content-between gap-3">
          <div className="w50 mt-4">
            <p className="f16 text-ultraBlack mb-2">{`${t("Deposits")} (${
              formData?.payments?.length
            })`}</p>
            <TextInput
              euro
              type="number"
              disabled={true}
              value={formData?.payments?.reduce(
                (acc, { amount }) => acc + +amount,
                0
              )}
            />
          </div>
          <div className="w50 mt-4">
            <p className="f16 text-ultraBlack mb-2">
              {t("Remaining Amount to Pay")}
            </p>
            <TextInput
              euro
              type="number"
              disabled={true}
              value={
                formData?.totalTTC -
                formData?.payments?.reduce(
                  (acc, { amount }) => acc + +amount,
                  0
                )
              }
            />
          </div>
        </div>

        <Separator className="mt-5" />
        <div className="alignH bg-dimGrey px-4 py-3">
          <p className="w10 mb-0 sBold text-brightGrey f12">{t("Payment")}</p>
          <p className="w25 mb-0 sBold text-brightGrey f12">
            {t("Payment Amount")}
          </p>
          <p className="w25 mb-0 sBold text-brightGrey f12">
            {t("Payment date")}
          </p>
        </div>
        {formData?.payments?.map((paymentData, index) => (
          <PaiementItem
            key={paymentData.id}
            index={index}
            {...paymentData}
            formData={formData}
            setFormData={setFormData}
          />
        ))}
        {user?.attributes?.userRole === 1 && (
          <button
            onClick={handleAddPayment}
            className="addAttachment underline ms-3 mt-3 f12 text-softBlue border-0 bg-transparent"
          >
            + {t("Add a Payment")}
          </button>
        )}

        <div className="mt-3">
          <span className="text-red f14 ">
            {isDepassTotal
              ? t("You have exceeded the total amount of the order !")
              : ""}
          </span>
        </div>
      </div>

      <div className="buttonsContainer d-flex justify-content-center mt-5">
        <Button
          variant="outline"
          className="me-4"
          onClick={() => dispatch(closeRightModal())}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={disabled}
          loading={commandeLoading}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
}

export default Paiements;
