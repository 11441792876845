import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Step4MissingDataParticulier = () => {
  const { t } = useTranslation("common");
  const { cart } = useSelector((state) => state.catalogueReducer);

  return !cart.addresseDeLivraison ||
    !cart.addresseDeFacturation ||
    !cart.particulierName ||
    !cart.deliveryOptionName ||
    !cart.particulierPhone ||
    !cart.particulierEmail ||
    !cart.facture ||
    !cart.bon_de_commande ? (
    <div className="mt-3 bold">
      <div className="f12 text-red">{t("Missing details")}</div>
      {!cart.addresseDeLivraison ? (
        <div className="text-red f10">
          {t("Delivery address")} ({t("required")})
        </div>
      ) : null}
      {!cart.addresseDeFacturation ? (
        <div className="text-red f10">
          {t("Billing address")} ({t("required")})
        </div>
      ) : null}
      {!cart.deliveryOptionName ? (
        <div className="text-red f10">
          {t("Delivery method")} ({t("required")})
        </div>
      ) : null}
      {!cart.particulierName ? (
        <div className="text-red f10">
          {t("Customer's full name")} ({t("required")})
        </div>
      ) : null}
      {!cart.particulierPhone ? (
        <div className="text-red f10">{t("Client's phone number")}</div>
      ) : null}
      {!cart.particulierEmail ? (
        <div className="text-red f10">{t("Client's email")}</div>
      ) : null}

      {!cart.facture ? (
        <div className="text-red f10">{t("Invoice")}</div>
      ) : null}
      {!cart.bon_de_commande ? (
        <div className="text-red f10">{t("Purchase order")}</div>
      ) : null}
    </div>
  ) : null;
};

export default Step4MissingDataParticulier;
