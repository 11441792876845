export const affiliateInfosForm = [
  {
    type: "text",
    label: "Company",
    name: "company",
  },
  {
    type: "text",
    label: "Last name",
    name: "lastName",
    required: true,
  },
  {
    type: "text",
    label: "First name",
    name: "firstName",
    required: true,
  },
  {
    type: "tel",
    label: "Phone number",
    name: "phoneNumber",
    required: true,
  },
  {
    type: "email",
    label: "Email",
    name: "email",
    required: true,
  },
  // {
  //   type: "select",
  //   label: "Poste",
  //   placeholder: "Sélectionner",
  //   name: "occupation",
  //   required: true,
  //   options: [
  //     { name: "Option 1", code: "Option 1" },
  //     { name: "Option 2", code: "Option 2" },
  //   ],
  // },
  {
    type: "number",
    label: "SIRET number",
    name: "siret",
    required: true,
  },
  {
    type: "string",
    label: "Code VAT",
    name: "TVACode",
    required: true,
  },
  {
    type: "password",
    label: "Password",
    name: "password",
    required: true,
    separator: true,
  },
];
export const affiliateInfosUpdateForm = [
  {
    type: "text",
    label: "Company",
    name: "company",
  },
  {
    type: "text",
    label: "Last name",
    name: "lastName",
    required: true,
  },
  {
    type: "text",
    label: "First name",
    name: "firstName",
    required: true,
  },
  {
    type: "tel",
    label: "Phone number",
    name: "phoneNumber",
    required: true,
  },
  {
    type: "email",
    label: "Email",
    name: "email",
    required: true,
  },
  // {
  //   type: "select",
  //   label: "Poste",
  //   placeholder: "Sélectionner",
  //   name: "occupation",
  //   required: true,
  //   options: [
  //     { name: "Option 1", code: "Option 1" },
  //     { name: "Option 2", code: "Option 2" },
  //   ],
  // },
  {
    type: "number",
    label: "SIRET number",
    name: "siret",
    required: true,
  },
  {
    type: "string",
    label: "Code VAT",
    name: "TVACode",
    required: true,
  },
];

export const addressForm = [
  {
    type: "text",
    label: "Billing address",
    name: "billingAddress",
    required: true,
    span: 12,
  },
  {
    type: "select",
    label: "Country",
    name: "country",
    required: true,
  },
  {
    type: "select",
    label: "City",
    name: "city",
    subOption: "country",
    optionsName: "cities",
    required: true,
  },
  {
    type: "number",
    label: "Postal code",
    name: "zipcode",
    required: true,
  },
];
