import React, { useEffect, useState } from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import Separator from "../../Common/Separator/Separator";
import { profileForm, updateProfileForm } from "./data";
import PassswordInput from "../../Common/Forms/ResetPassword/PassswordInput";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Informations({
  isUpdating,
  formData,
  setFormData,
  setDisabled,
  customErrors,
  setCustomErrors,
}) {
  const { t } = useTranslation("common");
  const [invalidFields, setInvalidFields] = useState([]);
  const { user } = useSelector((state) => state.userReducer);

  const currForm = isUpdating ? updateProfileForm : profileForm;
  const errorsMap = {
    exist: t("This email is already in use."),
    phoneNumberError: t("Please enter a valid phone number."),
    emailError: t("Please enter a valid email."),
  };
  const checkeFormValidation = () => {
    const updatedInvalidFields = [];
    currForm.forEach(({ name, type, required }) => {
      if (required) {
        if (type === "select") {
          if (
            formData[name]?.code === undefined ||
            formData[name]?.code === null
          ) {
            updatedInvalidFields.push(name);
          }
        } else if (
          formData[name] === undefined ||
          formData[name] === null ||
          !String(formData[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    });
    if (updatedInvalidFields?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setInvalidFields(updatedInvalidFields);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    if (customErrors && customErrors[name]) {
      setCustomErrors((state) => {
        const updatedState = state;
        if (updatedState[name]) {
          delete updatedState[name];
        }
        return updatedState;
      });
    }
  };

  useEffect(() => {
    checkeFormValidation();
  }, [formData]);

  return (
    <div className="modalContent px-5 pt-4">
      <Row>
        {currForm.map((el) => {
          if (el?.type === "select") {
            return (
              <Col lg={el?.span ?? 6} key={el.name} className="mb-4">
                <p className="f16 text-ultraBlack mb-2">
                  {t(`${el.label ?? 3}`)}
                </p>
                <Select
                  {...el}
                  placeholder={t(`${el.placeholder ?? ""}`)}
                  value={formData[el.name]}
                  callback={handleChange}
                />
              </Col>
            );
          }

          return (
            <Col
              lg={el?.span ?? 6}
              key={el.name}
              className={`${
                customErrors && customErrors[el?.name] ? "mb-2" : "mb-4"
              }`}
            >
              {el?.separator && (
                <div className="mb-3">
                  <Separator />
                </div>
              )}
              <p className="f16 text-ultraBlack mb-2">
                {t(`${el.label ?? ""}`)}
              </p>
              {el?.type !== "password" ? (
                <TextInput
                  {...el}
                  placeholder={t(`${el.placeholder ?? ""}`)}
                  value={formData[el?.name]}
                  callback={handleChange}
                />
              ) : (
                ((isUpdating && user?.attributes?.userRole === 1) ||
                  !isUpdating) && (
                  <>
                    <Separator className="my-3" />
                    <PassswordInput
                      {...el}
                      value={formData[el?.name]}
                      callback={handleChange}
                      text={t("Password")}
                    />
                  </>
                )
              )}
              {customErrors &&
                errorsMap.hasOwnProperty(customErrors[el?.name]) && (
                  <div className="mt-2 text-redCalendar f14">
                    {errorsMap[customErrors[el?.name]]}
                  </div>
                )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Informations;
