import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColumnslist } from "./columnsList";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import OrderCardAdmin from "./OrderCardAdmin";
import "./CommandesViewContent.css";
import _ from "lodash";
import { fetchCommandes } from "../../REDUX/actions/commandes";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import Button from "../Common/FormElements/Button/Button";
import { SearchSVG } from "../Svgs";
import { useInView } from "react-intersection-observer";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CommandesViewContent = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const { userRole } = useSelector((state) => state.userReducer);
  const columnsList = getColumnslist(userRole, t);
  const [searchCommande, setsearchCommande] = useState("");
  const {
    commandes,
    commandeLoading,
    commandeError,
    commandesCount,
    commandeRefetchLoading,
  } = useSelector((state) => state.commandeReducer);

  const [page, setpage] = useState(1);
  const [sortField, setsortField] = useState("number:desc");
  useEffect(() => {
    if (inView && !commandeRefetchLoading) {
      setpage(page + 1);
      dispatch(fetchCommandes(searchCommande, sortField, page + 1));
    }
  }, [inView]);

  const handleSearchCommandes = () => {
    dispatch(fetchCommandes(searchCommande));
  };

  // mapOrders depending on userRole
  const mapOrders = (orderData) => {
    const commonProps = {
      key: orderData.id,
      numOrder: orderData.id,
      oldId: orderData.oldId,
      montantTTC: orderData.total,
      orderDate: orderData.oldCreatedAt ?? orderData.createdAt,

      orderData,
    };
    return <OrderCardAdmin commande={orderData} {...commonProps} />;
  };

  /* ******************************** Rendering ******************************* */
  return (
    <div className="flex-1">
      <div className="alignH justify-content-between gap-3 mb-4">
        <h2 className="text-blue bold f30">
          {t("Orders")} ({commandesCount})
        </h2>
        <div className="alignH gap-3 w60">
          <TextInput
            className={"flex-1"}
            placeholder={t("Search")}
            callback={(e) => setsearchCommande(e.target.value)}
          />
          <Button
            onClick={handleSearchCommandes}
            Icon={() => <SearchSVG color="white" />}
          >
            {t("Search")}
          </Button>
        </div>
      </div>
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex justify-content-between align-items-center pointer">
        {columnsList
          ?.filter((item) => item)
          ?.map(({ title, width, sort }, index) => (
            <div
              key={index}
              className={`${width} ${
                sortField.includes(sort) ? "bold" : ""
              } text-lightBlack sBold f14`}
              onClick={() => {
                setpage(1);
                setsortField(
                  sortField.includes(sort)
                    ? sortField.includes(":desc")
                      ? `${sort}:asc`
                      : `${sort}:desc`
                    : `${sort}:desc`
                );
                dispatch(
                  fetchCommandes(
                    searchCommande,
                    sortField.includes(sort)
                      ? sortField.includes(":desc")
                        ? `${sort}:asc`
                        : `${sort}:desc`
                      : `${sort}:desc`
                  )
                );
              }}
            >
              {title}
            </div>
          ))}
      </div>
      <LoaderWrapper loading={commandeLoading} error={commandeError}>
        {commandes.length ? commandes.map(mapOrders) : null}
      </LoaderWrapper>
      <div className="alignC" ref={ref}>
        {commandeRefetchLoading ? <Spinner /> : null}
      </div>
    </div>
  );
};

export default CommandesViewContent;
