import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import API from "../../Api/Api";
import { UPDATE_PROFILE } from "../Graphql/Mutations/user";
import client from "./Client";
import { GET_ME } from "../Graphql/Queries/user";
import { handleError } from "./error";
import qs from "qs";
import { uploadImageToStrapi } from "../../Utils/uploadImageToStrapi";
import { closeRightModal } from "./rightModal";
import store from "../store";
import moment from "moment";
import NEW_API from "../../Api/NewApi";
// Types
const FETCH_USERS = "FETCH_USERS";
const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

// New login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: "CLEAR_ORDERS" });
    dispatch({ type: "RESET_RESELLERS" });
    dispatch({ type: "RESET_PRODUCTS" });
    API.defaults.headers.common["Authorization"] = "";

    dispatch({ type: "LOADING_USER" });
    const { data } = await API.post(
      "/api/auth/local",
      { identifier: email, password },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    localStorage.setItem("token", "Bearer " + data.jwt);
    API.defaults.headers.common["Authorization"] = "Bearer " + data.jwt;

    NEW_API.defaults.headers.common["Authorization"] = "Bearer " + data.jwt;
    const me = await client.query({
      query: GET_ME,
      variables: {
        id: data.user.id,
      },
    });
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: me?.data?.usersPermissionsUser?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOGIN_ERROR", payload: error });
  }
};

// export const login = (email, password) => async (dispatch) => {
//   try {
//     dispatch({ type: "LOADING_USER" });
//     const { data } = await client.mutate({
//       mutation: LOGIN,
//       variables: {
//         email: email,
//         password: password,
//       },
//     });
//     localStorage.setItem("token", "Bearer " + data.login.jwt);
//     API.defaults.headers.common["Authorization"] = "Bearer " + data.login.jwt;
//     const me = await client.query({
//       query: GET_ME,
//       variables: {
//         id: data.login.user.id,
//       },
//     });
//
//     dispatch({
//       type: "LOGIN_SUCCESS",
//       payload: me?.data?.usersPermissionsUser?.data,
//     });
//   } catch (error) {
//     dispatch({ type: "LOGIN_ERROR", payload: error });
//   }
// };

export const currentUser =
  (withLoading = true) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      withLoading && dispatch({ type: "LOADING_USER" });
      API.defaults.headers.common["Authorization"] = token;
      NEW_API.defaults.headers.common["Authorization"] = token;
      const decodedToken = jwtDecode(token.substring(7));
      try {
        const me = await client.query({
          query: GET_ME,
          variables: {
            id: decodedToken.id,
          },
        });

        // const userData = {
        //   id: me.data.usersPermissionsUser.data.id,
        //   ...me.data.usersPermissionsUser.data.attributes,
        //   panier: me.data.usersPermissionsUser.data.attributes?.panier?.data?.id,
        // };

        dispatch({
          type: "AUTH_SUCCESS",
          payload: me?.data?.usersPermissionsUser?.data,
        });
      } catch (error) {
        console.log("this is the error:", error);
        localStorage.clear();
        if (error.response?.status === 401) {
          dispatch({ type: "UNAUTHORIZED", payload: error });
        } else {
          console.log(error);
          dispatch({ type: "LOGOUT" });
        }
      }
    } else {
      dispatch({ type: "LOGOUT" });
    }
  };

export const logout = () => async (dispatch, getState) => {
  localStorage.removeItem("token");
  client.resetStore();
  API.defaults.headers.common["Authorization"] = "";
  dispatch({ type: "LOGOUT" });
  dispatch({ type: "CLEAR_ORDERS" });
  dispatch({ type: "RESET_RESELLERS" });
  dispatch({ type: "RESET_PRODUCTS" });
};

export const updateProfile = (userId, newUser, t) => async (dispatch) => {
  const { user } = store.getState().userReducer;
  dispatch({ type: "UPDATE_PROFILE_LOAD" });
  try {
    const {
      firstName,
      lastName,
      occupation,
      phoneNumber,
      email,
      password,
      newPhoto,
      birthdate,
    } = newUser;

    await client.mutate({
      mutation: UPDATE_PROFILE,
      variables: {
        id: userId,
        firstName,
        lastName,
        occupation,
        phoneNumber,
        email,
        password,
        birthdate: moment(birthdate).format("YYYY-MM-DD"),
      },
    });
    if (newPhoto) {
      await uploadImageToStrapi(userId, newPhoto);
    }
    // console.log({ data });
    dispatch({ type: "UPDATE_PROFILE_SUCC" });
    dispatch(currentUser(false));
    if (user?.id === userId) {
      toast.success(t("Your profile has been updated"));
    } else {
      toast.success(t("The profile has been updated"));
    }
  } catch (error) {
    dispatch({ type: "UPDATE_PROFILE_FAIL" });
    console.log(error);
  }
};

export const changePassword =
  (currentPassword, password, passwordConfirmation, t) => async (dispatch) => {
    dispatch({ type: "CHANGE_PASSWORD_LOAD" });
    try {
      await API.post(
        "/api/auth/change-password",
        {
          currentPassword,
          password,
          passwordConfirmation,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch({ type: "CHANGE_PASSWORD_SUCC" });

      toast.success(t("The password has been changed"));
    } catch (error) {
      Object.keys(error?.response?.data?.error?.details).length === 0
        ? dispatch(handleError(error?.response?.data?.error?.message))
        : error?.response?.data?.error?.details?.errors?.map((errorItem) =>
            dispatch(handleError(errorItem?.message))
          );

      dispatch({ type: "CHANGE_PASSWORD_FAIL", payload: error });
    }
  };

export const getAllUsers = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  const userRole = store.getState()?.userReducer?.user?.attributes?.userRole;
  // Get the organization id of the user

  const id =
    store.getState().userReducer.user.attributes?.profil?.data?.attributes
      ?.reseller?.data?.id;

  // Define the base query parameters
  const baseQuery = {
    fields: [
      "occupation",
      "phoneNumber",
      "firstName",
      "lastName",
      "email",
      "username",
      "userRole",
    ],
    populate: {
      photo: { fields: ["url"] },
      profil: {
        reseller: {
          fields: ["id"],
        },
      },
    },
    sort: ["createdAt"],
  };

  // Define filters based on userRole
  let filters = {
    userRole: {
      $eq: userRole,
    },
  };

  // Apply additional filter for profil when userRole is revendeur (client)
  if (userRole === 0) {
    filters.profil = {
      reseller: {
        id: {
          $eq: id,
        },
      },
    };
  }

  // Combine base query and filters
  const query = qs.stringify({
    ...baseQuery,
    filters,
  });

  try {
    API.defaults.headers.common.Authorization = token;
    const { data } = await API.get("/api/users?" + query);
    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_USERS_FAILURE, error: error });
    toast.error("une erreur est survenue");
  }
};

// create User Action:
export const addUser = (newUser, t) => async (dispatch) => {
  dispatch({ type: "CREATE_USER_LOADING" });

  const userRole = store.getState()?.userReducer?.user?.attributes?.userRole;

  const id =
    store.getState().userReducer.user.attributes?.profil?.data?.attributes
      ?.reseller?.data?.id;

  const query = "";

  const dataToSend = userRole === 1 ? { ...newUser } : { ...newUser };

  try {
    const { data: createdUser } = await API.post(`/api/users/${query}`, {
      ...dataToSend,
    });
    console.log(createdUser);
    if (userRole === 0) {
      await API.post(`api/profils/${query}`, {
        data: {
          user: createdUser?.id,
          reseller: id,
        },
      });
    }

    uploadImageToStrapi(createdUser.id, newUser.photo);
    dispatch(getAllUsers(newUser.userRole));
    dispatch(closeRightModal());
    dispatch({ type: "CREATE_USER_SUCC" });
    toast.success(t("Member created successfully!"));
  } catch (error) {
    dispatch({ type: "CREATE_USER_FAIL" });
    if (error?.response?.data?.error?.message === "Email already taken") {
      toast.error(t("The email address already exists"));
    } else if (
      error?.response?.data?.error?.message === "email must be a valid email"
    ) {
      toast.error(t("The email address is not valid!"));
    } else if (
      error?.response?.data?.error?.message ===
      "password must be at least 6 characters"
    ) {
      toast.error(t("Password must contain at least 6 characters"));
    } else {
      toast.error(t("An error occurred while creating the member"));
    }
  }
};
// update user
export const updateUser = (currentUser, t) => async (dispatch) => {
  dispatch({ type: "UPDATE_USER_LOADING" });
  try {
    const { data: updatedUser } = await API.put(
      `/api/users/${currentUser.id}`,
      currentUser
    );
    if (currentUser.newPhoto) {
      await uploadImageToStrapi(updatedUser.id, currentUser.newPhoto);
    }
    dispatch(getAllUsers(currentUser?.userRole));
    dispatch(closeRightModal());
    dispatch({ type: "UPDATE_USER_SUCC" });
    toast.success(t("Member has been updated successfully!"));
  } catch (error) {
    dispatch({ type: "UPDATE_USER_FAIL" });
    toast.error(t("An error occurred while updating the member"));
  }
};
// delete user
export const deleteUser = (userId, userRole, t) => async (dispatch) => {
  try {
    await API.delete(`/api/users/${userId}`);
    dispatch(getAllUsers(userRole));
    toast.success(t("Member deleted successfully!"));
  } catch (error) {
    toast.error(t("An error occurred while deleting the member."));
  }
};
// get user
export const getSelectedUser = (id) => async (dispatch) => {
  dispatch({ type: "SELECTED_USER_LOADING" });
  try {
    const query = qs.stringify(
      {
        populate: ["photo"],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const { data } = await API.get(`/api/users/${id}?` + query);
    dispatch({ type: "FETCH_SELECTED_USER", payload: data });
  } catch (error) {
    dispatch({ type: "SELECTED_USER_ERROR", payload: error });
    console.log(error);
  }
};

export const getResponsables = () => async (dispatch) => {
  dispatch({ type: "RESPONSABLES_LOADING" });
  try {
    const { data } = await API.get(`api/users?filters[userRole]=1&populate=*`);
    dispatch({ type: "RESPONSABLES_FETCHED", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "RESPONSABLES_ERROR", payload: error });
  }
};

export const getRevendeur = (payload) => async (dispatch) => {
  dispatch({ type: "REVENDEUR_LOADING" });
  try {
    const { id } = payload;
    const query = qs.stringify(
      {
        populate: {
          profil: {
            populate: {
              reseller: {
                fields: "*",
                populate: {
                  deliveryAddress: {
                    fields: "*",
                    populate: { country: "*", city: "*" },
                  },
                },
              },
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    const { data } = await API.get(`api/users/${id}?${query}`);
    dispatch({ type: "REVENDEUR_FETCHED", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "REVENDEUR_ERROR", payload: error });
  }
};
