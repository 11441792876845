import React from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import CalendarInput from "../../Common/FormElements/CalendarInput/CalendarInput";
import ListDeleteIcon from "../../Common/DeleteIcon/ListDeleteIcon";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteInvoice } from "../../../REDUX/actions/invoices";
import { deletePayment } from "../../../REDUX/actions/commandes";
import { useTranslation } from "react-i18next";

function PaiementItem({
  index,
  id,
  amount,
  date,
  formData,
  setFormData,
  newPayment,
}) {
  const { t } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const handleAmountChange = (e) => {
    setFormData({
      ...formData,
      payments: formData?.payments?.map((payment) => {
        return payment.id === id
          ? { ...payment, amount: e.target.value }
          : payment;
      }),
    });
  };
  const handleDateChange = (e) => {
    setFormData({
      ...formData,
      payments: formData?.payments?.map((payment) => {
        return payment.id === id ? { ...payment, date: e.value } : payment;
      }),
    });
  };

  const handleDelete = () => {
    if (newPayment) {
      setFormData({
        ...formData,
        payments: formData.payments.filter((payment) => payment.id !== id),
      });
    } else {
      formData?.payStatus !== "Payée" &&
        // formData?.payments?.length === 1 &&
        // formData?.payments[0]?.id === id &&
        // dispatch(deleteInvoice({ id: formData?.invoice?.id }));
        dispatch(deletePayment({ id, commandeID: formData?.id }));
    }
  };

  return (
    <div className="alignH px-4 py-3 b-1-bottom-softGrey">
      <p className="w10 f12 mb-0 text-softBlack ">
        {t("Payment")} {index + 1}
      </p>
      <div className="w25 pe-4">
        <TextInput
          euro
          value={amount}
          callback={handleAmountChange}
          inputClassName={"f12"}
          placeholderStyle={"f12"}
          type="number"
          disabled={user?.attributes?.userRole !== 1}
        />
      </div>
      <div className="w25">
        <CalendarInput
          className="paymentDateInput b-2-softGrey w100"
          customInputStyle={{ fontSize: "12px" }}
          placeholder={t("Payment date")}
          calendarIconSize={"16px"}
          callback={handleDateChange}
          value={date ? moment(date).utc(true).toDate() : undefined}
          withMinDate={false}
          disabled={user?.attributes?.userRole !== 1}
        />
      </div>
      {user?.attributes?.userRole === 1 && (
        <ListDeleteIcon callback={handleDelete} />
      )}
    </div>
  );
}

export default PaiementItem;
