import React from "react";
import { useTranslation } from "react-i18next";

function RevendeurNotFound() {
  const { t } = useTranslation("common");
  return (
    <div className="m-5">
      <p>⚠️ {t("This ticket was created by an admin!")}</p>
    </div>
  );
}

export default RevendeurNotFound;
