import React from "react";
import { useSelector } from "react-redux";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { productTypesModel } from "../utils";
import CartPackingItem from "../CartPackingItem";
import { useTranslation } from "react-i18next";

const PackingGroupList = ({
  searchInput,
  activeTab,
  productsactiveTab,
  hideActions,
}) => {
  const { i18n } = useTranslation("common");
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div>
      {cart.groupedPackings?.map((packingGroup, index) => (
        <div key={index} className="my-3 py-3 border-bottom">
          <div className="alignH f14 bold gap-3 mb-3">
            <div style={{ width: "250px" }}>
              <img
                width={"200px"}
                src={`${IMAGE_END_POINT}${packingGroup.mainImageURL}`}
                alt=""
              />
            </div>
            <div className="w100">
              <div>
                <div>
                  {i18n.language === "en"
                    ? packingGroup.productName_en ?? packingGroup.productName
                    : packingGroup.productName}{" "}
                  ({packingGroup.packings.length})
                </div>
                <div className="text-softBlue f12">
                  {productTypesModel[packingGroup.productType]}
                </div>
                <CartPackingItem
                  packingGroup={packingGroup}
                  activeTab={activeTab}
                  searchInput={searchInput}
                  productsactiveTab={productsactiveTab}
                  showFields
                  hideActions={hideActions}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PackingGroupList;
