const initialState = {
  entrepots: [],
  loading: false,
  packingsLoading: false,
  packings: [],
  error: null,
  entrepot: null,
};

const entrepotReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ENTREPOTS_LOADING":
      return { ...state, loading: true, error: null };

    case "FETCH_ENTREPOTS_SUCCESS":
      return { ...state, loading: false, entrepots: payload, error: null };

    case "FETCH_ENTREPOTS_ERROR":
      return { ...state, loading: false, error: payload };

    case "FETCH_ENTREPOT_PACKINGS_LOADING":
      return { ...state, packingsLoading: true, error: null };

    case "FETCH_ENTREPOT_PACKINGS_SUCCESS":
      return {
        ...state,
        packingsLoading: false,
        packings: payload,
        error: null,
      };

    case "FETCH_ENTREPOT_PACKINGS_ERROR":
      return { ...state, packingsLoading: false, error: payload };

    default:
      return state;
  }
};

export default entrepotReducer;
