import React from "react";
import ProductStats from "../ProductStats/ProductStats";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProductsStats = () => {
  const { t } = useTranslation("common");
  const { stats } = useSelector((state) => state.statsReducer);

  const productStats = Object.keys(stats?.productStats ?? {})?.map((key) => {
    const categoryName =
      key.toUpperCase().slice(0, 1) + key.toLowerCase().slice(1);
    return {
      id: key,
      title: `${t(`${categoryName}`, { ns: "common" })} ${t("sold")}`,
      statValue: stats?.productStats[key] ?? "-",
    };
  });

  return (
    <div className="alignC justify-content-between align-items-stretch gap-3">
      {productStats?.map(({ id, title, statValue }) => (
        <ProductStats title={title} statValue={statValue} key={id} />
      ))}
    </div>
  );
};

export default ProductsStats;
