import React from "react";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import UpdateApporteurAffaire from "../../ApporteurAffaires/NewApporteurAffaire/UpdateApporteurAffaire";
import { useDispatch } from "react-redux";
import { deleteAffiliate } from "../../../REDUX/actions/affiliate";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import ConfirmModal from "../../Common/Modal/ConfirmModal";
import NewIntermediary from "./NewIntermediary";
import { deleteProfile } from "../../../REDUX/actions/profile";
import { useTranslation } from "react-i18next";

const IntermediaryCard = ({
  id,
  userId,
  firstName,
  lastName,
  phoneNumber,
  email,
  occupation,
  reseller,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  return (
    <div>
      <div
        className={`row px-4 py-4 d-flex justify-content-between align-items-center w-100 orderCardContainer `}
        onClick={() =>
          dispatch(
            openRightModal(
              <NewIntermediary id={id} resellerId={reseller?.data?.id} />
            )
          )
        }
      >
        <span className={`f14 normal col-2 text-black`}>{lastName ?? "-"}</span>
        <span className={`f14 normal col-2 text-black`}>
          {firstName ?? "-"}
        </span>
        <span className={`f14 normal col-2 text-lightBlack`}>
          {phoneNumber ?? "-"}
        </span>
        <span className={`f14 normal col-3 text-lightBlack`}>
          {email ?? "-"}
        </span>
        <span className={`f14 normal col-2 text-lightBlack`}>{occupation}</span>
        <span className="col-1">
          <VerticalPoints
            showLeft
            options={[
              {
                text: t("Edit"),
                callback: () =>
                  dispatch(
                    openRightModal(
                      <NewIntermediary
                        id={id}
                        resellerId={reseller?.data?.id}
                      />
                    )
                  ),
              },
              {
                text: t("Delete"),
                red: true,

                callback: () =>
                  dispatch(
                    openCenteredModal(
                      <ConfirmModal
                        label={t("The profile")}
                        id={id}
                        onConfirm={() => {
                          dispatch(deleteProfile(id, userId));
                        }}
                      />
                    )
                  ),
              },
            ]}
            className="ms-auto"
          />
        </span>
      </div>
    </div>
  );
};

export default IntermediaryCard;
