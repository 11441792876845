import React from "react";
import { useDispatch } from "react-redux";

import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import Prospect from "../../Calendar/Modals/Prospect/Prospect";
import AskForQuote from "../../Prospects/AskForQuote";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import { isDefined } from "../../../Utils";
import { useTranslation } from "react-i18next";

const ProspectPreview = ({
  id,
  nom,
  tel,
  email,
  Icon,
  withoutOptions = false,
}) => {
  const { t } = useTranslation("common");
  const columnStyle = "col-3 textStart text-brightGrey f15 normal py-4";

  const dispatch = useDispatch();

  return (
    <div className="row w-100 ps-4 alignH min-h-70">
      <span className={`text-customBlack ${columnStyle}`}>{nom}</span>
      <span className={columnStyle}>
        {isDefined(tel) && tel !== "" ? tel : "-"}
      </span>
      <span className={columnStyle}>{email}</span>
      <div className={"col-3 textStart alignH justify-content-between"}>
        <span>{Icon}</span>
        <span className={"col-3 textStart py-4"}>
          {!withoutOptions && (
            <VerticalPoints
              showLeft
              options={[
                {
                  text: t("Prospect Profile"),
                  callback: () =>
                    dispatch(openRightModal(<Prospect id={id} />)),
                },
                {
                  text: t("Quote Request"),
                  callback: () => dispatch(openCenteredModal(<AskForQuote />)),
                },
                // { text: "Supprimer le prospect", red: true },
              ]}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default ProspectPreview;
