import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Step4Reseller = () => {
  const { t } = useTranslation("common");
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);
  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <div className="alignH gap-5">
        <h4 className="mt-4 text-softBlue bold">1- {t("Client details")}</h4>
      </div>
      <div className="mt-3 alignH gap-2">
        <div>{t("Store")} : </div>
        <div>
          {cart.reseller ? (
            cart.reseller.name
          ) : (
            <div className="text-red">{t("Missing")}</div>
          )}{" "}
        </div>
      </div>
      {userRole !== "reseller" && (
        <div className="mt-3 alignH gap-2">
          <div>{t("Business affiliate")} : </div>
          <div>
            {cart.business_affiliate ? (
              cart.business_affiliate.company
            ) : (
              <div className="text-red">{t("Missing")}</div>
            )}{" "}
          </div>
        </div>
      )}
      {userRole !== "reseller" && (
        <div className="alignH gap-2">
          <div>{t("Commission")} : </div>
          <div>
            {cart.business_affiliate ? (
              cart.comission + "€"
            ) : (
              <div className="text-red">{t("Missing")}</div>
            )}{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step4Reseller;
