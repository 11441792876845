import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import CalendarInput from "../../Common/FormElements/CalendarInput/CalendarInput";
import {
  getSAVProducts,
  getSAVRevendeurs,
} from "../../../REDUX/actions/ticketSAV";
import moment from "moment";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import {
  getCurrentReseller,
  getResellers,
} from "../../../REDUX/actions/reseller";
import {
  savOrdersExtractor,
  savPdtsCommandesExtractor,
} from "../../../Utils/DataExtractors/savExtractor";
import { getProductsFromOrder } from "../../../REDUX/actions/orders";
import { fetchCommande } from "../../../REDUX/actions/commandes";
import { useTranslation } from "react-i18next";
import { problemOptions } from "../../../helpers/formLabels/savRevendeurFilters";

const NewTicketSAVInfosRevendeur = ({ formData, setFormData }) => {
  const { t, i18n } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);

  const { savRevendeurs, savProductsLoading, savProductsError } = useSelector(
    (state) => state.ticketSAVReducer
  );
  // const { orderProducts } = useSelector((state) => state.ordersReducer);
  const { commande } = useSelector((state) => state.commandeReducer);
  const { currentReseller } = useSelector((state) => state.resellerReducer);

  const dispatch = useDispatch();

  const handleChange = (event, name) => {
    setFormData({
      ...formData,
      [name]: event.target.value ? event.target.value : event.value,
    });
  };

  useEffect(() => {
    dispatch(getSAVRevendeurs());
    dispatch(getResellers());
    dispatch(getSAVProducts());
    formData?.commande?.id && dispatch(fetchCommande(formData?.commande?.id));
  }, [dispatch, formData?.commande?.id]);

  useEffect(() => {
    formData?.reseller?.id &&
      dispatch(getCurrentReseller(formData?.reseller?.id));
  }, [dispatch, formData?.reseller?.id]);

  useEffect(() => {
    formData?.order?.id && dispatch(getProductsFromOrder(formData?.order?.id));
  }, [dispatch, formData?.order?.id, setFormData]);

  useEffect(() => {
    setFormData({
      ...formData,
      serialNumber: formData?.product?.serialNumber,
      refClient: formData?.product?.refClient,
    });
    // eslint-disable-next-line
  }, [
    dispatch,
    formData?.product?.serialNumber,
    formData?.product?.refClient,
    setFormData,
  ]);

  useEffect(() => {
    user?.attributes?.userRole === 0 &&
      setFormData({
        ...formData,
        reseller: {
          id: user?.attributes?.profil?.data?.attributes?.reseller?.data?.id,
        },
      });
  }, []);

  return (
    <ShadowLoader loading={savProductsLoading} error={savProductsError}>
      <div>
        <div className="d-flex justify-content-between">
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">{t("Reseller")}</p>
            {user?.attributes?.userRole !== 0 ? (
              <Select
                placeholder={t("Reseller")}
                options={savRevendeurs}
                callback={(e) => handleChange(e, "reseller")}
                value={formData?.reseller}
                appendToSelf={true}
                filter={true}
              />
            ) : (
              <TextInput
                name={"reseller"}
                value={
                  user?.attributes?.profil?.data?.attributes?.reseller?.data
                    ?.attributes?.name
                }
                disabled
              />
            )}
          </div>
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">{t("Order")}</p>
            <Select
              placeholder={t("Order")}
              options={savOrdersExtractor(
                user?.attributes?.userRole === 0
                  ? user?.attributes?.commandes?.data
                  : currentReseller?.attributes?.commandes?.data,
                t
              )}
              callback={(e) => handleChange(e, "commande")}
              value={formData?.commande}
            />
          </div>
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">{t("Product")}</p>
            <Select
              placeholder={t("Product")}
              options={
                commande?.packings
                  ? savPdtsCommandesExtractor(commande?.packings, i18n.language)
                  : []
              }
              callback={(e) => handleChange(e, "product")}
              value={formData?.product}
            />
          </div>
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">
              {t("Serial number full")}
            </p>
            <TextInput
              placeholder={t("Serial number full")}
              callback={(e) => handleChange(e, "serialNumber")}
              value={formData?.serialNumber ?? ""}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between my-5">
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">{t("Reference client")}</p>
            <TextInput
              placeholder={t("Reference client")}
              callback={(e) => handleChange(e, "refClient")}
              value={formData?.refClient}
            />
          </div>
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">{t("Delivery date")}</p>
            <CalendarInput
              className="b-2-softGrey w100"
              placeholder={t("Delivery date")}
              callback={(e) => handleChange(e, "deliveryDate")}
              withMinDate={false}
              value={formData?.deliveryDate ?? ""}
              disabled={true}
            />
          </div>
          <div className="w24">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <p className="f16 text-ultraBlack mb-0">
                {t("Warranty expiration date")}
              </p>
              <p className="text-lameRed f12 mb-0">
                {formData?.warrantyEndDate &&
                moment(formData?.warrantyEndDate)?.format("DD-MM-YYYY") <
                  moment()
                  ? t("Expired")
                  : null}
              </p>
            </div>
            <TextInput
              className="warrantyEndDate bg-softGrey br5"
              value={formData?.warrantyEndDate ?? ""}
              disabled
            />
          </div>
          <div className="w24">
            <p className="f16 text-ultraBlack mb-2">{t("Problem")}</p>
            <Select
              placeholder={t("Problem")}
              options={problemOptions(t)}
              callback={(e) => handleChange(e, "problem")}
              value={formData?.problem}
            />
          </div>
        </div>
      </div>
    </ShadowLoader>
  );
};

export default NewTicketSAVInfosRevendeur;
