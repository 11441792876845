/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// helpers
import { onglets } from "./Onglets";
import { rolesColors } from "../../../Utils/fileUtils";
// style
import "./Drawer.css";
import { useTranslation } from "react-i18next";
/* -------------------------------------------------------------------------- */
/*                             Local UI component                             */
/* -------------------------------------------------------------------------- */
const NavList = () => {
  const { t } = useTranslation("common");
  /* ********************************** Hooks ********************************* */
  // select userRole from user reducer
  const { userRole } = useSelector((state) => state.userReducer);
  // get the location of the current user
  const location = useLocation();
  /* ****************************** NAV Rendering ***************************** */
  return onglets?.map(
    (el, index) =>
      ((userRole === "reseller" && !el?.showOnlyToAdmin) ||
        (userRole === "admin" && !el?.showOnlyToClient) ||
        (userRole === "BusinessBroker" && el?.showOnlyToBroker)) && (
        <Link
          to={el.to}
          className={`drawLink drawLink-${userRole} transition02 my-2 d-flex br5 ${
            location.pathname === el.to ||
            (location.pathname.includes(el.to) && el.name === "Prospects")
              ? `bg-${rolesColors[userRole]} text-white`
              : ""
          }`}
          key={index}
        >
          <div className="d-flex justify-content-start align-items-center ps-1">
            {el.icon(
              location.pathname === el.to ||
                (location.pathname.includes(el.to) && el.name === "Prospects")
                ? "white"
                : null
            )}{" "}
            <span className="linkName">{t(`${el.name}`)}</span>
          </div>
        </Link>
      )
  );
};
export default NavList;
