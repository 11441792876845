import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  getOneProspect,
} from "../../../../REDUX/actions/prospects";
import moment from "moment";
import Button from "../../../Common/FormElements/Button/Button";
import TextArea from "../../../Common/FormElements/TextArea/TextArea";
import { useTranslation } from "react-i18next";

function Prospect({ id }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  useEffect(() => {
    dispatch(getOneProspect(id));
  }, [dispatch, id]);
  const { prospect } = useSelector((state) => state.prospectsReducer);

  return (
    <div className="px-5 d-flex flex-column h-100">
      <h1 className="quickSand rightModalTitle mb-5">Détail prospect</h1>
      <div className="d-flex px-4 pt-4 pb-5 bg-dimGrey mb-4">
        <div className="d-flex w-50">
          <div className="pt-3">
            <p className="f15 text-customBlack sBold mb-0">
              {prospect?.attributes?.firstName} {prospect?.attributes?.lastName}
            </p>
            <p className="f15 text-brightGrey mb-0 ellipsis-text ">
              {prospect?.attributes?.email}
            </p>
            <p className="f15 text-brightGrey mb-0">
              {prospect?.attributes?.phoneNumber}
            </p>
            <p className="f15 sBold text-customBlack mb-2 mt-3">
              {t("Date of 1st contact creation")}
            </p>
            <p className="f15 text-brightGrey mb-0">
              {moment(prospect?.attributes?.firstContactDate).format(
                "DD/MM/YYYY",
              )}
            </p>
          </div>
        </div>
        <div className="ms-5 pt-3 w-50">
          <p className="f15 sBold text-customBlack mb-2 ellipsis-text">
            {t("Address")}
          </p>
          <p className="f15 text-brightGrey mb-0">
            {prospect?.attributes?.address?.data?.attributes?.street}
          </p>
          <p className="f15 text-brightGrey mb-0">
            {
              prospect?.attributes?.address?.data?.attributes?.city?.data
                ?.attributes?.name
            }
          </p>
          <p className="f15 text-brightGrey mb-0">
            {
              prospect?.attributes?.address?.data?.attributes?.country?.data
                ?.attributes?.name
            }
          </p>
          {/* <p className="f15 text-brightGrey mb-0">06000 Nice, France</p> */}
        </div>
      </div>
      <p className="f15 sBold text-customBlack mb-2 ellipsis-text">
        {t("Comments")}
      </p>
      <div className="d-flex flex-column justify-content-end mb-3">
        {prospect?.attributes?.comments?.data?.map((comment) => (
          <div className="bg-dimGrey py-2 px-3 mt-2 br4">
            <p className="f15 text-brightGrey mb-0">
              <span className="text-dark medium underline">
                {comment?.attributes?.user?.data?.attributes?.firstName +
                  " " +
                  comment?.attributes?.user?.data?.attributes?.lastName}{" "}
                :
              </span>{" "}
              {comment?.attributes?.text}
            </p>
          </div>
        ))}
      </div>
      {/* <OrderProductsList forRightModal withoutStatus /> */}

      <div className="mt-auto">
        <TextArea
          name="comment"
          value={comment ?? ""}
          callback={(e) => setComment(e.target.value)}
          rows={4}
          placeholder={""}
        />

        <div className="w-100 d-flex justify-content-end mt-3">
          <Button
            disabled={!comment?.trim()?.length > 0}
            onClick={() => {
              dispatch(addComment({ id, comment }));
              setComment("");
            }}
          >
            {t("Add a comment")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Prospect;
