import React from "react";
import InvoiceTabComments from "./InvoiceTabComments";
import { useTranslation } from "react-i18next";

const InvoiceComments = ({ id, slug }) => {
  const { t } = useTranslation("common");
  return (
    <div className="newApporteurAffaire d-flex flex-column justify-content-between h100">
      <div className="h-100 d-flex flex-column">
        <h2 className="sBold f25 quickSand text-blue px-5 mb-5">
          {`${t("The invoice")} #${slug}`}
        </h2>
        <InvoiceTabComments id={id} />
      </div>
    </div>
  );
};

export default InvoiceComments;
