import React from "react";
import { useSelector } from "react-redux";
import FactureItem from "./FactureItem";
import CustomCheckBox from "../../Common/CustomCheckBox/CustomCheckBox";
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { ProgressSpinner } from "primereact/progressspinner";
import { useTranslation } from "react-i18next";

function FacturesList({
  searchInput,
  measureRef,
  checkedInvoices,
  setCheckedInvoices,
}) {
  const { t } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);
  const { invoices, invoicesLoading, invoicesError } = useSelector(
    (state) => state.invoicesReducer
  );
  /* *************************** Events && callBacks ************************** */
  const handleCheckAll = () => {
    if (checkedInvoices?.length === invoices?.length) {
      setCheckedInvoices([]);
    } else {
      setCheckedInvoices(
        invoices?.map((item) => ({
          id: item?.id,
          fileUrl: item?.fileUrl,
          orderSlug: item?.orderSlug,
          invoiceSlug: item?.slug,
        }))
      );
    }
  };

  const factureColWidth = user?.attributes?.userRole === 0 ? "w14" : "w14";
  const factureColStyle = `f14 text-brightGrey mb-0 ${factureColWidth} sBold`;
  /* ******************************** rendering ******************************* */
  return (
    <div className="flex-1 d-flex flex-column">
      <div className="listHeader d-flex align-items-center bg-dimGrey p-3">
        <CustomCheckBox
          checked={
            invoices?.length > 0 && checkedInvoices?.length === invoices?.length
          }
          callback={handleCheckAll}
          className="me-3"
        />
        <p className={factureColStyle}>{t("Invoice number")}</p>
        <p className={factureColStyle}>{t("Order number")}</p>
        {/* <p className={factureColStyle}>Créée par</p> */}
        {/* <p className={factureColStyle}>Référence client</p> */}
        {user?.attributes?.userRole !== 0 && (
          <p className={factureColStyle}>{t("Reseller")}</p>
        )}
        <p className={factureColStyle}>{t("Invoice date")}</p>
        <p className={factureColStyle}>{t("Amount")}</p>
        <p className={factureColStyle}>{t("Status")}</p>
      </div>
      <div className="flex-1">
        <LoaderWrapper
          loading={invoicesLoading && invoices?.length < 10}
          error={invoicesError}
        >
          {invoices?.map((invoiceData) => {
            return (
              <FactureItem
                key={invoiceData.id}
                {...invoiceData}
                checked={checkedInvoices?.find(
                  (item) => +item?.id === +invoiceData?.id
                )}
                searchInput={searchInput}
                checkedInvoices={checkedInvoices}
                setCheckedInvoices={setCheckedInvoices}
              />
            );
          })}
        </LoaderWrapper>
      </div>
      {!invoicesLoading && (
        <div style={{ width: "100%", height: "1px" }} ref={measureRef} />
      )}
      {invoicesLoading && invoices?.length >= 10 && (
        <ProgressSpinner
          style={{
            display: "flex",
            alignItems: "center",
            width: "100px",
            height: "100px",
            marginTop: "30px",
          }}
          strokeWidth={3}
          className="loadingViewSpinner"
        />
      )}
    </div>
  );
}

export default FacturesList;
