import React from "react";
import { useSelector } from "react-redux";
import Button from "../../Common/FormElements/Button/Button";
import ProspectsPreviewValues from "./ProspectsPreviewValues";
import { ProspectsSvg } from "../../Svgs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProspectsPreview = () => {
  const { t } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (user?.attributes?.userRole === 0) {
      navigate("/prospects");
    } else {
      navigate("/revendeur");
    }
  };
  const prospectsPreviewTitles = [
    { id: 1, label: t("Name") },
    { id: 2, label: t("Phone") },
    { id: 3, label: t("Email") },
    { id: 4, label: "" },
  ];

  return (
    <div className="px-4 pt-4 w-100 h-100 bg-white br5 shadow14">
      <div className="alignH justify-content-between">
        <div className="alignH gap-2">
          <ProspectsSvg color="#14748e" />
          <span className=" f22 bold text-blue">
            {user?.attributes?.userRole === 0 ? t("Prospects") : t("Resellers")}
          </span>
        </div>
        <Button onClick={handleNavigate}>{t("View More")}</Button>
      </div>
      <div>
        <div className="row bg-dimGrey orderListTable py-3 ps-4">
          {prospectsPreviewTitles?.map(({ id, label }) => (
            <span
              className="col-3 f14 text-brightGrey sBold"
              style={{ textAlign: "left" }}
              key={id}
            >
              {label}
            </span>
          ))}
        </div>
        <ProspectsPreviewValues />
      </div>
    </div>
  );
};

export default ProspectsPreview;
