import React from "react";
import { useDispatch } from "react-redux";
import { deleteApprov } from "../../../REDUX/actions/approvisionnement";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeleteBtn from "../../Common/FormElements/DeleteBtn";
import Button from "../../Common/FormElements/Button/Button";
import { useTranslation } from "react-i18next";

function DeleteApprov({ id, packingList }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteApprov({ id, packingList }));
    dispatch(closeCenteredModal());
  };
  const closeModal = () => {
    dispatch(closeCenteredModal());
  };
  return (
    <div className="d-flex flex-column align-items-center gap-3">
      <p>
        {t("Are you sure you want to delete the supply")} #{id} ?{" "}
      </p>
      <div className="d-flex gap-3">
        <DeleteBtn onClick={handleDelete} />
        <Button onClick={closeModal}>Annuler</Button>
      </div>
    </div>
  );
}

export default DeleteApprov;
