import React, { useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import LineChartHeader from "./LineChartHeader";
import CustomLineChart from "../../Common/CustomLineChart/CustomLineChart";
import "./Synteses.css";
import { useTranslation } from "react-i18next";

const SyntheseRecettes = ({ stats }) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState("Current Revenue");
  const [dateActiveTab, setDateActiveTab] = useState(t("Year"));
  const currentYear = new Date().getFullYear();
  let years = [currentYear - 1, currentYear];

  const getCurrentStats = () => {
    switch (activeTab) {
      case "Current Revenue": {
        return stats?.totalTTCMap;
      }
      case "Net Margin": {
        return stats?.margeMap;
      }
      case "Margin Rate": {
        return stats?.txMargeMap;
      }
      default: {
        return {};
      }
    }
  };

  return (
    <div className="syntheseRecettes bg-white br5 w60 shadow26 px-4 py-3 d-flex flex-column gap-3">
      <span className="text-blue bold f22 quickSand">
        {t("Revenue Summary")}
      </span>
      <TabsNavbar
        tabs={["Current Revenue", "Net Margin", "Margin Rate"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className="justify-content-between"
        tabClassName="f14 normal"
      />

      <LineChartHeader
        years={years}
        colors={["yellowOrange", "aquaGreen"]}
        setDateActiveTab={setDateActiveTab}
        dateActiveTab={dateActiveTab}
      />
      <CustomLineChart
        years={years}
        colors={["#FCB03E", "#1AD5AF"]}
        data={getCurrentStats()}
        unity={activeTab === "Margin Rate" ? "%" : "k"}
      />
    </div>
  );
};

export default SyntheseRecettes;
