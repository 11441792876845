import React, { useEffect, useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import NewRevendeurContent from "./NewRevendeurContent";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { billingAddressForm, deliveryAddressForm, resellerForm } from "./data";
import {
  createUpdateReseller,
  getCurrentReseller,
} from "../../../REDUX/actions/reseller";
import {
  closeRightModal,
  openRightModal,
} from "../../../REDUX/actions/rightModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import UpdateTicketSav from "../../Sav/UpdateTicketSavAdmin/UpdateTicketSav";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewRevendeur({ id, savID }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { currentResellerLoading: loading, currentReseller } = useSelector(
    (state) => state?.resellerReducer
  );
  const { userRole } = useSelector((state) => state.userReducer);
  const [activeTab, setActiveTab] = useState("Informations");
  const [invalidFields, setInvalidFields] = useState([]);
  const [customErrors, setCustomErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [forms, setForms] = useState({
    infos: {},
    addresses: {
      billing: {},
      delivery: {},
    },
    affiliate: {},
  });
  const tabs = [
    "Informations",
    "Addresses",
    // , "Apporteur d'affaire"
  ];

  const location = useLocation();

  const getRenderedTabs = (tabs = []) => {
    const authoredTabs =
      userRole === "BusinessBroker"
        ? tabs.filter((tab) => tab !== "Business affiliate")
        : tabs;
    return authoredTabs;
  };

  const checkFormValidation = () => {
    const updatedInvalidFields = [];
    resellerForm?.forEach(({ name, required }) => {
      if (required) {
        if (
          forms.infos[name] === undefined ||
          forms.infos[name] === null ||
          !String(forms.infos[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    });
    if (updatedInvalidFields?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setInvalidFields(updatedInvalidFields);
  };

  const handleClick = () => {
    const phoneNumberPattern = /^(\+|\d{1,4})\s?(\d\s?){5,}$/;
    if (!forms.infos.phoneNumber.match(phoneNumberPattern)) {
      setCustomErrors({
        ...customErrors,
        phoneNumber: "phoneNumberError",
      });

      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!forms.infos.email.match(emailPattern)) {
      setCustomErrors({
        ...customErrors,
        email: "emailError",
      });
      return;
    }
    dispatch(
      createUpdateReseller({ ...forms }, currentReseller?.id, () =>
        dispatch(closeRightModal(), t)
      )
    );
  };
  useEffect(() => {
    setForms((currState) => {
      const updatedFormData = { ...currState };
      resellerForm.forEach(({ name }) => {
        updatedFormData.infos[name] = currentReseller?.attributes[name];
      });
      currentReseller?.attributes?.delivery_addresses?.data?.length > 0 &&
        deliveryAddressForm.forEach(({ name, type }) => {
          if (type === "select") {
            updatedFormData.addresses.delivery[name] = {
              name: currentReseller?.attributes?.delivery_addresses?.data[0]
                ?.attributes[name]?.data?.attributes?.name,
              code: currentReseller?.attributes?.delivery_addresses?.data[0]
                ?.attributes[name]?.data?.id,
            };
          } else {
            updatedFormData.addresses.delivery[name] =
              currentReseller?.attributes?.delivery_addresses?.data[0]?.attributes[
                name
              ];
          }
        });

      currentReseller?.attributes?.billingAddress?.data?.length > 0 &&
        billingAddressForm.forEach(({ name, type }) => {
          if (type === "select") {
            updatedFormData.addresses.billing[name] = {
              name: currentReseller?.attributes?.billingAddress?.data[0]
                ?.attributes[name]?.data?.attributes?.name,
              code: currentReseller?.attributes?.billingAddress?.data[0]
                ?.attributes[name]?.data?.id,
            };
          } else {
            updatedFormData.addresses.billing[name] =
              currentReseller?.attributes?.billingAddress?.data[0]?.attributes[
                name
              ];
          }
        });

      updatedFormData.affiliate = {
        name: `${
          currentReseller?.attributes?.business_affiliate?.data?.attributes
            ?.user?.data?.attributes?.firstName ?? ""
        } ${
          currentReseller?.attributes?.business_affiliate?.data?.attributes
            ?.user?.data?.attributes?.lastName ?? ""
        }`,
        code: currentReseller?.attributes?.business_affiliate?.data?.id,
      };

      return updatedFormData;
    });
  }, [currentReseller]);

  useEffect(() => {
    checkFormValidation();
  }, [forms]);

  useEffect(() => {
    dispatch(getCurrentReseller(id));
  }, [id, dispatch]);

  /* ******************************** Rendering ******************************* */
  return (
    <div className="newRevendeur d-flex flex-column justify-content-between h100">
      <div className="flex-1 d-flex flex-column">
        <div className="alignH align-items-end">
          {location.pathname === "/sav" && (
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              size={"xl"}
              className="ps-5 mb-5 pointer text-blue"
              onClick={() =>
                dispatch(openRightModal(<UpdateTicketSav id={savID} />))
              }
            />
          )}
          <h2
            className={`sBold f25 quickSand text-blue ${
              location.pathname === "/sav" ? "px-3" : "px-5"
            } mb-5`}
          >
            {id ? t("Modify the reseller") : t("Add new reseller")}
          </h2>
        </div>
        <TabsNavbar
          tabs={getRenderedTabs(tabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
        />
        <NewRevendeurContent
          activeTab={activeTab}
          forms={forms}
          setForms={setForms}
          customErrors={customErrors}
        />
        {activeTab !== "Addresses" && (
          <div className="buttonsContainer d-flex justify-content-center mt-auto">
            <Button variant="outline" className="me-4">
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              disabled={disabled}
              onClick={loading ? undefined : handleClick}
            >
              {t("Save")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewRevendeur;
