import React from "react";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import Button from "../Components/Common/FormElements/Button/Button";
import MembersList from "../Components/MonEquipe/MembersList/MembersList";
import NewMember from "../Components/MonEquipe/Modals/NewMember/NewMember";
import RightModal from "../Components/Modals/RightModal/RightModal";

import { useDispatch } from "react-redux";
import { openRightModal } from "../REDUX/actions/rightModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import { useTranslation } from "react-i18next";

function MonEquipeView() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const handleNewMember = () => {
    dispatch(openRightModal(<NewMember />));
  };
  return (
    <ViewScroller>
      <div className="flex-1 d-flex flex-column h-100">
        <ViewHeader title={t("My team")} className="align-items-center">
          {/* <p className="f14 text-brightGrey mb-0">SOCIETE</p> */}
          <Button
            onClick={handleNewMember}
            Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
          >
            {t("New member")}
          </Button>
        </ViewHeader>
        <MembersList />
        <RightModal />
      </div>
    </ViewScroller>
  );
}

export default MonEquipeView;
