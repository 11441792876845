import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedUser, updateUser } from "../../../../REDUX/actions/user";
import MemberInputsForm from "../../../Common/Forms/GeneralInfo/NewMemberGeneralInfo";
import LoaderWrapper from "../../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import Button from "../../../Common/FormElements/Button/Button";
import { closeRightModal } from "../../../../REDUX/actions/rightModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
function UpdateMember({ memberId }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const {
    selectedUser,
    selectedUserLoading,
    selectedUserError,
    userUpdateLoad,
  } = useSelector((state) => state.userReducer);
  const [formData, setFormData] = useState(selectedUser);
  const [triggerImg, setTriggerImg] = useState(false);

  useEffect(() => {
    dispatch(getSelectedUser(memberId));
  }, [dispatch, memberId]);

  useEffect(() => {
    setFormData(selectedUser);
  }, [selectedUser]);

  const defaultImg = selectedUser?.photo?.url;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        newPhoto: imageFile,
      }));
    }
    setTriggerImg(true);
  };
  const handleCalenderInput = (e, name) => {
    const { value } = e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleUpdateUser = async () => {
    const dataToSend = { ...formData };
    const phoneNumberPattern = /^(\+|\d{1,4})\s?(\d\s?){5,}$/;
    if (!dataToSend?.phoneNumber?.match(phoneNumberPattern)) {
      toast.error(t("Please enter a valid phone number."));
      return;
    }
    dispatch(updateUser(dataToSend, t));
  };
  const checkProfilePhoto = () => {
    return !triggerImg
      ? null
      : formData.newPhoto
      ? formData.newPhoto
      : formData.photo;
  };

  const handleCloseRightModal = () => {
    dispatch(closeRightModal());
  };

  return (
    <div className="updateMemberModal d-flex flex-column justify-content-between h-100">
      <h1 className="quickSand rightModalTitle px-5">{t("Edit member")}</h1>
      <div className="d-flex flex-column justify-content-between h-100">
        <LoaderWrapper loading={selectedUserLoading} error={selectedUserError}>
          <MemberInputsForm
            handleChange={handleChange}
            handleImageChange={handleImageChange}
            userImage={checkProfilePhoto()}
            handleCalenderInput={handleCalenderInput}
            formData={formData}
            defaultImgUser={defaultImg}
          />
        </LoaderWrapper>
      </div>
      <div className="alignH align-self-center">
        <Button
          type="button"
          variant="outline"
          className="mx-2 px-5"
          onClick={handleCloseRightModal}
        >
          {t("Cancel")}
        </Button>
        <Button
          type="button"
          className="mx-2"
          onClick={handleUpdateUser}
          loading={userUpdateLoad}
        >
          {t("Edit")}
        </Button>
        {/* <Button type="button" className="mx-2 bg-softBlue">
          Envoyer les accès
        </Button> */}
      </div>
    </div>
  );
}

export default UpdateMember;
