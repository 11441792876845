import React, { useEffect, useState } from "react";
import ProductAttachementItem from "./ProductAttachementItem";
import AddAttachment from "../../Common/Forms/Attachements/AddAttachment";
import { useTranslation } from "react-i18next";

const DocumentsProduct = ({ handleFormsChange, initialValues }) => {
  const { t } = useTranslation("common");
  const [attachments, setAttachments] = useState(initialValues ?? []);

  useEffect(() => {
    handleFormsChange("documents", attachments);
  }, [attachments]);

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between ms-4 py-2">
          <p className="f12 mb-0 text-brightGrey normal">
            {t("Upload PDF or Word files with a maximum size of 150 KB")}
          </p>
        </div>
        <div className="listHeader d-flex align-items-center bg-dimGrey p-3 mt-3">
          <p className="f12 sBold text-brightGrey mb-0 w40">
            {t("Document title")}
          </p>
          <p className="f12 sBold text-brightGrey mb-0 w40">{t("File")}</p>
        </div>
        <div className="attachmentsList">
          {attachments?.map((document) => {
            return (
              <ProductAttachementItem
                key={document?.id}
                id={document?.id}
                title={document?.title}
                icon={document?.icon}
                docType={document?.docType}
                file={document?.file}
                fileUrl={document?.fileUrl}
                fileName={document?.fileName}
                newFileName={document?.file?.name}
                attachments={attachments}
                setAttachments={setAttachments}
              />
            );
          })}
        </div>
        <AddAttachment
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </div>
      {/* <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="px-5"
          disabled={disabled}
          loading={loading}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </>
  );
};

export default DocumentsProduct;
