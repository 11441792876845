import React, { useEffect, useState } from "react";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import Select from "../Common/FormElements/Select/Select";
import TextArea from "../Common/FormElements/TextArea/TextArea";
import CalendarInput from "../Common/FormElements/CalendarInput/CalendarInput";
import Button from "../Common/FormElements/Button/Button";
import {
  formLabels,
  formDataInitialValues,
} from "../../helpers/formLabels/newProspectFormLabels";
import checkFormDataValidity from "../../Utils/checkFormDataValidity";
import { useDispatch, useSelector } from "react-redux";
import { createProspect } from "../../REDUX/actions/prospects";
import { checkEmail } from "../../Utils/ErrorHandling/checkEmail";
import { toast } from "react-toastify";
import { checkPhone } from "../../Utils/ErrorHandling/checkPhone";
import { getCountries } from "../../REDUX/actions/country";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NewProspect = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { prospectsLoading } = useSelector((state) => state.prospectsReducer);
  const { countries } = useSelector((state) => state.countryReducer);

  const [validForm, setValidForm] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [formData, setFormData] = useState(formDataInitialValues);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkEmail(formData.email)) {
      toast.error(t("Invalid email!"));
    } else if (!checkPhone(formData.phoneNumber)) {
      toast.error(t("Invalid phone number!"));
    } else {
      dispatch(
        createProspect({
          ...formData,
        })
      );
    }
  };
  useEffect(() => {
    if (checkFormDataValidity(formData)) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [formData]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    setFetchedData((fetchedData) => ({ ...fetchedData, country: countries }));
  }, [countries]);
  return (
    <form
      className="d-flex flex-column justify-content-between h100 px-5"
      onSubmit={handleSubmit}
    >
      <div>
        <span className="rightModalTitle quickSand mb-4">
          {t("New prospect")}
        </span>
        <div className="mt-4">
          {formLabels?.map((formRow) => (
            <Row>
              {formRow.map((el) => {
                if (el?.type === "select") {
                  let options = [];
                  if (el?.subOption && formData[el?.subOption]) {
                    options = (fetchedData[el?.subOption]?.find(
                      (item) => item?.code === formData[el?.subOption]?.code
                    ) || {})[el?.optionsName];
                  } else {
                    options = fetchedData[el?.name]?.map((item) => ({
                      name: item?.name,
                      code: item?.code,
                    }));
                  }
                  return (
                    <Col lg={el?.span ?? 6} key={el.name} className="mb-4">
                      <p className="f16 text-ultraBlack mb-2">
                        {t(`${el.label}`)}
                      </p>
                      <Select
                        name={el.name}
                        value={formData[el.name]}
                        options={options}
                        label={t(`${el.label}`)}
                        disabled={el?.subOption && !options?.length > 0}
                        callback={handleChange}
                      />
                    </Col>
                  );
                }
                if (el?.type === "calender") {
                  return (
                    <>
                      <Col lg={el?.span ?? 6} key={el.name}>
                        <p className="f16 text-ultraBlack mb-2">
                          {t(`${el.label}`)}
                        </p>
                        <CalendarInput
                          {...el}
                          placeholder={t(`${el.placeholder ?? ""}`)}
                          width={"100%"}
                          className="b-2-softGrey mb-4"
                          withMinDate={false}
                          callback={handleChange}
                          value={formData[el?.name]}
                        />
                      </Col>
                      <hr
                        style={{ borderColor: "#abb8b2" }}
                        width={"100%"}
                        className="mb-3"
                      />
                    </>
                  );
                }
                return (
                  <Col lg={el?.span ?? 6} key={el.name} className="mb-4">
                    <p className="f16 text-ultraBlack mb-2">
                      {t(`${el.label}`)}
                    </p>
                    <TextInput
                      className={"w100"}
                      type={el.type}
                      name={el.name}
                      value={formData[el.name]}
                      callback={handleChange}
                    />
                  </Col>
                );
              })}
            </Row>
          ))}
          <div className="mb-3">
            <p className="f16 text-ultraBlack mb-2">{t("Comment")}</p>
            <TextArea
              name="comment"
              value={formData?.comment ?? ""}
              callback={handleChange}
              rows={4}
              placeholder={""}
            />
          </div>
        </div>
      </div>
      {/* <div className="colAlignC align-items-start gap-2">
        <span className="text-softBlack f16 normal">Description</span>
        <TextArea className={"w-100 b-2-softGrey"} rows={3} />
      </div> */}
      <Button
        className="max-fit-content mx-auto"
        isSubmit={true}
        disabled={!validForm}
        loading={prospectsLoading}
      >
        {t("Add a prospect")}
      </Button>
    </form>
  );
};

export default NewProspect;
