import React, { useEffect, useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import ContainerContent from "./ContainerContent";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import { formLabelsData } from "../../../helpers/fakeData/Commandes";
import {
  getOneApprov,
  updateApprov,
} from "../../../REDUX/actions/approvisionnement";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import { useTranslation } from "react-i18next";

const ModifyContainer = ({ id, initalTab = "General information" }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { approvError, approvLoading, approvReload, approv } = useSelector(
    (state) => state.approvReducer
  );
  const [activeTab, setActiveTab] = useState(initalTab);
  const [disabled, setDisabled] = useState(true);
  const [transportExceeded, setTransportExceeded] = useState(false);

  const [forms, setForms] = useState({
    container: {},
    packingList: [],
    payments: [],
    attachments: [],
  });

  const checkFormValidation = () => {
    let updatedInvalidFields = [];
    for (const formRow of formLabelsData) {
      for (const field of formRow) {
        const { name, type, required } = field;
        if (required) {
          if (type === "select") {
            if (!forms.container[name]?.code) {
              updatedInvalidFields.push(name);
            }
          } else if (
            forms.container[name] === undefined ||
            forms.container[name] === null ||
            !String(forms.container[name])?.trim()?.length > 0
          ) {
            updatedInvalidFields.push(name);
          }
        }
      }
    }
    if (!forms.packingList.length) {
      updatedInvalidFields.push("packingList");
    }
    if (!forms?.packingList?.every((item) => item?.deliveredCost > 0)) {
      updatedInvalidFields.push("packingList");
    }
    if (updatedInvalidFields?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleSubmit = () => {
    dispatch(updateApprov({ id: id, ...forms }, t));
  };

  useEffect(() => {
    dispatch(getOneApprov({ id }));
  }, [dispatch, id, approvReload]);

  useEffect(() => {
    checkFormValidation();
  }, [forms?.container, forms?.packingList]);

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_CURR_APPROV" });
    };
  }, [dispatch]);

  // return;

  return (
    <div className="modifyOrder d-flex flex-column justify-content-between h100">
      <div>
        <div className="d-flex align-items-baseline gap-2 px-5 mb-5">
          <h2 className="sBold f25 quickSand text-blue ">
            {t("Edit container")}
          </h2>
          {approv?.entrepot && (
            <img src="entrepot.png" alt="entrepot" width={"23"} />
          )}
        </div>
        <TabsNavbar
          tabs={["General information", "Packing list", "Comment"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
        />
        <ShadowLoader loading={approvLoading} error={approvError}>
          <ContainerContent
            update={true}
            activeTab={activeTab}
            forms={forms}
            setForms={setForms}
            setTransportExceeded={setTransportExceeded}
          />
        </ShadowLoader>
        {activeTab !== "Comment" && (
          <>
            {transportExceeded && (
              <p className="f14 text-red text-center mb-0">
                {t(
                  "You have exceeded the total delivery price; please remove a model from the 'Packing List'!"
                )}
              </p>
            )}
            <div
              className={`buttonsContainer d-flex justify-content-center mt-${
                transportExceeded ? "3" : "5"
              }`}
            >
              <Button
                variant="outline"
                className="me-4"
                onClick={() => dispatch(closeRightModal())}
              >
                {t("Cancel")}
              </Button>
              <Button
                disabled={disabled || transportExceeded}
                onClick={handleSubmit}
                loading={approvLoading}
              >
                {t("Save")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModifyContainer;
