const initialState = {
  ticketSAVs: null,
  ticketSAVsLoading: false,
  ticketSAVsError: null,
  ticketSAVsReload: true,
  ticketSAV: null,
  ticketSAVLoading: false,
  ticketSAVError: null,
  ticketSAVReload: null,
  savProducts: [],
  savProductsLoading: false,
  savProductsError: null,
  savRevendeurs: [],
  savRevLoading: false,
  savRevendeursError: null,
  filtersLists: {},
  currentComments: [],
  currentCommentsLoading: false,
  currentAdminComments: [],
  currentAdminCommentsLoading: false,
  createCommentLoading: false,
};

const ticketSAVReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "TICKETS_SAV_LOADING":
      return {
        ...state,
        ticketSAVsLoading: true,
      };
    case "TICKETS_SAV_FETCHED":
      return {
        ...state,
        ticketSAVsLoading: false,
        ticketSAVs: payload,
      };
    case "TICKETS_SAV_ERROR":
      return {
        ...state,
        ticketSAVsLoading: false,
        ticketSAVsError: payload,
      };
    case "TICKETS_SAV_CREATED":
      return {
        ...state,
        ticketSAVsLoading: false,
      };
    case "TICKET_SAV_LOADING":
      return {
        ...state,
        ticketSAVLoading: true,
      };
    case "TICKET_SAV_FETCHED":
      return {
        ...state,
        ticketSAVLoading: false,
        ticketSAV: payload,
      };
    case "TICKET_SAV_ERROR":
      return {
        ...state,
        ticketSAVLoading: false,
        ticketSAVError: payload,
      };
    case "TICKET_SAV_UPDATED":
    case "TICKET_SAV_DELETED":
      return {
        ...state,
        ticketSAVLoading: false,
      };

    case "SAV_PRODUCTS_LOADING":
      return {
        ...state,
        savProductsLoading: true,
      };
    case "SAV_PRODUCTS_FETCHED":
      return {
        ...state,
        savProductsLoading: false,
        savProducts: payload,
      };
    case "SAV_PRODUCTS_ERROR":
      return {
        ...state,
        savProductsLoading: false,
        savProductsError: payload,
      };

    case "SAV_REVENDEURS_LOADING":
      return {
        ...state,
        savRevLoading: true,
      };
    case "SAV_REVENDEURS_FETCHED":
      return {
        ...state,
        savRevLoading: false,
        savRevendeurs: payload,
      };
    case "SAV_REVENDEURS_ERROR":
      return {
        ...state,
        savRevLoading: false,
        savRevendeursError: payload,
      };

    case "TICKETS_SAV_RELOAD":
      return {
        ...state,
        ticketSAVsReload: !state.ticketSAVsReload,
      };
    case "TICKET_SAV_RELOAD":
      return {
        ...state,
        ticketSAVReload: !state.ticketSAVReload,
      };
    case "FILTERS_FETCHED":
      return {
        ...state,
        filtersLists: payload,
      };
    case "CREATE_COMMENTS_SAV_LOADING":
      return { ...state, createCommentLoading: true };

    case "CREATE_COMMENTS_SAV_SUCCESS":
      return {
        ...state,
        createCommentLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_SAV_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_SAV_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };

    case "CREATE_ADMIN_COMMENTS_SAV_LOADING":
      return { ...state, createCommentLoading: true };

    case "CREATE_ADMIN_COMMENTS_SAV_SUCCESS":
      return {
        ...state,
        createCommentLoading: false,
        currentAdminComments: [payload, ...state.currentAdminComments],
      };

    case "FETCH_ADMIN_COMMENTS_SAV_LOADING":
      return { ...state, currentAdminCommentsLoading: true };

    case "FETCH_ADMIN_COMMENTS_SAV_SUCCESS":
      return {
        ...state,
        currentAdminComments: payload,
        currentAdminCommentsLoading: false,
      };
    default:
      return state;
  }
};

export default ticketSAVReducer;
