import React, { useEffect, useState } from "react";
// import DownloadButton from "../../ListHeaderButtons/DownloadButton";
import DeleteButton from "../../ListHeaderButtons/DeleteButton";
import CheckBox from "../../FormElements/CheckBox/CheckBox";
import AttachmentItem from "./AttachmentItem/AttachmentItem";
import AddAttachment from "./AddAttachment";
import { deleteAttachements } from "../../../../REDUX/actions/products";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Attachements = ({
  attachments,
  setAttachments,
  fromNewProduct,
  fromGuide,
  fromContainer,
  fromDepense,
  acceptTypes,
  activeSwitch,
  setActiveSwitch,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    if (checkAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(attachments?.map((attach) => attach?.id));
    }
  };
  const handleDeleteSelected = () => {
    let leftItems = attachments.filter((attach) => {
      const isFound = !!selectedItems.find((item) => item === attach?.id);
      if (!isFound) {
        return true;
      }
      return false;
    });

    setCheckAll(false);
    setSelectedItems([]);
    setAttachments(leftItems);
  };

  const deleteAddedAttachements = () => {
    const attachementsToDelete = attachments?.filter((attach) =>
      selectedItems?.find((id) => attach?.id === id)
    );
    dispatch(
      deleteAttachements(attachementsToDelete, () => handleDeleteSelected())
    );
  };
  useEffect(() => {
    if (
      selectedItems?.length === attachments?.length &&
      attachments?.length > 0
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [selectedItems, attachments]);
  return (
    <div className={`${!fromNewProduct && "px-5"} pb-3`}>
      <div className="d-flex align-items-center justify-content-between ms-4">
        <p className="f12 mb-0 text-brightGrey normal">
          {t("File upload max size 15 MB")}
        </p>

        {!fromContainer && !fromDepense && (
          <div className="listHeaderButtons d-flex">
            {/* <DownloadButton /> */}
            <DeleteButton
              callback={deleteAddedAttachements}
              disabled={!selectedItems?.length > 0}
            />
          </div>
        )}
      </div>
      <div className="listHeader d-flex align-items-center bg-dimGrey p-3 mt-3">
        {!fromContainer && !fromDepense && (
          <CheckBox
            className="me-2"
            width={17}
            height={17}
            checked={checkAll}
            initialState={checkAll}
            callback={handleCheckAll}
          />
        )}
        <p className="f12 text-brightGrey mb-0 w30 sBold">
          {fromDepense ? t("Invoice title") : t("Document title")}
        </p>
        <p
          className={`f12 text-brightGrey mb-0 ${
            // fromNewProduct ? "w20" :
            "w30"
          } sBold`}
        >
          {t("Document")}
        </p>
        {!fromContainer && !fromDepense && (
          <p className="f12 text-brightGrey mb-0 w20 sBold">{t("Date")}</p>
        )}
        {/* {fromNewProduct && (
          <p className="f12 text-brightGrey mb-0 w20 sBold">Image principale</p>
        )} */}
      </div>
      <div className="attachmentsList">
        {attachments.map(
          ({ id, title, date, file, fileName, fileUrl, mediaType }) => {
            return (
              <AttachmentItem
                key={id}
                id={id}
                title={title ?? ""}
                date={date}
                checkAll={checkAll}
                fileName={file?.name ?? fileName}
                file={file}
                fileUrl={fileUrl}
                mediaType={mediaType}
                attachments={attachments}
                setAttachments={setAttachments}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                fromContainer={fromContainer}
                fromDepense={fromDepense}
                acceptTypes={acceptTypes}
                fromNewProduct={fromNewProduct}
                activeSwitch={activeSwitch}
                setActiveSwitch={setActiveSwitch}
              />
            );
          }
        )}
      </div>
      {!fromDepense && (
        <AddAttachment
          attachments={attachments}
          setAttachments={setAttachments}
          fromNewProduct={fromNewProduct}
          fromGuide={fromGuide}
        />
      )}
    </div>
  );
};

export default Attachements;
