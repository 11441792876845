import { toast } from "react-toastify";
import API from "../../Api/Api";

import { closeCenteredModal } from "./centeredModal";
import NEW_API from "../../Api/NewApi";
import { refetchReseller } from "./generalData";
import { updateCart } from "./catalogue";

export const updateAdress =
  (addressId, payload, resellerId, callBack, t) => async (dispatch) => {
    console.log({ payload });

    let cityId = payload.city.code;

    if (!cityId) {
      cityId = await createCity(payload);
    } else {
      await updateCity(cityId, payload);
    }

    try {
      await API.put(`api/addresses/${addressId}`, {
        data: {
          name: payload.name,
          street: payload.street,
          zip: payload.zip,
          country: payload.country.code,
          city: cityId,
        },
      });
      callBack ? callBack() : dispatch(refetchReseller(resellerId));

      dispatch(closeCenteredModal());

      toast.success(t("Delivery address successfully updated"));
    } catch (error) {
      console.log(error);
      dispatch({ type: "PANIER_ERROR", payload: error });
    }
  };

// export const getDeliveries = (payload) => async (dispatch) => {};

const createCity = async (payload) => {
  console.log({ payload });
  const newCity = await NEW_API.post("/api/cities", {
    data: {
      name: payload.city,
      country: payload.country.code,
      zipCode: payload.zip,
      code: payload?.code,
    },
  });
  return newCity.data.data.id;
};
const updateCity = async (cityId, payload) => {
  const newCity = await NEW_API.put(`/api/cities/${cityId}`, {
    data: {
      name: payload.city.name,
      country: payload.country.code,
      zipCode: payload.zip,
      code: payload.code,
    },
  });
  return newCity.data.data.id;
};

export const createNewDeliveryAddress =
  (payload, resellerId, type, cart, callBack, t) => async (dispatch) => {
    //LINK IT ON ADRESSE DE LIVRAISON OU FACTURATION

    console.log({ payload });
    const relationType =
      type === "addresseDeLivraison"
        ? { reseller: resellerId || null }
        : { resellerBillings: resellerId || null };
    let cityId = payload.city.code;
    if (!cityId) {
      cityId = await createCity(payload);
    } else {
      await updateCity(cityId, payload);
    }

    try {
      const createdAdress = await API.post(`api/addresses`, {
        data: {
          name: payload.name,
          street: payload.street,
          zip: payload.zip,
          country: payload.country.code,
          city: cityId,
          ...relationType,
        },
      });
      // dispatch(getCurrentReseller(resellerId));if
      if (resellerId) {
        dispatch(refetchReseller(resellerId));
      }
      if (callBack) callBack();
      if (cart.particulier) {
        const particulierAdress =
          type === "addresseDeLivraison"
            ? {
                addresseDeLivraison: createdAdress.data.data.id || null,
                facture: null,
                bon_de_commande: null,
              }
            : {
                addresseDeFacturation: createdAdress.data.data.id || null,
                facture: null,
                bon_de_commande: null,
              };
        dispatch(updateCart(cart.id, particulierAdress));
      }

      dispatch(closeCenteredModal());

      toast.success(t("Delivery address successfully created"));
    } catch (error) {
      toast.error(t("An error has occurred!"));
      console.log(error);
    }
  };
