import React from "react";
import { useSelector } from "react-redux";
import PackingGroupList from "../Step2/PackingGroupList";
import { useTranslation } from "react-i18next";

const Step2Catalogue = ({ searchInput, activeTab, productsactiveTab }) => {
  const { t } = useTranslation("common");
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div>
      <h2 className="bold text-softBlue">
        {t("Product list")} {cart.totalNumberOfPackings}
      </h2>
      <PackingGroupList
        activeTab={activeTab}
        searchInput={searchInput}
        productsactiveTab={productsactiveTab}
      />
    </div>
  );
};

export default Step2Catalogue;
