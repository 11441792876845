import React, { useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import LineChartHeader from "./LineChartHeader";
import CustomLineChart from "../../Common/CustomLineChart/CustomLineChart";
import { useTranslation } from "react-i18next";

const SyntheseDepenses = ({ stats }) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState("Expenses");

  const [dateActiveTab, setDateActiveTab] = useState("Year");
  const currentYear = new Date().getFullYear();
  let years = [currentYear - 1, currentYear];

  return (
    <div className="bg-white br5 w40 shadow26 px-4 py-3 d-flex flex-column gap-3">
      <span className="text-blue bold f22 quickSand">
        {t("Revenue Summary")}
      </span>
      <TabsNavbar
        tabs={["Expenses"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className="justify-content-between "
        tabClassName="f14 normal me-0"
      />
      <LineChartHeader
        colors={["wildStrawberry", "turquoise"]}
        years={years}
        setDateActiveTab={setDateActiveTab}
        dateActiveTab={dateActiveTab}
      />
      <CustomLineChart
        colors={["#FC43AC", "#1eb1d8"]}
        years={years}
        data={stats}
      />
    </div>
  );
};

export default SyntheseDepenses;
