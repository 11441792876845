import React from "react";
import { useTranslation } from "react-i18next";

const InProgressTag = () => {
  const { t } = useTranslation("common");
  return (
    <span className="sBold f12 br8 py-2 px-3 text-center bg-lightOrange text-orange">
      {t("Pending")}
    </span>
  );
};
const CompletedTag = () => {
  const { t } = useTranslation("common");
  return (
    <span className="sBold f12 br8 py-2 px-3 text-center bg-green text-green">
      {t("Resolved")}
    </span>
  );
};
const CanceledTag = () => {
  const { t } = useTranslation("common");
  return (
    <span className="sBold f12 br8 py-2 px-3 text-center bg-redCalendar text-brightRed">
      {t("Canceled")}
    </span>
  );
};

function TicketStatusTag({ status }) {
  switch (status) {
    case 1:
      return <InProgressTag />;
    case 2:
      return <CompletedTag />;
    case 3:
      return <CanceledTag />;
    default:
      return <InProgressTag />;
  }
}

export default TicketStatusTag;
