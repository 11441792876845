export const formLabelsNewDep = [
  [
    {
      label: "Expense title",
      type: "text",
      placeholder: "Title",
      name: "title",
      required: true,
    },
    {
      label: "Supplier",
      type: "text",
      placeholder: "Supplier",
      name: "vendor",
      required: true,
    },
    {
      label: "Type",
      type: "input",
      placeholder: "Type",
      name: "type",
      required: true,
    },
  ],
  [
    {
      label: "Date",
      type: "calendar",
      placeholder: "Date",
      name: "date",
      required: true,
    },
    {
      label: "Amount incl. tax",
      type: "number",
      placeholder: "Amount incl. tax",
      euro: true,
      name: "amountTTC",
      required: false,
    },
    {
      label: "VAT 20%",
      type: "number",
      placeholder: "VAT",
      euro: true,
      name: "TVA",
      required: false,
      disabled: false,
    },
  ],
  [
    {
      label: "Amount excl. tax",
      type: "number",
      placeholder: "Amount excl. tax",
      euro: true,
      name: "amountHT",
      required: true,
    },
    {
      label: "Payment date",
      type: "calendar",
      placeholder: "Date",
      name: "paymentDate",
      required: false,
    },
  ],
  [
    {
      label: "Payment 1",
      type: "number",
      placeholder: "Payment",
      euro: true,
      name: "payment_1",
      required: false,
    },
    {
      label: "Payment 2",
      type: "number",
      placeholder: "Payment",
      euro: true,
      name: "payment_2",
      required: false,
    },
    {
      label: "Payment 3",
      type: "number",
      placeholder: "Payment",
      euro: true,
      name: "payment_3",
      required: false,
    },
  ],
];
