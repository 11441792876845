import qs from "qs";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";

export const fetchEntrepots = () => async (dispatch) => {
  dispatch({ type: "FETCH_ENTREPOTS_LOADING" });
  try {
    const { data } = await API.get(`${API_END_POINT}api/entrepots?populate=*`);
    dispatch({ type: "FETCH_ENTREPOTS_SUCCESS", payload: data?.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_ENTREPOTS_ERROR", payload: error });
  }
};

export const fetchPackingsFromEntrepot = (id) => async (dispatch) => {
  dispatch({ type: "FETCH_ENTREPOT_PACKINGS_LOADING" });
  try {
    const query = qs.stringify({
      filters: {
        entrepot: {
          id: { $eq: id },
        },
      },
    });
    const { data } = await API.get(
      `${API_END_POINT}api/packings?populate=*&${query}`
    );
    dispatch({ type: "FETCH_ENTREPOT_PACKINGS_SUCCESS", payload: data?.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_ENTREPOT_PACKINGS_ERROR", payload: error });
  }
};
