import React, { useState } from "react";
import Select from "../../Common/FormElements/Select/Select";
import {
  getStatusSelectValue,
  orderPayStatus,
  orderStatus,
} from "../../../helpers/formLabels/orderStatus";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import { updateCommande } from "../../../REDUX/actions/commandes";
import { useTranslation } from "react-i18next";

function ModifyStatusDialog({ id, status, number, isPayment }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(
    getStatusSelectValue(status),
  );

  const handleSubmit = () => {
    dispatch(
      updateCommande(
        id,
        isPayment
          ? { payStatus: selectedStatus.code }
          : { status: selectedStatus.code },
      ),
    );

    dispatch(closeCenteredModal());
  };

  return (
    <div>
      <p className="f15 sBold">
        {t("Order status")} #{number} :
      </p>
      <Select
        options={
          isPayment
            ? orderPayStatus.map(({ name, code }) => ({
                code,
                name: t(`${name}`),
              }))
            : orderStatus.map(({ name, code }) => ({
                code,
                name: t(`${name}`),
              }))
        }
        placeholder={t("Select")}
        value={selectedStatus}
        callback={(e) => setSelectedStatus(e.value)}
        className="mb-4"
      />
      <div className="d-flex justify-content-center gap-3">
        <Button onClick={handleSubmit}>{t("Save")}</Button>
        <Button onClick={() => dispatch(closeCenteredModal())}>
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}

export default ModifyStatusDialog;
