import React, { useEffect, useState } from "react";
import CommissionItem from "./CommissionItem";
import { useDispatch, useSelector } from "react-redux";
import { deleteAffiliateComissions } from "../../../REDUX/actions/affiliate";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";

function Commission({ handleFormsChange, initialValues }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { currentComissions } = useSelector((state) => state?.affiliateReducer);
  const [formData, setFormData] = useState(initialValues);
  const { ranges } = useSelector((state) => state.rangeReducer);

  const [rangeOptions, setRangeOptions] = useState([]);

  const addField = () => {
    setFormData([...formData, {}]);
  };

  const removeField = (index) => () => {
    const id = currentComissions[index]?.id;
    if (id !== undefined && id !== null) {
      dispatch(deleteAffiliateComissions(id, currentComissions));
    }
    setFormData((currState) => {
      const updatedFormData = currState?.filter((_, i) => i !== index);
      return updatedFormData;
    });
  };

  const handleChange = (index) => (e) => {
    const { value, name } = e.target;
    setFormData((currState) => {
      const updatedFormData = [...currState];
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });
    // checkFormValidation();
  };

  // const handleClick = () => {
  //   if (!disabled) {
  //     dispatch(
  //       createAffiliateComissions(
  //         formData,
  //         currentAffiliate?.informations.id,
  //         currentComissions?.map((item) => item?.id)
  //       )
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (currentComissions?.length > 0) {
  //     setFormData(
  //       currentComissions?.map((item) => ({
  //         value: item?.attributes?.value,
  //         range: {
  //           name: item?.attributes?.range?.data?.attributes?.name,
  //           code: convertedByType(item?.attributes?.range?.data?.id),
  //         },
  //       }))
  //     );
  //   }
  // }, [currentComissions]);

  useEffect(() => {
    handleFormsChange("comissions", formData);
  }, [formData]);

  useEffect(() => {
    setRangeOptions(ranges?.map((item) => omit(item, "models")));
  }, [ranges]);

  return (
    <>
      <div className="modalContent px-5 pt-4">
        <div className="listHeader d-flex align-items-center bg-dimGrey p-3 mt-3">
          <p className="f14 text-brightGrey mb-0 w60 sBold">{t("Range")}</p>
          <p className="f14 text-brightGrey mb-0 w30 sBold">
            {t("Commission")}
          </p>
        </div>
        {formData?.map((item, index) => (
          <CommissionItem
            value={item?.value}
            rangeValue={item?.range}
            key={item?.range?.code}
            rangeName="range"
            options={rangeOptions}
            onChange={handleChange(index)}
            removeField={removeField(index)}
          />
        ))}
        <button
          onClick={addField}
          className="addAttachment underline ms-3 mt-3 f12 text-softBlue border-0 bg-transparent"
        >
          + {t("Add a commission")}
        </button>
      </div>
      {/* <div className="buttonsContainer d-flex justify-content-center mt-auto pb-3">
        <Button
          variant="outline"
          className="me-4"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="me-4"
          disabled={disabled}
          loading={currentAffiliateLoading}
          onClick={handleClick}
        >
          Enregistrer
        </Button>
        <Button variant="lightBlue">Envoyer les accés</Button>
      </div> */}
    </>
  );
}

export default Commission;
