import React from "react";
import { getCuvePanelListImg } from "../getPackingSelectValue";
import adjustPrice from "../../../../Utils/AdjustPrice";
import { useTranslation } from "react-i18next";

function ApprovDetailsItem({
  model,
  reference,
  cuve,
  panel,
  serialNumber,
  buyingPrice,
  buyingCost,
  transport,
  deliveredCost,
  qc,
  quantity,
}) {
  const { t, i18n } = useTranslation("common");
  console.log(model);
  return (
    <div className="approvDetailsItemContainer">
      <div className="approvDetailsItem row gx-0 py-3 alignH">
        <span className={`col-3 normal px-0 f14 text-brightGrey`}>
          {i18n.language === "en"
            ? model?.data?.attributes?.name_en ?? model?.data?.attributes?.name
            : model?.data?.attributes?.name}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey`}>
          {reference ?? "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey`}>
          {cuve ? t(`${cuve}`) : "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey`}>
          {panel ? t(`${panel}`) : "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {serialNumber ?? "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {buyingPrice
            ? adjustPrice((buyingPrice ?? 0).toFixed(2).toString()) + " €"
            : "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {(qc ??= "-")}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {buyingCost
            ? adjustPrice((buyingCost ?? 0).toFixed(2).toString()) + " €"
            : "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {transport
            ? adjustPrice((transport ?? 0).toFixed(2).toString()) + " €"
            : "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {deliveredCost
            ? adjustPrice(
                ((deliveredCost + transport) * (quantity ?? 1) ?? 0)
                  .toFixed(2)
                  .toString()
              ) + " €"
            : "-"}
        </span>
      </div>
      <hr
        style={{
          border: `1px solid white`,
          opacity: 0.8,
          width: "97%",
          margin: "auto",
        }}
      />
    </div>
  );
}

export default ApprovDetailsItem;
