import React, { useEffect, useState } from "react";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  generateInvoice,
  updateFacture,
} from "../../../REDUX/actions/catalogue";
import { saveAs } from "file-saver";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { useTranslation } from "react-i18next";
const Step4Facture = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { cart, cartLoading } = useSelector((state) => state.catalogueReducer);
  const [factureDataIncomplete, setfactureDataIncomplete] = useState(true);

  const [factureNumber, setfactureNumber] = useState(
    cart.facture?.number || null
  );
  useEffect(() => {
    setfactureDataIncomplete(
      cart.particulier
        ? !cart.addresseDeLivraison ||
            !cart.addresseDeFacturation ||
            !cart.deliveryOptionName ||
            !cart.particulierName
        : !cart.addresseDeLivraison ||
            !cart.addresseDeFacturation ||
            !cart.deliveryOptionName ||
            !cart.reseller
    );
  }, [cart]);
  const [factureDate, setfactureDate] = useState(cart.facture?.date || null);
  useEffect(() => {
    if (cart) {
      setfactureNumber(cart.facture?.number || null);
      setfactureDate(cart.facture?.date || null);
    }
  }, [cart]);

  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <div className="d-flex gap-5">
        <h4 className="mt-4 text-softBlue bold">4- {t("Order details")} :</h4>
      </div>

      {cart.facture && (
        <>
          <div className="mt-3 alignH gap-2">
            <div>{t("Order number")} : </div>
            <input
              className="refClientItem p-1"
              defaultValue={factureNumber}
              onChange={(e) => setfactureNumber(e.target.value)}
            />
          </div>
          <div className="mt-3 alignH gap-2">
            <div>{t("Invoice date")} : </div>
            <input
              className="refClientItem p-1"
              type="date"
              defaultValue={factureDate}
              onChange={(e) => setfactureDate(e.target.value)}
            />
          </div>

          <div
            className="f12 underline cursor-pointer w100 m-auto"
            onClick={() =>
              saveAs(
                `${IMAGE_END_POINT}${cart.facture.media.url}`,
                `${t("Invoice")}-${cart.facture?.number} ${t("Order")}-${
                  cart.number
                }`
              )
            }
          >
            {t("Download invoice")}
          </div>
        </>
      )}
      <div className="w40 m-auto">
        <Button
          loading={cartLoading}
          className="w100 my-3 bold"
          disabled={factureDataIncomplete}
          onClick={() => {
            // console.log(cart);
            // console.log({ factureNumber, factureDate });
            if (cart?.facture) {
              dispatch(
                updateFacture(
                  cart.facture.id,
                  { number: factureNumber, date: factureDate },
                  cart.id,
                  t
                )
              );
            } else {
              dispatch(generateInvoice(cart?.id, t));
            }
          }}
        >
          {cart.facture ? t("Validate the changes") : t("Generate the invoice")}
        </Button>
      </div>
    </div>
  );
};

export default Step4Facture;
