import React, { useState } from "react";

import ViewScroller from "../../Common/ViewScroller/ViewScroller";
import RightModal from "../../Modals/RightModal/RightModal";
import ApprovViewContent from "./ApprovViewContent";
import CommandesApproViewHeader from "../CommandesApproViewHeader";

const ApprovTabContent = ({ activeTab }) => {
  const [searchInput, setSearchInput] = useState("");
  const [filtersInput, setFiltersInput] = useState({
    year: null,
    month: null,
    factory: null,
  });

  return (
    <ViewScroller>
      <CommandesApproViewHeader
        activeTab={activeTab}
        setSearchInput={setSearchInput}
        filtersInput={filtersInput}
        setFiltersInput={setFiltersInput}
        searchInput={searchInput}
      />
      <ApprovViewContent
        searchInput={searchInput}
        filtersInput={filtersInput}
        setSearchInput={setSearchInput}
      />
      <RightModal width={85} className="pb-4" />
    </ViewScroller>
  );
};

export default ApprovTabContent;
