import React, { useEffect, useMemo, useState } from "react";
import MessageInput from "./NewTicketSav/ChatContainer/MessageInput";
import { useDispatch, useSelector } from "react-redux";
import { createCommentSAV, getComments } from "../../REDUX/actions/ticketSAV";
import MessageItem from "./NewTicketSav/ChatContainer/MessageItem";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";

const SavChatMessages = ({ isAdmin }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const {
    ticketSAV,
    currentAdminComments,
    currentComments,
    currentCommentsLoading,
    currentAdminCommentsLoading,
    createCommentLoading,
  } = useSelector((state) => state.ticketSAVReducer);
  const [newMessage, setNewMessage] = useState({ text: "", attachments: [] });
  const comments = useMemo(() => {
    return {
      list: isAdmin ? currentAdminComments : currentComments,
      loading: isAdmin ? currentAdminCommentsLoading : currentCommentsLoading,
    };
  }, [
    currentAdminComments,
    currentCommentsLoading,
    currentAdminCommentsLoading,
    currentComments,
    isAdmin,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attachments") {
      setNewMessage({
        ...newMessage,
        [name]:
          newMessage?.attachments?.length > 0
            ? [...newMessage?.attachments, ...value]
            : value,
      });
    } else {
      setNewMessage({ ...newMessage, [name]: value });
    }
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    if (
      newMessage?.text?.trim()?.length > 0 ||
      newMessage?.attachments?.length > 0
    ) {
      dispatch(
        createCommentSAV(
          newMessage,
          ticketSAV?.id,
          isAdmin,
          user?.id,
          () => {
            setNewMessage({ text: "", attachments: [] });
            callback();
          },
          t
        )
      );
    }
  };

  useEffect(() => {
    if (ticketSAV?.id) {
      dispatch(getComments(ticketSAV?.id, isAdmin));
    }
  }, [dispatch, ticketSAV?.id, isAdmin]);

  return (
    <LoaderWrapper
      loading={currentCommentsLoading || currentAdminCommentsLoading}
    >
      <div className="newSavComment d-flex flex-column justify-content-end position-relative overflow-hidden">
        <div className="d-flex pe-4 flex-column overflow-auto flex-column-reverse scroll-snap-Ymandatory">
          {comments?.list?.map((comment, index) => (
            <MessageItem
              key={comment?.id}
              index={index}
              message={comment}
              isOwner={+user?.id === +comment?.attributes?.user?.data?.id}
            />
          ))}
        </div>
        <div className={"mt-5 pe-4"}>
          <MessageInput
            loading={createCommentLoading}
            handleChange={handleChange}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default SavChatMessages;
