import React from "react";
import PassswordInput from "./PassswordInput";
import { useDispatch, useSelector } from "react-redux";
import { closeError } from "../../../../REDUX/actions/error";
import { useTranslation } from "react-i18next";

function ResetPassword({
  currPassword,
  newPassword,
  newConfirmPassword,
  setCurrPassword,
  setNewPassword,
  setNewConfirmPassword,
  matchPassword,
  identicPassword,
  invalidFields,
}) {
  const { t } = useTranslation("common");
  const { errorMessage } = useSelector((state) => state.errorReducer);

  const dispatch = useDispatch();

  const handleChangeCurrPassword = (e) => {
    dispatch(closeError());
    setCurrPassword(e.target.value);
  };
  const handleChangeNewPassword = (e) => {
    dispatch(closeError());
    setNewPassword(e.target.value);
  };
  const handleChangeConfirmPassword = (e) => {
    dispatch(closeError());
    setNewConfirmPassword(e.target.value);
  };

  return (
    <div className="p-5 ">
      <PassswordInput
        text={t("Old password")}
        withoutPlaceHolder
        value={currPassword}
        callback={handleChangeCurrPassword}
      />
      <PassswordInput
        text={t("New password")}
        withoutPlaceHolder
        value={newPassword}
        callback={handleChangeNewPassword}
      />
      <PassswordInput
        text={t("Confirm password")}
        withoutPlaceHolder
        value={newConfirmPassword}
        callback={handleChangeConfirmPassword}
      />
      {invalidFields.password === "passwordError" && (
        <span className="text-red f12 ">
          {t("Please enter a password with at least 6 characters!")}
        </span>
      )}
      {matchPassword === false && (
        <p className="text-red f12 mb-0">
          {t("The new password and password confirmation do not match!")}
        </p>
      )}
      {identicPassword === true && (
        <p className="text-red f12 mb-0">
          {t("The new password must be different from the current password!")}
        </p>
      )}
      <p className="text-red f12 mb-0">{errorMessage}</p>
    </div>
  );
}

export default ResetPassword;
