import React from "react";
import GeneralInfo from "../Common/Forms/GeneralInfo/GeneralInfo";
import AddressInfo from "../Common/Forms/AddressInfo/AddressInfo";
import CompanyInfo from "../Common/Forms/CompanyInfo/CompanyInfo";
import ResetPassword from "../Common/Forms/ResetPassword/ResetPassword";
import { useTranslation } from "react-i18next";

function TabContent({
  activeTab,
  newUser,
  setNewUser,
  handleChangeUpdateUser,
  currPassword,
  newPassword,
  newConfirmPassword,
  setCurrPassword,
  setNewPassword,
  setNewConfirmPassword,
  matchPassword,
  identicPassword,
  invalidFields,
}) {
  const { t } = useTranslation("common");
  switch (activeTab) {
    case "General information":
      return (
        <GeneralInfo
          fromProfile
          setNewUser={setNewUser}
          newUser={newUser}
          handleChangeUpdateUser={handleChangeUpdateUser}
          invalidFields={invalidFields}
        />
      );
    case "Address":
      return <AddressInfo />;
    case "Company":
      return <CompanyInfo />;
    case "Password":
      return (
        <ResetPassword
          currPassword={currPassword}
          newPassword={newPassword}
          newConfirmPassword={newConfirmPassword}
          setCurrPassword={setCurrPassword}
          setNewPassword={setNewPassword}
          setNewConfirmPassword={setNewConfirmPassword}
          matchPassword={matchPassword}
          identicPassword={identicPassword}
          invalidFields={invalidFields}
        />
      );
    default:
      <GeneralInfo />;
  }
}

export default TabContent;
