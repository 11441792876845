import React, { useEffect, useState } from "react";
import Select from "../Common/FormElements/Select/Select";
import SwitchButton from "../Common/FormElements/SwitchButton/SwitchButton";
import Button from "../Common/FormElements/Button/Button";
import CalendarInput from "../Common/FormElements/CalendarInput/CalendarInput";
import { useDispatch, useSelector } from "react-redux";
import { getTicketSAVFiltersData } from "../../REDUX/actions/ticketSAV";
import moment from "moment";
import { adminInitialValues } from "../../helpers/formLabels/savFilters";
import {
  problemOptions,
  statusOptions,
} from "../../helpers/formLabels/savRevendeurFilters";
import { useTranslation } from "react-i18next";

const SavFiltersAdmin = ({ filtersInput, setFiltersInput }) => {
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState(adminInitialValues);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTicketSAVFiltersData());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSpecialChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value?.code });
  };

  const handleInterventionChange = () => {
    setFormData({ ...formData, intervention: !formData.intervention });
  };

  const handleSubmit = () => {
    setFiltersInput(formData);
  };

  const { filtersLists } = useSelector((state) => state.ticketSAVReducer);

  return (
    <>
      {/* 1st line */}
      <div className="d-flex justify-content-between">
        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">{t("Status")}</label>
          <Select
            showClear
            placeholder={t("Status")}
            name="status"
            options={statusOptions(t)}
            callback={handleSpecialChange}
          />
        </div>
        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">
            {t("Supplier")}
          </label>
          <Select
            options={[
              { name: t("Supplier"), code: "Fournisseur" },
              // { name: "Option", code: "Option" },
            ]}
            disabled={true}
          />
        </div>

        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">
            {t("Created at")}
          </label>
          <Select
            showClear
            placeholder={t("Created at")}
            options={filtersLists?.createdAtList?.map((date) => {
              return {
                name: moment(date).format("DD-MM-YYYY à hh:mm"),
                code: date,
              };
            })}
            name="createdAt"
            callback={handleChange}
          />
        </div>
        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">
            {t("Follow-up date")}
          </label>
          <Select
            showClear
            placeholder="date de relance"
            options={filtersLists?.relaunchList?.map((date) => {
              return {
                name: date,
                code: date,
              };
            })}
            name="relaunch"
            callback={handleChange}
          />
        </div>
      </div>

      {/* 2nd line */}

      <div className="d-flex justify-content-between mt-4">
        <>
          <div className="w20">
            <label className="f16 text-softBlack mb-2 sBold">
              {t("Reseller")}
            </label>
            <Select
              showClear
              placeholder={t("Reseller")}
              options={filtersLists?.resellersList?.map((reseller) => {
                return {
                  name: reseller.lastName + " " + reseller.firstName,
                  code: reseller.id,
                };
              })}
              name="revendeur"
              callback={handleChange}
            />
          </div>

          <div className="w20">
            <label className="f16 text-softBlack mb-2 sBold">
              {t("Responsible")}
            </label>
            <Select
              showClear
              placeholder={t("Responsible")}
              options={filtersLists?.responsablesList?.map((responsable) => {
                return {
                  name: responsable.lastName + " " + responsable.firstName,
                  code: responsable.id,
                };
              })}
              name="responsable"
              callback={handleChange}
            />
          </div>
        </>

        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">
            {t("Problem")}
          </label>
          <Select
            showClear
            placeholder={t("Problem")}
            options={problemOptions(t)}
            name="problem"
            callback={handleSpecialChange}
          />
        </div>

        <div className="w20 alignH justify-content-between">
          <div className="d-flex flex-column ">
            <label className="f16 text-softBlack mb-2 sBold">
              {t("Interventions")}
            </label>
            <span className="mb-1">
              <SwitchButton filterSwitch onClick={handleInterventionChange} />
            </span>
          </div>
        </div>
      </div>

      {/* 3rd line */}

      <div className="d-flex justify-content-between mt-4">
        <div className="w20 d-flex flex-column">
          <label className="f16 text-softBlack mb-2 sBold">
            {t("Since the")}
          </label>
          <CalendarInput
            placeholder={t("Since the")}
            name="sinceDate"
            callback={handleChange}
            withMinDate={false}
          />
        </div>
        <div className="w20 d-flex flex-column">
          <label className="f16 text-softBlack mb-2 sBold">{t("Until")}</label>
          <CalendarInput
            placeholder={t("Until")}
            name="untilDate"
            callback={handleChange}
            withMinDate={false}
          />
        </div>
        <div className="w20"></div>
        <div className="w20 align-self-end">
          <Button className="filterSubmitBtn" onClick={handleSubmit}>
            {t("Filter")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SavFiltersAdmin;
