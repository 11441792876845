import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NewAdressCardParticulier from "../Step3/NewAdressCardParticulier";
import { updateCart } from "../../../REDUX/actions/catalogue";
import { useTranslation } from "react-i18next";

const Step4Delivery = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <div className="alignH gap-5">
        <h4 className="mt-4 text-softBlue bold">2- {t("Delivery details")}</h4>
      </div>
      <div className="mb-2">
        {t("Delivery method")} : {cart.deliveryOptionName}
      </div>
      <div className="mt-3 alignH gap-2">
        <div>{t("Preferred delivery date")} : </div>
        <input
          className="refClientItem p-1"
          type="date"
          defaultValue={cart.estimatedDeliveryDate}
          onChange={(e) =>
            dispatch(
              updateCart(cart.id, { estimatedDeliveryDate: e.target.value })
            )
          }
        />
      </div>
      <div className="mt-3 gap-5 alignH">
        <div>
          <div className="mb-2">{t("Delivery address")} : </div>
          {cart.addresseDeLivraison ? (
            <NewAdressCardParticulier address={cart.addresseDeLivraison} />
          ) : (
            <div className="text-red">{t("Not mentioned")}</div>
          )}
        </div>

        <div>
          <div className="mb-2">{t("Billing address")} : </div>
          {cart.addresseDeFacturation ? (
            <NewAdressCardParticulier
              address={cart.addresseDeFacturation}
              type="addresseDeFacturation"
            />
          ) : (
            <div className="text-red">{t("Not mentioned")}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step4Delivery;
