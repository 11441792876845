import ConfirmModal from "../Components/Common/Modal/ConfirmModal";
import SavPreview from "../Components/Sav/SavPreview/SavPreview";
import UpdateTicketSav from "../Components/Sav/UpdateTicketSavAdmin/UpdateTicketSav";
import { openCenteredModal } from "../REDUX/actions/centeredModal";
import { openRightModal } from "../REDUX/actions/rightModal";
import {
  deleteTicketSAV,
  updateTicketSAVStatus,
} from "../REDUX/actions/ticketSAV";

export const extractRevendeurOfSav = (users) => {
  return users.data?.find((user) => user?.attributes?.userRole === 0);
};
export const extractResponsableOfSav = (users) => {
  return users.data?.find((user) => user?.attributes?.userRole === 1);
};
export const generateOptionsBasedOnRole = (userRole, id, dispatch, t) => {
  const roleOptionsMap = {
    admin: [
      // {
      //   text: "Modifier le ticket",
      //   callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      // },
      {
        text: t("View the ticket"),
        callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      },
      {
        text: t("Resolve the ticket"),
        callback: () =>
          dispatch(updateTicketSAVStatus({ id: id, data: { status: 2 } }, t)),
      },
      {
        text: t("Cancel the ticket"),
        orange: true,
        callback: () =>
          dispatch(updateTicketSAVStatus({ id: id, data: { status: 3 } }, t)),
      },
      {
        text: t("Delete the ticket"),
        red: true,
        callback: () =>
          dispatch(
            openCenteredModal(
              <ConfirmModal
                label={t("AS ticket")}
                id={id}
                onConfirm={() => dispatch(deleteTicketSAV(id))}
              />
            )
          ),
      },
    ],
    reseller: [
      {
        text: t("View the ticket"),
        callback: () => dispatch(openRightModal(<SavPreview id={id} />)),
      },
      // {
      //   text: "Modifier le ticket",
      //   callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      // },
      // {
      //   text: "Annuler le ticket",
      //   red: true,
      //   callback: () =>
      //     dispatch(updateTicketSAVStatus({ id: id, data: { status: 3 } })),
      // },
    ],
    BusinessBroker: [
      {
        text: t("View the ticket"),
        callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      },
      {
        text: t("Resolve the ticket"),
        callback: () =>
          dispatch(updateTicketSAVStatus({ id: id, data: { status: 2 } }, t)),
      },
      {
        text: t("Cancel the ticket"),
        orange: true,
        callback: () =>
          dispatch(updateTicketSAVStatus({ id: id, data: { status: 3 } }, t)),
      },
      {
        text: t("Delete the ticket"),
        red: true,
        callback: () =>
          dispatch(
            openCenteredModal(
              <ConfirmModal
                label={t("AS ticket")}
                id={id}
                onConfirm={() => dispatch(deleteTicketSAV(id))}
              />
            )
          ),
      },
    ],
  };
  const options = roleOptionsMap[userRole] || [];

  return options;
};
