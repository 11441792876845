import React from "react";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import Select from "../../../Common/FormElements/Select/Select";
import Separator from "../../../Common/Separator/Separator";
import { useTranslation } from "react-i18next";

function AddressInfo() {
  const { t } = useTranslation("common");
  const villeOptions = [
    { name: "Paris", code: "Paris" },
    { name: "London", code: "London" },
  ];
  return (
    <div className="p-5">
      <div className="mb-3">
        <p className="f16 text-ultraBlack mb-2">{t("Delivery address")}</p>
        <TextInput placeholder={t("Delivery address")} />
      </div>
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("City")}</p>
          <Select placeholder={t("Select")} options={villeOptions} />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("Postal code")}</p>
          <TextInput placeholder={t("Postal code")} />
        </div>
      </div>
      <div className="mb-4 w49">
        <p className="f16 text-ultraBlack mb-2">{t("Country")}</p>
        <Select placeholder={t("Select")} options={villeOptions} />
      </div>
      <Separator />
      <div className="my-3">
        <p className="f16 text-ultraBlack mb-2">{t("Billing address")}</p>
        <TextInput placeholder={t("Billing address")} />
      </div>
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("City")}</p>
          <Select placeholder={t("Select")} options={villeOptions} />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">{t("Postal code")}</p>
          <TextInput placeholder={t("Postal code")} />
        </div>
      </div>
      <div className="mb-4 w49">
        <p className="f16 text-ultraBlack mb-2">{t("Country")}</p>
        <Select placeholder={t("Select")} options={villeOptions} />
      </div>
    </div>
  );
}

export default AddressInfo;
