import React from "react";
import Select from "../../Common/FormElements/Select/Select";
import ListDeleteIcon from "../../Common/DeleteIcon/ListDeleteIcon";
import { useTranslation } from "react-i18next";

function SocieteItem({ value, name, options, onChange, removeField }) {
  const { t } = useTranslation("common");
  return (
    <div className="alignH py-3 px-3 b-1-bottom-softGrey">
      <Select
        filter
        appendToSelf
        name={name}
        value={value}
        options={options}
        width="w50"
        placeholder={t("Select")}
        valueCustomStyle="12px"
        callback={onChange}
      />
      <ListDeleteIcon callback={removeField} />
    </div>
  );
}

export default SocieteItem;
