const getOrderDetailsLabel = (forRightModal, withoutStatus, particulier, t) => {
  return !withoutStatus
    ? [
        { label: t("Product name"), className: "col-3", alignement: "start" },
        {
          label: t("Reference"),
          className: "col-1",
          alignement: "start",
        },
        {
          label: t("Client reference"),
          // label: forRightModal ? "Réf Client." : "Créée par",
          className: "col-2",
          alignement: "start",
        },

        {
          label: t("Serial number"),
          // label: forRightModal ? "Réf Client." : "Créée par",
          className: "col-2",
          alignement: "start",
        },
        {
          label: forRightModal ? "Quant." : t("Quantity"),
          className: "col-1",
          alignement: "start",
        },
        { label: t("Discount"), className: "col-1", alignement: "start" },
        // {
        //   label: forRightModal ? "Acce." : "Accessoires",
        //   className: "col-1",
        //   alignement: "start",
        // },
        {
          label: `${t("Unit Price")} ${
            particulier ? t("Incl. Tax") : t("Excl. Tax")
          }`,
          className: "col-1",
          alignement: "start",
        },
        {
          label: `${t("Price")} ${
            particulier ? t("Incl. Tax") : t("Excl. Tax")
          }`,
          className: "col-1",
          alignement: "start",
        },
      ]
    : [
        { label: t("Product name"), className: "col-4", alignement: "start" },
        {
          label: t("Reference"),
          className: "col-2",
          alignement: "start",
        },
        {
          label: t("Created by"),
          // label: forRightModal ? "Réf Client." : "Référence client",
          className: "col-2",
          alignement: "start",
        },
        {
          label: forRightModal ? "Quant." : t("Quantity"),
          className: "col-1",
          alignement: "start",
        },
        // {
        //   label: forRightModal ? "Acce." : "Accessoires",
        //   className: "col-1",
        //   alignement: "start",
        // },
        {
          label: t("Unit Price excl. tax"),
          className: "col-1",
          alignement: "start",
        },
        {
          label: t("Price excl. tax"),
          className: "col-1",
          alignement: "start",
        },
      ];
};

export default getOrderDetailsLabel;
